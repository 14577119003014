import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import PropTypes from 'prop-types';
import CustomMultiSelect from 'rapidfab/components/forms/CustomMultiSelect';
import { FormControlSelect } from 'rapidfab/components/formTools';
import Loading from 'rapidfab/components/Loading';
import Tooltip from 'rapidfab/components/Tooltip';
import { PERMANENT_CONTAINER_TYPES } from 'rapidfab/constants';
import { FormattedMessageMappingOption } from 'rapidfab/i18n';
import { PERMANENT_CONTAINER_TYPES_MAP } from 'rapidfab/mappings';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import React from 'react';
import {
  Button,
  ButtonToolbar,
  Card,
  FormControl, FormGroup, FormLabel,
  Row,
} from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';

const PermanentContainerRecordForm = ({
  onSubmit,
  isSubmitting,
  initialFormValues,
  onSelectAllMaterials,
  onDeselectAllMaterials,
  locationData,
  materialData,
  onLocationChange,
  isLoading,
}) => {
  const {
    locations,
    subLocationsForLocation,
  } = locationData;

  const {
    materials,
  } = materialData;

  const containerAvailableTypes = _omit(PERMANENT_CONTAINER_TYPES, ['DISPOSABLE', 'CYCLONE']);
  const isCyclone = initialFormValues.type === PERMANENT_CONTAINER_TYPES.CYCLONE;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      initialValuesEqual={isEqual}
      mutators={{
        onSelectAllMaterials,
        onDeselectAllMaterials,
      }}
      render={({ handleSubmit, form, values }) => {
        const materialRestrictionsLength = values?.material_restrictions?.length;
        const dynamicAllowedMaterialsLabel = materialRestrictionsLength
          ? `${materialRestrictionsLength} ${pluralWord('Material', values.material_restrictions)} Selected`
          : 'All Materials Allowed';
        return (
          <form onSubmit={handleSubmit}>
            <Row>
              <Card className="mb15" bg="dark">
                <Card.Header className="pd-exp accent d-flex align-items-center justify-content-between">
                  <FormattedMessage
                    id="field.permanentContainer"
                    defaultMessage="Permanent Container Details"
                  />
                  <ButtonToolbar className="pull-right">
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      size="sm"
                      variant="success"
                      onClick={() => {}}
                    >
                      {isSubmitting ? <Loading /> : <SaveButtonTitle resourceName="Permanent Container" />}
                    </Button>
                  </ButtonToolbar>
                </Card.Header>
                <div className="card-body-wrapper-accent">
                  {isLoading ? <Loading className="p-a-md" size="2x" /> : (
                    <Card.Body>
                      <FormGroup className="form-group" controlId="uxName">
                        <FormLabel>
                          <FormattedMessage id="field.name" defaultMessage="Name" />: *
                        </FormLabel>
                        <Field
                          name="name"
                          type="text"
                          render={props => (
                            <FormControl
                              {...props.input}
                              required
                            />
                          )}
                        />
                      </FormGroup>
                      <FormGroup className="form-group" controlId="uxFamily">
                        {/* Commented until we might require it back, hidden at this iteration */}
                        {/* <Button */}
                        {/*  className="spacer-left btn-xs" */}
                        {/*  data-cy="select-all-materials-button" */}
                        {/*  variant={isAllMaterialsButtonSelected ? 'link' : 'info'} */}
                        {/*  onClick={() => { */}
                        {/*    setIsAllMaterialsButtonSelected(!isAllMaterialsButtonSelected); */}
                        {/*    if (isAllMaterialsButtonSelected) form.mutators.onDeselectAllMaterials(); */}
                        {/*    else form.mutators.onSelectAllMaterials(); */}
                        {/*  }} */}
                        {/* > */}
                        {/*  {isAllMaterialsButtonSelected ? 'Deselect all materials' : 'Select all materials'} */}
                        {/* </Button> */}

                        <Field
                          name="material_restrictions"
                          render={({ input }) => (
                            <CustomMultiSelect
                              value={input.value}
                              onChange={input.onChange}
                              disabled={isCyclone}
                              isCardMode
                              formLabel={(
                                <FormLabel
                                  className="mb10"
                                  style={!materialRestrictionsLength ? { color: '#1ac98e' } : {}}
                                >
                                  {dynamicAllowedMaterialsLabel}
                                </FormLabel>
                              )}
                              title={(
                                <FormattedMessage
                                  id="material_restrictions"
                                  defaultMessage="Allowed Materials"
                                />
                              )}
                              options={materials}
                              placeholder="Select materials"
                            />
                          )}
                        />
                      </FormGroup>
                      <FormGroup className="form-group" controlId="uxTareWeight">
                        <FormLabel>
                          <FormattedMessage
                            id="field.tareWeight"
                            defaultMessage="Tare Weight"
                          />
                          :
                          <Tooltip
                            id="tareWeightTooltip"
                            placement="right"
                            trigger={(<FontAwesomeIcon icon={faInfoCircle} className="spacer-left" />)}
                          >
                            Tare Weight units are in grams.
                          </Tooltip>
                        </FormLabel>

                        <Field
                          name="tare_weight"
                          render={props => (
                            <FormControl disabled={isCyclone} {...props.input} />
                          )}
                        />
                      </FormGroup>
                      <FormGroup className="form-group" controlId="uxType">
                        <FormLabel>
                          <FormattedMessage
                            id="field.type"
                            defaultMessage="Type"
                          />
                          : *
                        </FormLabel>

                        <Field
                          name="type"
                          render={props =>
                            (
                              <FormControlSelect
                                {...props.input}
                                id="uxType"
                                disabled={!!initialFormValues.uri || isCyclone}
                                required
                              >
                                {Object.values(containerAvailableTypes).map(
                                  reportType => (
                                    <FormattedMessageMappingOption
                                      mapping={PERMANENT_CONTAINER_TYPES_MAP}
                                      value={reportType}
                                    />
                                  ))}
                              </FormControlSelect>
                            )}
                        />
                      </FormGroup>
                      <FormGroup className="form-group" controlId="uxLocation">
                        <FormLabel>
                          <FormattedMessage
                            id="field.location"
                            defaultMessage="Location"
                          />
                          : *
                        </FormLabel>

                        <Field
                          name="location"
                          render={props =>
                            (
                              <FormControlSelect
                                {...props.input}
                                id="uxLocation"
                                disabled={isCyclone}
                                onChange={event => {
                                  form.change('sub_location', '');
                                  const newLocationUri = event.target.value;
                                  onLocationChange(newLocationUri);
                                  props.input.onChange(event);
                                }}
                                required
                              >
                                <option key="placeholder" value="" selected>
                                  Select a Location
                                </option>
                                {locations.map(location => (
                                  <option key={location.uri} value={location.uri}>
                                    {location.name}
                                  </option>
                                ))}
                              </FormControlSelect>
                            )}
                        />
                      </FormGroup>
                      <FormGroup className="form-group" controlId="uxSubLocation">
                        <FormLabel>
                          <FormattedMessage
                            id="field.subLocation"
                            defaultMessage="Sub-Location"
                          />
                          : *
                        </FormLabel>
                        <Field
                          name="sub_location"
                          render={props => (
                            <FormControlSelect
                              {...props.input}
                              id="uxSubLocation"
                              disabled={!values.location || isCyclone}
                              required
                            >
                              <option key="placeholder" value="" selected>
                                {!values.location ? 'Select a Location' : 'Select a Sub-Location'}
                              </option>
                              {subLocationsForLocation.map(subLocation => (
                                <option key={subLocation.uri} value={subLocation.uri}>
                                  {subLocation.name}
                                </option>
                              ))}
                            </FormControlSelect>
                          )}
                        />
                      </FormGroup>
                    </Card.Body>
                  )}
                </div>
              </Card>
            </Row>
          </form>
        );
      }}
    />
  );
};

PermanentContainerRecordForm.propTypes = {
  initialFormValues: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  locationData: PropTypes.shape({
    locations: PropTypes.arrayOf(PropTypes.shape({})),
    subLocationsForLocation: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  materialData: PropTypes.shape({
    isAllMaterialsButtonSelected: PropTypes.bool,
    setIsAllMaterialsButtonSelected: PropTypes.func,
    materials: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSelectAllMaterials: PropTypes.func.isRequired,
  onDeselectAllMaterials: PropTypes.func.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  onLocationChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PermanentContainerRecordForm;

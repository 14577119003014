import { ROUTES } from 'rapidfab/constants/routes';

export const LOCATION_FILTER_DEFAULT_VALUES = {
  UNASSIGNED: 'unassigned',
  ALL: '',
};

export const SUB_LOCATION_FILTER_DEFAULT_VALUES = {
  ALL: '',
};

export const MODEL_LAYER_THICKNESS_SETTINGS = {
  MIN: 0.001,
  MAX: 1,
  DEFAULT: 0.2,
  HAWKING_DEFAULT: 0.24,
  THREEDPC_DEFAULT: 0.1,
  STEP: 0.001,
};

export const MATERIAL_REASON_CODE_OPTIONS = {
  INVALID_RECEIPTS: 'Invalid_receipts',
  ITEM_SUBSTITUTION: 'item_substitution',
  UOM_DISCREPANCY: 'uom_discrepancy',
  CYCLE_COUNT: 'cycle_count',
  CANNOT_BE_DETERMINED: 'cannot_be_determined',
  OVERSHIPMENT_ADJUSTMENT: 'overshipment_adjustment',
  DAMAGED_TRANSIT: 'damaged_transit',
  DAMAGED_WAREHOUSE: 'damaged_warehouse',
  PHYSICAL_COUNT: 'physical_count',
  DATA_ENTRY_ERROR: 'data_entry_error',
  SCRAP: 'scrap',
};

export const MANUFACTURING_PROCESSES = [
  'Binder Jet',
  'SLA', // Stereolithography.
  'SLS', // Selective laser sintering.
  'FDM', // Fused Deposition Modeling.
  'Polyjet',
  'Polyjet Anatomy',
  'EBM', // Electron Beam Manufacturing.
  'MJF', // HP version of polyjet.
  'DLP', // Digital Light Projection.
  'CLIP', // Continuous Liquid Interface Production.
  'DMLS', // Direct Metal Laser Sintering.
  'SDL', // Selective Deposits Layer, paper and glue
];

export const LOCATION_NOTIFICATION_SETTING_BOOLS = {
  ORDER_CREATED: 'notification_order_created',
  LINE_ITEMS_CONFIRMED: 'notification_line_items_confirmed',
  ORDER_STARTED_PRINTING: 'notification_order_started_printing',
  ORDER_SCHEDULED: 'notification_order_scheduled',
  PRINT_ERROR: 'notification_print_error',
  ORDER_COMPLETED: 'notification_order_completed',
};

export const SHIPMENT_ADDRESS_TYPES = {
  RECIPIENT_ADDRESS: 'recipient_address',
  BILLING_ADDRESS: 'billing_address',
  ORIGIN_ADDRESS: 'origin_address',
};

export const ORDER_SHIPPING_GROUPING_OPTIONS = {
  BY_BATCHING_STRATEGY: 'by_batching_strategy',
  BY_ORDER: 'by_order',
};

export const WORK_INSTRUCTION_REPORT_TYPES = {
  NO_ENTRY: 'no entry',
  CHECKBOX: 'checkbox',
  DOCUMENT: 'document',
  PICTURE: 'picture',
  NUMBER: 'number',
  RANGE: 'range',
  TEXT: 'text',
  SINGLE_SELECT_DROPDOWN: 'single-selection-dropdown',
  OUTPUT_AT_LOCATION: 'output-at-location',
};

export const WORK_INSTRUCTION_THRESHOLD_TYPES = {
  BETWEEN: 'between',
  NOT_BETWEEN: 'not-between',
  TOLERANCE: 'tolerance',
  LESS_THAN: 'less-than',
  LESS_THAN_OR_EQUALS_TO: 'less-than-or-equals-to',
  GREATER_THAN: 'greater-than',
  GREATER_THAN_OR_EQUALS_TO: 'greater-than-or-equals-to',
  EQUALS_TO: 'equals-to',
  NOT_EQUALS_TO: 'not-equals-to',
  CONTAINS: 'contains',
  NOT_CONTAINS: 'not-contains',
  NONE: 'None',
};

export const WORK_INSTRUCTION_THRESHOLD_TYPES_BY_WORK_INSTRUCTION_TYPE = {
  [WORK_INSTRUCTION_REPORT_TYPES.NO_ENTRY]: [],
  [WORK_INSTRUCTION_REPORT_TYPES.CHECKBOX]: [],
  [WORK_INSTRUCTION_REPORT_TYPES.DOCUMENT]: [],
  [WORK_INSTRUCTION_REPORT_TYPES.PICTURE]: [],
  [WORK_INSTRUCTION_REPORT_TYPES.NUMBER]: [
    WORK_INSTRUCTION_THRESHOLD_TYPES.BETWEEN,
    WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_BETWEEN,
    WORK_INSTRUCTION_THRESHOLD_TYPES.TOLERANCE,
    WORK_INSTRUCTION_THRESHOLD_TYPES.LESS_THAN,
    WORK_INSTRUCTION_THRESHOLD_TYPES.LESS_THAN_OR_EQUALS_TO,
    WORK_INSTRUCTION_THRESHOLD_TYPES.GREATER_THAN,
    WORK_INSTRUCTION_THRESHOLD_TYPES.GREATER_THAN_OR_EQUALS_TO,
    WORK_INSTRUCTION_THRESHOLD_TYPES.EQUALS_TO,
    WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_EQUALS_TO,
  ],
  [WORK_INSTRUCTION_REPORT_TYPES.RANGE]: [],
  [WORK_INSTRUCTION_REPORT_TYPES.TEXT]: [
    WORK_INSTRUCTION_THRESHOLD_TYPES.CONTAINS,
    WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_CONTAINS,
    WORK_INSTRUCTION_THRESHOLD_TYPES.EQUALS_TO,
    WORK_INSTRUCTION_THRESHOLD_TYPES.NOT_EQUALS_TO,
  ],
  [WORK_INSTRUCTION_REPORT_TYPES.SINGLE_SELECT_DROPDOWN]: [],
  [WORK_INSTRUCTION_REPORT_TYPES.OUTPUT_AT_LOCATION]: [],
};

export const CASTOR_COSTING_CONFIG_MANUFACTURABILITY_OPTIONS = {
  PRINTABLE: 'printable',
  NOT_PRINTABLE: 'not-printable',
  PRINTABLE_WITH_SUPPORT: 'printable-with-supports',
};

export const USER_HIDE_INFO_TYPES = {
  FINANCIAL: 'financial',
};

export const EXTERNAL_PRINT_ANALYSIS_VENDOR = {
  NEBUMIND: 'nebumind',
};

export const QUOTE_PREVIEW_MODAL_FIELDS = {
  LABOR: 'Labor',
  WORKSTATION_TIME: 'Workstation Time',
  PIECE_OVERHEAD_COST: 'Piece Overhead Cost',
  RUN_OVERHEAD_COST: 'Run Overhead Cost',
};

export const PRINTER_FORM_COMMAND_TYPES = {
  START_JOB: 'start_job',
  CANCEL_JOB: 'cancel_job',
};

export const MODELER_TYPE_MANUFACTURERS = {
  PHOTOCENTRIC_LTD: 'Photocentric Ltd',
};

export const BUREAU_BARCODE_FORMAT = {
  QR: 'qr',
  BARCODE: 'barcode',
};

export const RESOURCES_TO_DECODE = {
  SHIPMENT: 'shipment',
  CONTAINER: 'material-container',
  PRINTER: 'printer',
  POST_PROCESSOR: 'post-processor',
  TOOLING_STOCK: 'tooling-stock',

  PIECE: 'piece',
  RUN: 'run',
  SUMMARY: 'summary',
};

export const ENCODED_RESOURCES_CONSTANTS = {
  SHIPMENT: 'E',
  CONTAINER: 'A',
  PRINTER: 'D',
  POST_PROCESSOR: 'P',
  TOOLING_STOCK: 'T',

  RUN: 'B',
  PIECE: 'C',
  SUMMARY: 'Z',
};

export const MATERIAL_DATA_TYPES = {
  LOT: 'lot',
  BATCH: 'batch',
};

export const MANUFACTURER_FIELDS = {
  name: 'name',
  contact_name: 'contact.name',
  contact_phone: 'contact.phone',
  support_name: 'support.name',
  support_phone: 'support.phone',
  address: 'address',
  notes: 'notes',
};

export const REACT_SELECT_ACTIONS = {
  CREATE_OPTION: 'create-option',
};

export const manufacturerDefaultPayload = {
  contact: {
    name: '',
    phone: '',
  },
  support: {
    name: '',
    phone: '',
  },
  address: '',
  notes: '',
};

export const SLICER_CONFIGURATION_FILE_STATUSES = {
  PENDING_FILE_UPLOAD: 'pending_file_upload',
  FAILED: 'failed',
};

export const PRINTER_TYPE_REQUIRED_FIELD_TITLES = {
  name: 'Name',
  description: 'Description',
  manufacturer: 'Manufacturer',
  materials: 'Materials',
  build_packer_type: 'Build Packer Type',
  build_volume: 'Build Plate Volume',
  min_packing_distance: 'Minimum Packing Distance',
  time_before_print: 'Pre-Run Buffer',
  time_after_print: 'Post-Run Buffer',
  running_cost_per_hour: 'Printer Cost Per Hour',
};

export const MATERIAL_TYPE_REQUIRED_FIELD_TITLES = {
  name: 'Name',
  description: 'Description',
  manufacturer: 'Manufacturer',
  type: 'Material Type',
  cost: 'Cost Per Unit',
  units: 'Units',
  density: 'Density',
};

export const POST_PROCESSOR_TYPE_REQUIRED_FIELD_TITLES = {
  name: 'Name',
  description: 'Description',
  manufacturer: 'Manufacturer',
  materials: 'Materials',
  build_packer_type: 'Build Packer Type',
  build_volume: 'Build Volume',
  min_packing_distance: 'Minimum Packing Distance',
  cost: 'Cost Per Minute',
  in_progress_max: 'Max Capacity',
  number_of_parts: 'Number of Parts',
};

export const LABOR_TYPE = {
  LABOR_TO_RUN: 'labor_to_run',
};

export const IMPERSONATION_USER_PROFILE_CONTEXT = {
  SEARCH: 'search',
  IMPERSONATED_USER: 'impersonated_user',
  FAVORITE_USERS: 'favorite_users',
};

export const SERVICE_PROVIDER_TYPE = {
  WORK: 'work',
  VENDOR: 'vendor',
};

export const CREATE_RUN_PROGRESS_STATUSES = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  FINISHING: 'finishing',
  COMPLETE: 'complete',
  ERROR: 'error',
};

export const LOCATION_TABS = {
  DETAILS: 'details',
  SUB_LOCATIONS: 'subLocations',
  RESOURCES: 'resources',
};

export const USER_ROLES_ITEMS = [
  { name: 'Manager', value: 'manager' },
  { name: 'Location Manager', value: 'location-manager' },
  { name: 'Standard User', value: 'standard-user' },
  { name: 'Restricted User', value: 'restricted' },
  { name: 'Global User', value: 'global-user' },
];

export const USER_ROLES_BY_VALUES = {
  manager: 'Manager',
  'location-manager': 'Location Manager',
  'standard-user': 'Standard User',
  restricted: 'Restricted User',
  'global-user': 'Global User',
};

export const USER_MODAL_MODES = {
  ADD: 'add',
  EDIT: 'edit',
  VIEW: 'view',
};

export const ACTIVE_LOGIN_FORM_STATES = {
  SIGN_IN: 'sign_in',
  FORGOT_PASSWORD: 'forgot_password',
  FORGOT_PASSWORD_COMPLETE: 'forgot_password_complete',
  RESET_PASSWORD: 'reset_password',
};

export const ORDER_QUOTE_MODES = {
  IDLE: 'idle',
  IDLE_ORDER_CONFIRMED: 'idle-order-confirmed',
  IDLE_ORDER_CANCELLED: 'idle-order-cancelled',
  REQUIRED_INITIAL: 'required-initial',
  REQUIRED_GENERATED: 'required-generated',
  REQUIRED_CONFIRM: 'required-confirm',
  REQUIRED_ACCEPTED: 'required-accepted',
};

export const PIECE_GROUPS_FILTER = {
  ALL: 'all',
  ORIGINAL_PIECES_ONLY: 'original_pieces_only',
  PRODUCTION_CHANGED_PIECES: 'production_changed_pieces',
};

export const PRODUCTION_CHANGE_TYPES_VALUES = {
  NONCONFORMANCE: 'nonconformance',
  WORKFLOW: 'workflow',
  REMANUFACTURE: 'remanufacture',
  SCRAP: 'scrap',
  NONE: '',
};

export const PRODUCTION_CHANGE_OPTIONS = [
  { label: 'None', value: PRODUCTION_CHANGE_TYPES_VALUES.NONE },
  { label: 'Flag for Non-conformance Review', value: PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE },
  { label: 'Workflow Change', value: PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW },
  { label: 'Remanufacture', value: PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE },
  { label: 'Scrap', value: PRODUCTION_CHANGE_TYPES_VALUES.SCRAP },
];

export const MACHINE_TYPE = {
  PRINTER: 'printer',
  POST_PROCESSOR: 'post_processor',
};

export const BUILD_PLATE_CHOICES = {
  PIECE: 'piece',
  LINE_ITEM: 'line_item',
};

export const DROPDOWN_MODAL_TYPES = {
  DUPLICATE: 'duplicate',
  SAVE_AS_TEMPLATE: 'saveAsTemplate',
  CANCEL: 'cancel',
  DELETE: 'delete',
};

export const TABLES_VISIBILITY_PROTECTED_COLUMNS = {
  [ROUTES.PERMANENT_CONTAINERS]: ['name'],
};

export const AUTODESK_FUSION_MODEL_STATUSES = {
  created: 'created',
  processing: 'processing',
  ready: 'ready',
  error: 'error',
};

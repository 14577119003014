import React from 'react';

const FusionSuccess = () => (
  <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.6097 0.0625001C19.691 0.122372 19.7328 0.198421 19.7971 0.239439C20.161 0.471875 20.3921 0.771365 20.3872 1.22891C20.3784 2.04031 20.3853 2.85187 20.3846 3.72129C20.3815 3.92396 20.3778 4.06871 20.3778 4.21345C20.3775 8.11258 20.3776 12.0117 20.3775 15.9108C20.3775 16.4048 20.3773 16.405 19.8687 16.405C15.5224 16.405 11.1761 16.4054 6.82975 16.4047C5.77428 16.4045 4.71881 16.4005 3.66309 16.369C3.66425 10.9141 3.66568 5.48829 3.66711 0.0625C8.97161 0.0625 14.2761 0.0625 19.6097 0.0625001ZM11.8476 9.18693C11.8614 9.17735 11.8702 9.16441 11.9316 9.14238C12.8788 9.14238 13.8261 9.14238 14.7657 9.14238C14.7657 8.57874 14.7657 8.05863 14.7657 7.50913C13.7731 7.50913 12.806 7.50913 11.8392 7.50913C11.8392 6.79102 11.8392 6.10354 11.8392 5.39373C11.9721 5.39373 12.0846 5.39373 12.1971 5.39373C13.1389 5.39372 14.0807 5.38683 15.0223 5.3985C15.261 5.40146 15.3609 5.34294 15.3454 5.08682C15.3248 4.74674 15.3248 4.40354 15.3453 4.06346C15.3612 3.80059 15.2658 3.7309 15.0104 3.73236C13.2822 3.7422 11.5539 3.73725 9.82558 3.73831C9.7458 3.73836 9.66603 3.75015 9.58408 3.75664C9.58408 6.77698 9.58408 9.779 9.58408 12.7772C10.3377 12.7772 11.0678 12.7772 11.8256 12.7772C11.8256 11.5716 11.8256 10.384 11.8476 9.18693Z" fill="#FF6B00" />
    <path d="M3.66249 16.3964C4.71796 16.3987 5.77343 16.4027 6.82889 16.4029C11.1752 16.4036 15.5215 16.4032 19.8678 16.4032C20.3765 16.4032 20.3767 16.4031 20.3767 15.909C20.3767 12.0099 20.3766 8.1108 20.3769 4.21167C20.377 4.06693 20.3807 3.92218 20.3864 3.74809C20.9142 3.71875 21.4384 3.71875 21.9996 3.71875C21.9996 9.17722 21.9996 14.5899 21.9996 20.0316C15.5972 20.0607 9.19473 20.0607 2.76951 20.0388C2.16347 19.6787 1.92225 19.1771 1.98264 18.5048C2.01384 18.1574 1.98643 17.8048 1.99588 17.4357C2.55834 17.0767 3.11042 16.7366 3.66249 16.3964Z" fill="#933C00" />
    <path d="M3.663 16.369C3.11118 16.7383 2.55911 17.0785 1.97576 17.4431C1.76766 17.5804 1.59467 17.6999 1.41324 17.8048C0.965052 18.064 0.512999 18.3166 0.0625 18.5718C0.0625002 13.1254 0.0625002 7.67895 0.0852254 2.2031C1.04463 1.61501 1.98386 1.06054 2.91596 0.494359C3.11733 0.372046 3.29305 0.20756 3.48058 0.0625001C3.52719 0.0625 3.5738 0.0625 3.64371 0.0625C3.66559 5.48829 3.66416 10.9141 3.663 16.369Z" fill="#FF9548" />
    <path d="M11.8256 9.19844C11.8256 10.386 11.8256 11.5736 11.8256 12.7793C11.0677 12.7793 10.3376 12.7793 9.58398 12.7793C9.58398 9.78104 9.58398 6.77902 9.58398 3.75868C9.66593 3.75219 9.7457 3.7404 9.82548 3.74035C11.5538 3.73929 13.2821 3.74424 15.0103 3.7344C15.2657 3.73294 15.3611 3.80263 15.3452 4.0655C15.3247 4.40558 15.3247 4.74878 15.3453 5.08886C15.3608 5.34498 15.2609 5.4035 15.0222 5.40054C14.0806 5.38887 13.1388 5.39576 12.197 5.39577C12.0845 5.39577 11.972 5.39577 11.8391 5.39577C11.8391 6.10558 11.8391 6.79306 11.8391 7.51117C12.8059 7.51117 13.773 7.51117 14.7656 7.51117C14.7656 8.06067 14.7656 8.58078 14.7656 9.14442C13.826 9.14442 12.8788 9.14442 11.8866 9.14365C11.8363 9.16139 11.8309 9.17992 11.8256 9.19844Z" fill="white" />
    <path d="M11.836 9.18782C11.8304 9.17403 11.8358 9.15551 11.8573 9.14062C11.8696 9.16057 11.8608 9.17351 11.836 9.18782Z" fill="#FF9040" />
    <path d="M25 17C25 19.7614 22.7614 22 20 22C17.2386 22 15 19.7614 15 17C15 14.2386 17.2386 12 20 12C22.7614 12 25 14.2386 25 17Z" fill="#FABE09" />
    <path d="M20.5 14C20.5 13.7234 20.2766 13.5 20 13.5C19.7234 13.5 19.5 13.7234 19.5 14V18C19.5 18.2766 19.7234 18.5 20 18.5C20.2766 18.5 20.5 18.2766 20.5 18V14ZM20 20.5C20.1658 20.5 20.3247 20.4342 20.4419 20.3169C20.5592 20.1997 20.625 20.0408 20.625 19.875C20.625 19.7092 20.5592 19.5503 20.4419 19.4331C20.3247 19.3158 20.1658 19.25 20 19.25C19.8342 19.25 19.6753 19.3158 19.5581 19.4331C19.4408 19.5503 19.375 19.7092 19.375 19.875C19.375 20.0408 19.4408 20.1997 19.5581 20.3169C19.6753 20.4342 19.8342 20.5 20 20.5Z" fill="white" />
  </svg>
);

export default FusionSuccess;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';
import {
  API_RESOURCES,
  ORDER_PACKING_SLIP_STATUSES,
} from 'rapidfab/constants';
import { useDispatch } from 'react-redux';
import Actions from 'rapidfab/actions';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getEndpointFromURI } from 'rapidfab/utils/uriUtils';
import Alert from 'rapidfab/utils/alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import {
  runResourceType,
} from 'rapidfab/types';

const DownloadPackingSlipButton = ({
  documentEndpointName,
  resourceUri,
  isLoading,
  run,
}) => {
  const [isRequestedDocumentDownloading, setIsRequestedDocumentDownloading] =
    useState(false);
  const [packingSlipDocument, setPackingSlipDocument] = useState(null);
  const [locationUUID, setLocationUUID] = useState(null);
  const dispatch = useDispatch();

  const onRequestDocument = () => {
    const resourceEndpointName = getEndpointFromURI(resourceUri).endpointName;
    return dispatch(
      Actions.Api.nautilus[documentEndpointName].post({
        [resourceEndpointName]: resourceUri,
      }),
    ).then(response => {
      const { location } = response.headers;
      setLocationUUID(extractUuid(location));
      return dispatch(
        Actions.Api.nautilus[documentEndpointName].get(
          extractUuid(location),
          true,
        ),
      );
    });
  };
  const getDocument = () =>
    dispatch(
      Actions.Api.nautilus[documentEndpointName].get(locationUUID, true),
    );

  useEffect(() => {
    let interval;
    if (
      isRequestedDocumentDownloading &&
      packingSlipDocument?.status &&
      Object.values(ORDER_PACKING_SLIP_STATUSES).includes(
        packingSlipDocument?.status,
      )
    ) {
      switch (packingSlipDocument?.status) {
        case ORDER_PACKING_SLIP_STATUSES.ERROR:
          Alert.error(
            <FormattedMessage
              id="toaster.error.packingSlip.downloading"
              defaultMessage="Error downloading packing slip"
            />,
          );
          setIsRequestedDocumentDownloading(false);
          break;
        /* when packingSlipDocument.status is complete,
         * the content is downloaded and interval is removed
         */
        case ORDER_PACKING_SLIP_STATUSES.COMPLETE:
          window.location = packingSlipDocument.content;
          setIsRequestedDocumentDownloading(false);
          break;

        /*
         * Receiving the Post request response(`packingSlipDocument` here)
         * doesn't mean that the packing slip is ready to be downloaded.
         *
         * We add periodic GET requests until
         * the packingSlipDocument's status is `complete`
         *
         * when packingSlipDocument's status is complete,
         * the content is downloaded and interval is removed
         *
         * Clarification ticket is `sc-43800`
         */
        default:
          interval = setInterval(
            () =>
              getDocument().then(resp => {
                setPackingSlipDocument(resp?.json);
              }),
            1000,
          );
          break;
      }
    }
    return () => {
      clearInterval(interval);
    };
  }, [packingSlipDocument]);

  useEffect(() => {
    if (isRequestedDocumentDownloading) {
      onRequestDocument().then(resp => {
        setPackingSlipDocument(resp?.json);
      });
    }
  }, [isRequestedDocumentDownloading]);
  const shouldPrint = [API_RESOURCES.ORDER_PACKING_SLIP].includes(
    documentEndpointName,
  );
  const handleRequestDocumentClick = () => {
    if (shouldPrint) {
      window.print();
    } else {
      setIsRequestedDocumentDownloading(true);
    }
  };

  return (
    <Button
      // eslint-disable-next-line no-undef
      disabled={
        isRequestedDocumentDownloading ||
        isLoading ||
        !run
      }
      variant="primary"
      size="sm"
      style={{ marginRight: '1rem' }}
      onClick={handleRequestDocumentClick}
    >
      {isRequestedDocumentDownloading || isLoading ? (
        <Loading />
      ) : (shouldPrint ? (
        <>
          <FontAwesomeIcon className="spacer-right" icon={faPrint} />

          <FormattedMessage
            id="record.printPackingSlip"
            defaultMessage="Print Packing Slip"
          />
        </>
      ) : (
        <FormattedMessage
          id="record.packingSlip"
          defaultMessage="Download Packing Slip"
        />
      ))}
    </Button>
  );
};

DownloadPackingSlipButton.defaultProps = {
  isLoading: false,
  run: null,
};

DownloadPackingSlipButton.propTypes = {
  documentEndpointName: PropTypes.oneOf([API_RESOURCES.ORDER_PACKING_SLIP])
    .isRequired,
  resourceUri: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  run: runResourceType,
};

export default DownloadPackingSlipButton;

// Handles large numbers and decimals.
export default function formatLargeNumber(number, decimals) {
  if (number === null || number === undefined) return null;

  const [integer, decimal] = number.toString().split('.');

  // If there's a decimal part, slice and remove trailing zeros
  if (decimal) {
    const trimmedDecimal = decimal.slice(0, decimals).replace(/0+$/, '');
    return trimmedDecimal ? `${integer}.${trimmedDecimal}` : integer;
  }

  return integer;
}

import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import { selectInputStyles } from 'rapidfab/constants/styles';
import PostProcessorTypesIcon from 'rapidfab/icons/post-processor-types-icon';
import { convertToDays } from 'rapidfab/utils/timeUtils';
import React from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  FormLabel,
  FormControl,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Dropdown,
  Card,
  Row,
  SplitButton, Badge, InputGroup, Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import Loading from 'rapidfab/components/Loading';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import PostProcessorProvidersContainer from 'rapidfab/containers/records/PostProcessorProvidersContainer';
import VisibleFor from 'rapidfab/components/VisibleFor';
import DisabledByAccessInfoCheck from 'rapidfab/components/DisabledByAccessInfoCheck';
import { ACCESS_INFO_ACTION_TYPES, REACT_SELECT_ACTIONS, ROUTES } from 'rapidfab/constants';
import LastUpdated from 'rapidfab/components/LastUpdated';
import { postProcessorTypeResourceType } from 'rapidfab/types';
import { Link } from 'react-router-dom';
import { getRouteURI, isURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { faArchive, faBan, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FORMATTED_DURATION_TYPES, FormattedOptionalDuration } from 'rapidfab/i18n';
import CreatableSelect from 'react-select/creatable';
import TimeFormattedHHMMSSWithTooltip from '../TimeFormattedHHMMSSWithTooltip';

const styles = {
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  spacingTopNewForm: { marginTop: '-2rem' },
  spacingTopExistingForm: { marginTop: '1rem' },
};

const PostProcessorForm = ({
  duration,
  handleDelete,
  handleArchive,
  handleUnarchive,
  handleInputChange,
  handleSubmit,
  loading,
  location,
  locations,
  name,
  postProcessorType,
  postProcessorTypes,
  selectedPostProcessorType,
  submitting,
  uuid,
  isService,
  postProcessor,
  isServiceProviderRole,
  isDurationInvalid,
  subLocation,
  subLocations,
  subLocationsFetching,
  handleChangePostProcessorType,
  setNewPostProcessorTypeName,
  newPostProcessorTypeName,
  postProcessorTypeOnTheFlySubmitting,
  postProcessorTypeOnTheFlyFetching,
  setPostProcessorTypeModalVisible,
}) => {
  const formattedPostProcessorTypes = postProcessorTypes.map(postProcessorType => ({
    label: postProcessorType.name,
    value: postProcessorType.uri,
  })).filter(Boolean);

  const handlePostProcessorTypeChange = (selectedOption, actionMeta, onChange) => {
    // The user would like to create the new manufacturer "on the fly"
    if (actionMeta.action === REACT_SELECT_ACTIONS.CREATE_OPTION) {
      // Set the potential new Printer Type name for the Creation on the Fly
      setNewPostProcessorTypeName(selectedOption.label);
    }
    const newValue = selectedOption ? selectedOption.value : '';
    return onChange(newValue);
  };

  const currentPostProcessor = postProcessorTypes.find(
    ppt => postProcessorType === ppt.uri);

  const selectedOption = formattedPostProcessorTypes.find(option =>
    option.value === postProcessorType) || null;

  return (
    <div style={postProcessor ? styles.spacingTopExistingForm : styles.spacingTopNewForm}>
      {(loading && (!postProcessorTypeOnTheFlyFetching || !postProcessorTypeOnTheFlySubmitting)) ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center">
              {(!isService && currentPostProcessor?.is_modular && uuid) && (
                <Link
                  className="btn btn-info-outline spacer-right"
                  to={getRouteURI(ROUTES.POST_PROCESSOR_QR, { uuid: postProcessor.uuid }, {}, true)}
                >
                  <BureauBarcodeIcon
                    className="spacer-right"
                    qrCodeText="Post Processor QR Code"
                    barcodeText="Post Processor Barcode"
                  />
                </Link>
              )}
              {postProcessor?.archived && (
                <Badge bg="default">
                  <FormattedMessage id="field.archived" defaultMessage="Archived" />
                </Badge>
              )}
            </div>
            <div className="pull-right">
              <VisibleFor bureauUserRole>
                <DisabledByAccessInfoCheck
                  resourceUri={postProcessor && postProcessor.uri}
                  actionType={ACCESS_INFO_ACTION_TYPES.EDIT}
                  renderDisabledPrefix
                >
                  {({ disabled }) => (
                    <SplitButton
                      id="uxSaveDropdown"
                      type="submit"
                      variant="success"
                      size="sm"
                      className="mt5"
                      title={submitting ? <Loading /> : <SaveButtonTitle />}
                      disabled={disabled}
                    >
                      {postProcessor?.archived ? (
                        <>
                          <Dropdown.Item
                            eventKey={2}
                            onClick={handleUnarchive}
                            disabled={!uuid}
                          >
                            <FontAwesomeIcon icon={faArchive} />{' '}
                            <FormattedMessage id="button.unarchive" defaultMessage="Unarchive" />
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey={1}
                            onClick={handleDelete}
                            disabled={!uuid}
                          >
                            <FontAwesomeIcon icon={faBan} />{' '}
                            <FormattedMessage id="button.delete" defaultMessage="Delete" />
                          </Dropdown.Item>
                        </>
                      ) : (
                        <Dropdown.Item
                          eventKey={1}
                          onClick={handleArchive}
                          disabled={!uuid}
                        >
                          <FontAwesomeIcon icon={faArchive} />{' '}
                          <FormattedMessage id="button.archive" defaultMessage="Archive" />
                        </Dropdown.Item>
                      )}
                    </SplitButton>
                  )}
                </DisabledByAccessInfoCheck>
              </VisibleFor>
            </div>
          </div>

          <br />

          <Row>
            <Col xs={12} sm={6}>
              {(!isService || uuid) && (
                <FormGroup controlId="uxName" className="form-group">
                  <FormLabel>Name: *</FormLabel>
                  <FormControl
                    name="name"
                    type="text"
                    required
                    disabled={isService && uuid}
                    onChange={handleInputChange}
                    value={name}
                  />
                </FormGroup>
              )}
              {!isService && (
                <FormGroup controlId="uxLocation" className="form-group">
                  <FormLabel>
                    <FormattedMessage
                      id="field.location"
                      defaultMessage="Location"
                    />
                    : *

                    {isURI(location) && (
                      <Link
                        target="_blank"
                        className="spacer-left"
                        rel="noopener noreferrer"
                        to={getRouteURI(ROUTES.LOCATIONS,
                          null,
                          { uuid: extractUuid(location) },
                          true)}
                      >
                        <FontAwesomeIcon icon={faExternalLink} />
                      </Link>
                    )}
                  </FormLabel>
                  <FormControl
                    as="select"
                    name="location"
                    onChange={handleInputChange}
                    required
                    value={location}
                  >
                    {
                      // We show `Choose...` option only if no value is selected
                      // Anyway, this is an edge-case
                      // and no exact STRs found at the moment to see this issue
                      // since by default first location is auto-selected
                    }
                    {!location && (
                      <FormattedMessage
                        id="field.choose"
                        defaultMessage="Choose…"
                      >
                        {text => <option value="">{text}</option>}
                      </FormattedMessage>
                    )}
                    {locations.map(({ uri, name }) => (
                      <option key={uri} value={uri}>
                        {name}
                      </option>
                    ))}
                  </FormControl>
                </FormGroup>
              )}
              {!isService && (
                <FormGroup className="form-group" controlId="uxSubLocation">
                  <FormLabel>
                    <FormattedMessage
                      id="field.sub_location"
                      defaultMessage="Sub-Location"
                    />
                    : *
                    {subLocationsFetching && <Loading inline className="spacer-left" />}
                  </FormLabel>
                  <FormControl
                    name="sub_location"
                    as="select"
                    disabled={!location || subLocationsFetching}
                    onChange={handleInputChange}
                    required
                    value={subLocation}
                  >
                    <option key="placeholder" value="" selected>
                      {!location ? 'Select a Location' : 'Select a Sub-Location'}
                    </option>
                    {subLocations.map(subLocation => (
                      <option key={subLocation.uri} value={subLocation.uri}>
                        {subLocation.name}
                      </option>
                    ))}
                  </FormControl>
                </FormGroup>
              )}
              {
                // For MVP hiding post-processor-type field for SP role
                // since SP role does not have access to Post Processor Type API
                !isServiceProviderRole && (
                  <FormGroup controlId="uxPostProcessorType" className="form-group">
                    <FormLabel>
                      <FormattedMessage
                        id="field.postProcessorType"
                        defaultMessage="Post Processor Type"
                      />
                      : *
                      {isURI(postProcessorType) && (
                        <Button
                          variant="link"
                          className="printer-type-icon p-a-0"
                          onClick={() => setPostProcessorTypeModalVisible(true)}
                        >
                          <PostProcessorTypesIcon className="custom-navbar-icon-link" />
                        </Button>
                      )}
                    </FormLabel>
                    <InputGroup>

                      <CreatableSelect
                        data-cy="uxPostProcessorType"
                        key={newPostProcessorTypeName}
                        styles={selectInputStyles}
                        isLoading={postProcessorTypeOnTheFlySubmitting || postProcessorTypeOnTheFlyFetching}
                        placeholder="Select Post Processor Type"
                        isDisabled={uuid || postProcessorTypeOnTheFlySubmitting || postProcessorTypeOnTheFlyFetching}
                        components={{ LoadingIndicator: () => (<Loading inline className="spacer-right" />) }}
                        value={selectedOption}
                        options={formattedPostProcessorTypes}
                        required
                        onChange={(value, actionMeta) =>
                          handlePostProcessorTypeChange(value, actionMeta, handleChangePostProcessorType)}
                        isClearable
                      />
                    </InputGroup>
                  </FormGroup>
                )
              }
              <FormGroup controlId="uxDuration" className="form-group">
                <FormLabel>
                  Workstation Default Duration: *
                </FormLabel>
                <InputGroup>
                  {
                    isService ? (
                      <FormControl
                        defaultValue={duration ? convertToDays(duration) : ''}
                        name="duration"
                        onChange={handleInputChange}
                        required
                        isInvalid={isDurationInvalid}
                      />
                    ) : (
                      <FormControl
                        defaultValue={duration}
                        name="duration"
                        onChange={handleInputChange}
                        required
                        isInvalid={isDurationInvalid}
                      />
                    )
                  }
                  <InputGroup.Text>
                    {
                      isService ? (
                        <span>days</span>
                      ) : (
                        <FormattedMessage
                          id="field.workstation-duration"
                          defaultMessage="HH:MM"
                        />
                      )
                    }

                  </InputGroup.Text>
                </InputGroup>
              </FormGroup>
              {isDurationInvalid && (<span className="text-start text-danger">Please use HH:MM format above</span>)}
              <LastUpdated resource={postProcessor} />
            </Col>
            <Col xs={12} sm={6}>
              {selectedPostProcessorType && (
                <Card bg="dark">
                  <Card.Header
                    className="pd-exp inverse"
                  >{`Post Processor Type: ${selectedPostProcessorType.name}`}
                  </Card.Header>
                  <div className="card-body-wrapper">
                    <Card.Body>
                      <ListGroup fill>
                        <ListGroupItem style={styles.flexRow}>
                          <strong>
                            <FormattedMessage
                              id="field.description"
                              defaultMessages="Description"
                            />:
                          </strong>
                          <span>{selectedPostProcessorType.description}</span>
                        </ListGroupItem>
                        <ListGroupItem style={styles.flexRow}>
                          <strong>
                            <FormattedMessage
                              id="field.duration"
                              defaultMessage="Duration"
                            />:
                          </strong>
                          <span>
                            {selectedPostProcessorType.duration ? (
                              <TimeFormattedHHMMSSWithTooltip timeInSeconds={selectedPostProcessorType.duration}>
                                <FormattedOptionalDuration
                                  value={selectedPostProcessorType.duration}
                                  intervalFormat={FORMATTED_DURATION_TYPES.HH_MM_SS}
                                />
                              </TimeFormattedHHMMSSWithTooltip>

                            ) : (
                              <FormattedMessage
                                id="notAvailable"
                                defaultMessage="N/A"
                              />
                            )}
                          </span>
                        </ListGroupItem>
                      </ListGroup>
                    </Card.Body>
                  </div>
                </Card>
              )}
              {(isService && postProcessor) && (
                <PostProcessorProvidersContainer processorService={postProcessor} />
              )}
            </Col>
          </Row>
        </form>
      )}
    </div>
  );
};

PostProcessorForm.defaultProps = {
  location: '',
  subLocation: '',
  postProcessorType: '',
  selectedPostProcessorType: null,
  uuid: null,
  postProcessor: null,
  newPostProcessorTypeName: null,
  postProcessorTypeOnTheFlySubmitting: false,
  postProcessorTypeOnTheFlyFetching: false,
};

PostProcessorForm.propTypes = {
  duration: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleArchive: PropTypes.func.isRequired,
  handleUnarchive: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.string,
  subLocation: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subLocationsFetching: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  postProcessorType: PropTypes.string,
  postProcessorTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedPostProcessorType: PropTypes.shape({
    description: PropTypes.string,
    duration: PropTypes.number,
    name: PropTypes.string,
  }),
  submitting: PropTypes.bool.isRequired,
  uuid: PropTypes.string,
  isService: PropTypes.bool.isRequired,
  postProcessor: postProcessorTypeResourceType,
  isServiceProviderRole: PropTypes.bool.isRequired,
  isDurationInvalid: PropTypes.bool.isRequired,
  handleChangePostProcessorType: PropTypes.func.isRequired,
  setNewPostProcessorTypeName: PropTypes.func.isRequired,
  newPostProcessorTypeName: PropTypes.oneOfType([PropTypes.string, null]),
  postProcessorTypeOnTheFlySubmitting: PropTypes.bool,
  postProcessorTypeOnTheFlyFetching: PropTypes.bool,
  setPostProcessorTypeModalVisible: PropTypes.func.isRequired,
};

export default PostProcessorForm;

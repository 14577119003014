import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import FusionLogo from 'rapidfab/icons/fusion-logo';

const FusionButtonLink = ({ href, content, hidden, newTab }) => {
  if (hidden) return null;

  const target = newTab ? '_blank' : undefined;
  const linkRelationship = newTab ? 'noopener noreferrer' : undefined;
  const linkClasses = classNames('fusion-connect-button', {
    'fusion-connect-link-disabled': !href,
  });

  return (
    <a
      href={href}
      className={linkClasses}
      target={target}
      rel={linkRelationship}
    >
      <FusionLogo />
      {content && <div className="fusion-connect-button-text">{content}</div>}
    </a>
  );
};

FusionButtonLink.defaultProps = {
  content: 'View in Fusion',
  hidden: false,
  newTab: true,
};

FusionButtonLink.propTypes = {
  href: PropTypes.string.isRequired,
  content: PropTypes.string,
  hidden: PropTypes.bool,
  newTab: PropTypes.bool,
};

export default FusionButtonLink;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, ListGroup, ListGroupItem, Card, Row } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import { shipmentResourceType, runResourceType } from 'rapidfab/types';
import { API_RESOURCES } from 'rapidfab/constants';
import ResourceLink from 'rapidfab/components/ResourceLink';
import DownloadPackingSlipButton from 'rapidfab/components/records/order/edit/DownloadPackingSlipButton';

const RunShipment = ({ shipment, run }) => (
  <Card bg="dark" className="mb15">
    <Card.Header className="pd-exp pe-1 inverse d-flex align-items-center justify-content-between">
      <FormattedMessage id="shipmentRunTitle" defaultMessage="Shipment" />
      {shipment?.uri && (
        <DownloadPackingSlipButton
          documentEndpointName={API_RESOURCES.SHIPMENT_PACKING_SLIP}
          resourceUri={shipment.uri}
          run={run}
        />
      )}
    </Card.Header>
    <div className="card-body-wrapper">
      <ListGroup fill>
        {shipment?.uri ? (
          <ListGroupItem>
            <Row className="align-items-center justify-content-between">
              <Col xs={12}>
                <ResourceLink uri={shipment.uri} />
              </Col>
            </Row>
          </ListGroupItem>
        ) : (
          <ListGroupItem>
            <FormattedMessage
              id="noShipmentForRun"
              defaultMessage="No shipment associated with this run"
            />
          </ListGroupItem>
        )}
      </ListGroup>
    </div>
  </Card>
);

RunShipment.propTypes = {
  shipment: PropTypes.arrayOf(shipmentResourceType).isRequired,
  run: runResourceType.isRequired,
};

export default RunShipment;

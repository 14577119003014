import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'rapidfab/components/Loading';
import Tooltip from 'rapidfab/components/Tooltip';
import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const renderTooltipByCondition = (
  isFetching,
  shouldRenderTooltip,
  tooltipTitle,
  tooltipText,
  loadingComponent = <Loading inline className="spacer-left mb0" />,
  icon = faTriangleExclamation,
) => {
  if (isFetching) {
    return loadingComponent;
  }

  if (!shouldRenderTooltip) return null;

  return (
    <Tooltip
      id="render-data-tooltip"
      placement="bottom"
      trigger={(
        <span className="powder_quality_badge">
          <FontAwesomeIcon
            icon={icon}
            color="#FF9903"
            className="spacer-right"
          />
          <span>{tooltipTitle}</span>
        </span>
      )}
    >
      <div>{tooltipText}</div>
    </Tooltip>
  );
};

import { resetRcTooltipInnerStyle } from 'rapidfab/constants/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { faObjectGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RCTooltip from 'rc-tooltip';
import { Link } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';

/**
 * LinkedText
 *
 * Renders the children wrapped in a <Link> if the item has a valid URI.
 */
const LinkedText = ({ item, children, uriDetails }) => {
  const { accessor, route } = uriDetails;
  const link =
    route && accessor && item[accessor]
      ? getRouteURI(route, null, { uuid: extractUuid(item[accessor]) }, true)
      : null;
  return link ? <Link to={link}>{children}</Link> : children;
};

LinkedText.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
  uriDetails: PropTypes.shape({
    accessor: PropTypes.string,
    route: PropTypes.string,
    display: PropTypes.string,
  }),
};

LinkedText.defaultProps = {
  uriDetails: {},
};

/**
 * TooltipItem
 *
 * Displays the provided “rest” items as a comma–separated list.
 * Each item shows an icon and (if possible) is rendered as a link.
 */
const TooltipItem = ({ data, uriDetails }) => {
  const { display, accessor } = uriDetails;
  return (
    <p className="truncated-items-tooltip-overlay darkTooltip">
      {data.map((item, index) => {
        const key = accessor ? item[accessor] : item;
        const name = display ? item[display] : item;
        const comma = index < data.length - 1 ? ', ' : '';
        return (
          <span key={key} data-cy="hidden_items">
            <FontAwesomeIcon className="spacer-right" icon={faObjectGroup} />
            <LinkedText item={item} uriDetails={uriDetails}>
              <strong>&quot;{name}&quot;</strong>
            </LinkedText>
            {comma}&nbsp;
          </span>
        );
      })}
    </p>
  );
};

TooltipItem.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  uriDetails: PropTypes.shape({
    accessor: PropTypes.string,
    route: PropTypes.string,
    display: PropTypes.string,
  }),
};

TooltipItem.defaultProps = {
  uriDetails: {},
};

/**
 * TruncatedItemsList
 *
 * Displays the first few items (up to showCount) in a list.
 * If there are more items, a tooltip (using TooltipItem) shows the remaining items.
 */
const TruncatedItemsList = ({ data, showCount, uriDetails, hideIcon }) => {
  const firstItemsToShow = data.slice(0, showCount);
  const restItems = data.slice(showCount);
  const { display, accessor } = uriDetails;

  return (
    <div className="d-flex align-items-center">
      <div className="mt5 mb5 mr10">
        {firstItemsToShow.map((item, index) => {
          const key = accessor ? item[accessor] : item;
          const name = display ? item[display] : item;
          // If there are extra items, always add a comma after each entry.
          // Otherwise, add a comma only if this isn’t the last item.
          const shouldAppendComma =
            restItems.length > 0 || index < firstItemsToShow.length - 1;
          return (
            <div
              key={key}
              data-cy="hidden_items"
              className="d-flex align-items-baseline"
            >
              {!hideIcon && <FontAwesomeIcon className="spacer-right" icon={faObjectGroup} />}
              <LinkedText item={item} uriDetails={uriDetails}>
                <span>{name}{shouldAppendComma ? ',' : ''}</span>
              </LinkedText>
            </div>
          );
        })}
      </div>

      {restItems.length > 0 && (
        <RCTooltip
          placement="bottom"
          destroyTooltipOnHide
          overlayInnerStyle={resetRcTooltipInnerStyle}
          mouseLeaveDelay={0.4}
          overlay={<TooltipItem data={restItems} uriDetails={uriDetails} />}
        >
          <div data-cy="rest-labels">{`+${restItems.length}`}</div>
        </RCTooltip>
      )}
    </div>
  );
};

TruncatedItemsList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  showCount: PropTypes.number,
  uriDetails: PropTypes.shape({
    accessor: PropTypes.string,
    route: PropTypes.string,
    display: PropTypes.string,
  }),
  hideIcon: PropTypes.bool,
};

TruncatedItemsList.defaultProps = {
  showCount: 3,
  uriDetails: {},
  hideIcon: false,
};

export default TruncatedItemsList;

import _difference from 'lodash/difference';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { API_RESOURCES, FEATURES, ORDER_SHIPPING_GROUPING_OPTIONS, PRIORITIES, USER_ROLES } from 'rapidfab/constants';
import {
  getCurrentUserRole,
  getLocations,
  getShippingsAlphabetized,
  getSubmittedOrders,
  getUsers,
  isCurrentUserRestricted,
  isFeatureEnabled,
} from 'rapidfab/selectors';
import userSort from 'rapidfab/utils/userSort';

import NewOrderForm from 'rapidfab/components/records/order/new/NewOrderForm';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import { NEW_ORDER_FORM_CONTAINER } from 'rapidfab/constants/forms';

const businessSegmentProfiledFields =
  _difference(NEW_ORDER_FORM_CONTAINER.FIELDS, NEW_ORDER_FORM_CONTAINER.UNIQUE_FIELDS);
const threeMAutofillFields = ['customer_name', 'customer_email'];

const NewOrderFormContainer = props => {
  const isUserRestricted = useSelector(isCurrentUserRestricted);
  const shippings = useSelector(getShippingsAlphabetized);
  const users = useSelector(getUsers).sort(userSort);
  const locationsAvailable = useSelector(getLocations);
  const [usersFetchState, setUsersFetchState] = useState({ offset: 0, count: 1 });

  const initialShipping = shippings[0] ? shippings[0].uri : null;

  const boeingOrderFieldsFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.BOEING_ORDER_FIELDS));
  const threeMOrderFieldsFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.THREE_M_MAIN_BUREAU_ORDER_FIELDS));
  const beehiveOrderFieldsFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.BEEHIVE3D_ORDER_FIELDS));
  const xerox3DPFieldsFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.XEROX3DP_FIELDS));
  const isDanfossUser = useSelector(state => isFeatureEnabled(state, FEATURES.ORDER_BUSINESS_SEGMENT));
  const isShipmentForOrderFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.SHIPMENT_FOR_ORDER));
  const isDigitalDesignWarehouseFeatureEnabled = useSelector(state => isFeatureEnabled(
    state, FEATURES.DIGITAL_DESIGN_WAREHOUSE,
  ));
  const isPOCUKOrderFieldsFeatureEnabled = useSelector(
    state => isFeatureEnabled(state, FEATURES.POC_UK_ORDER_FIELDS),
  );
  const isEatonOrderFieldsEnabled = useSelector(
    state => isFeatureEnabled(state, FEATURES.EATON_ORDER_FIELDS),
  );

  const isAmazonUser = useSelector(state => isFeatureEnabled(state, FEATURES.AMAZON_ORDER_FIELDS));

  const submittedOrders = useSelector(getSubmittedOrders);
  // adding users having default Whole Order Shipment checkbox enabled.
  const usersWithDefaultEnabled =
    beehiveOrderFieldsFeatureEnabled || isDanfossUser || isAmazonUser;
  const getShippingGrouping = () => {
    if (usersWithDefaultEnabled) {
      return ORDER_SHIPPING_GROUPING_OPTIONS.BY_ORDER;
    }

    if (isShipmentForOrderFeatureEnabled) {
      return ORDER_SHIPPING_GROUPING_OPTIONS.BY_ORDER;
    }

    return ORDER_SHIPPING_GROUPING_OPTIONS.BY_BATCHING_STRATEGY;
  };
  const currentUserRole = useSelector(getCurrentUserRole);
  const isManager = currentUserRole === USER_ROLES.MANAGER;

  const initialValues = !_isEmpty(props?.initialFormValues) ?
    props.initialFormValues : {
      custom_field_values: [],
      due_date: isPOCUKOrderFieldsFeatureEnabled ? dayjs().add(7, 'day').format('YYYY-MM-DD') : null,
      shipping: {
        uri: initialShipping,
      },
      priority: PRIORITIES.NORMAL,
      shipping_grouping: getShippingGrouping(),
    };

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => NEW_ORDER_FORM_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  if (threeMOrderFieldsFeatureEnabled) {
    // For companies with requested fields to be auto-filled,
    // Data will be auto-filled with information from the most recent previous order
    // Stored in LocalStorage. Only exceptions are fields from uniqueFields

    const autoFillFields = threeMOrderFieldsFeatureEnabled ?
      threeMAutofillFields
      :
      businessSegmentProfiledFields;
    try {
      const latestSubmittedOrder = submittedOrders && submittedOrders[0];
      if (latestSubmittedOrder) {
        _forEach(autoFillFields, value => {
          let fieldName = value;

          // For fields like 'shipping.name'
          // makes sense to copy whole 'shipping' object with all values
          const possiblyDictDotIndex = fieldName.indexOf('.');
          const isDictField = (possiblyDictDotIndex > -1);
          if (isDictField) {
            fieldName = value.slice(0, possiblyDictDotIndex);
          }

          if (latestSubmittedOrder[fieldName]) {
            initialValues[fieldName] = latestSubmittedOrder[fieldName];
          }
        });
      }
    } catch {
      // empty
    }
  }

  const dispatch = useDispatch();

  const fetchAllUsers = useCallback(async () => {
    const limit = 20;
    const response = await dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list(
      (isManager ? { archived: false } : {}), { limit, offset: usersFetchState.offset }, {}, true));

    setUsersFetchState(previous => (
      { offset: previous.offset + limit, count: response?.json?.meta?.count || 0 }
    ));
  }, [isManager, usersFetchState.offset]);

  const shouldFetchMoreUsers = usersFetchState.offset < usersFetchState.count;

  useEffect(() => {
    // Set initial order fields so we could reset the form
    // For the components up level
    if (props.setInitialOrderFields) {
      props.setInitialOrderFields(initialValues);
    }
  }, []);

  const selected = {
    isUserRestricted,
    initialFormValues,
    shippings,
    users,
    formValues: props.formValues,
    customOrderFieldReferences: props.customOrderFieldReferences,
    locationsAvailable,
    isBoeingOrderFieldsFeatureEnabled: boeingOrderFieldsFeatureEnabled,
    is3MOrderFieldsFeatureEnabled: threeMOrderFieldsFeatureEnabled,
    isBeehiveOrderFieldsFeatureEnabled: beehiveOrderFieldsFeatureEnabled,
    isXerox3DPFieldsFeatureEnabled: xerox3DPFieldsFeatureEnabled,
    isEatonOrderFieldsEnabled,
    isDanfossUser,
    submittedOrders,
    isDigitalDesignWarehouseFeatureEnabled,
    shouldFetchMoreUsers,
  };

  const dispatched = {
    fetchAllUsers,
  };

  return (
    <NewOrderForm
      {...props}
      {...selected}
      {...dispatched}
    />
  );
};

NewOrderFormContainer.defaultProps = {
  setInitialOrderFields: null,
  initialFormValues: null,
};

NewOrderFormContainer.propTypes = {
  formValues: PropTypes.shape({}).isRequired,
  customOrderFieldReferences: PropTypes.shape({}).isRequired,
  setInitialOrderFields: PropTypes.func,
  initialFormValues: PropTypes.shape({}),
};

export default NewOrderFormContainer;

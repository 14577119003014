import { OverlayTrigger, Tooltip as BSTooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const Tooltip = ({ id, children, placement, trigger, hideTooltipText }) => (
  <OverlayTrigger
    placement={placement}
    overlay={hideTooltipText ? <></> : (
      <BSTooltip id={id}>
        {children}
      </BSTooltip>
    )}
  >
    {trigger}
  </OverlayTrigger>
);

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  trigger: PropTypes.node,
  placement: PropTypes.string,
  hideTooltipText: PropTypes.bool,
};

Tooltip.defaultProps = {
  placement: 'top',
  trigger: <FontAwesomeIcon icon={faQuestionCircle} className="spacer-left spacer-right" />,
  hideTooltipText: false,
};

export default Tooltip;

export const TooltipPointer = ({ id, children, trigger, showTooltip }) => {
  const tooltipRef = useRef(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: null, y: null });

  // Adjust tooltip position based on the mouse movement
  const updateTooltipPosition = event => {
    if (!tooltipRef.current) return;

    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    let x = event.clientX + 10;
    let y = event.clientY + 20;

    // Center the tooltip horizontally
    x -= tooltipRect.width / 2;

    // Account for scroll
    x += window.scrollX;
    y += window.scrollY;

    // Ensure tooltip doesn't overflow the screen
    x = Math.max(20, Math.min(x, window.innerWidth - tooltipRect.width - 20));

    setTooltipPosition({ x, y });
  };

  useEffect(() => {
    if (!showTooltip) {
      return setTooltipPosition({ x: null, y: null });
    }

    const handleMouseMove = event => updateTooltipPosition(event);
    document.addEventListener('mousemove', handleMouseMove);

    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, [showTooltip]);

  return (
    <div>
      {showTooltip && (
        createPortal(
          <div
            id={id}
            ref={tooltipRef}
            className="tooltip-pointer"
            style={{
              left: `${tooltipPosition.x}px`,
              top: `${tooltipPosition.y}px`,
            }}
          >
            {children}
          </div>,
          document.body,
        )
      )}
      { trigger }
    </div>
  );
};

TooltipPointer.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showTooltip: PropTypes.bool.isRequired,
  trigger: PropTypes.node,
};

TooltipPointer.defaultProps = {
  trigger: <FontAwesomeIcon icon={faQuestionCircle} className="spacer-left spacer-right" />,
};

export const ActionTooltip = ({
  id,
  children,
  placement = 'top',
  trigger,
  hideTooltipText = false,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);

  const handleMouseLeave = () => setShowTooltip(false);

  return (
    <OverlayTrigger
      placement={placement}
      show={showTooltip}
      // Delay the tooltip hide to prevent flickering and allow the user to interact with the tooltip content
      delay={{ show: 0, hide: 200 }}
      popperConfig={{
        modifiers: [
          {
            name: 'offset',
            options: {
              // Shift the tooltip slightly to the bottom
              offset: [0, 5],
            },
          },
        ],
      }}
      overlay={
        !hideTooltipText && (
          <BSTooltip
            id={id}
            className="action-tooltip"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="action-tooltip-inner-content">{children}</div>
          </BSTooltip>
        )
      }
    >
      <span
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ cursor: 'pointer' }}
      >
        {trigger}
      </span>
    </OverlayTrigger>
  );
};

ActionTooltip.defaultProps = {
  placement: 'top',
  hideTooltipText: false,
};

ActionTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  trigger: PropTypes.node.isRequired,
  placement: PropTypes.string,
  hideTooltipText: PropTypes.bool,
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { API_RESOURCES, LINE_ITEM_STATUS, ROUTES } from 'rapidfab/constants';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import { getPiecesForLineItem, getPrintsForLineItem, getProcessStepsForLineItem } from 'rapidfab/selectors';
import { useNavigate } from 'react-router-dom';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { FormattedMessage } from 'rapidfab/i18n';

const SchedulePrintLineItemButton = ({ lineItem, isAutoRunChecked }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pieces = useSelector(state => getPiecesForLineItem(state, lineItem));
  const process_step = useSelector(state => getProcessStepsForLineItem(state, lineItem));
  const firstStep = process_step.find(step => step.step_positions[0] === 1);
  const prints = useSelector(state => getPrintsForLineItem(state, { uri: lineItem.uri }));
  const hasUnscheduledPieces = prints.filter(print => print.process_step_position === 1)
    .some(print => print.run === null);

  const fetchPrinterByPrinterType = async () => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list({
      printer_type: firstStep?.workstation_type_uri,
    }));
  };

  useEffect(() => {
    if (firstStep?.workstation_type_uri) {
      fetchPrinterByPrinterType();
    }
  }, [lineItem?.uri]);

  const asyncLocalStorage = {
    async setItem(key, value) {
      await null;
      return localStorage.setItem(key, value);
    },
    async getItem(key) {
      await null;
      return localStorage.getItem(key);
    },
  };

  const navigateLineItemToNewRun = () => {
    Object.keys(localStorage)
      .forEach(key => {
        if (/line-item/.test(key)) {
          localStorage.removeItem(key);
        }
      });
    asyncLocalStorage.setItem(lineItem.uri, JSON.stringify({
      machine: firstStep.workstation_type_uri,
      prints: pieces.map(piece => piece.uri),
    }))
      .then(() => asyncLocalStorage.getItem(lineItem.uri))
      .then(value => {
        const storedData = JSON.parse(value);

        if (storedData.prints.length > 0) {
          navigate(
            getRouteURI(ROUTES.RUN_CREATE,
              {}, { buildPlateFilledByLineItem: lineItem.uri }, true));
        }
      });
  };

  const toolTipMessageDisplay = () => {
    if (lineItem.status !== LINE_ITEM_STATUS.CONFIRMED) {
      return (
        <FormattedMessage
          id="toolTip.confirmed.LineItem"
          defaultMessage="Line Items can only be scheduled when they are confirmed. Please check line item status."
        />
      );
    }

    if (!hasUnscheduledPieces) {
      return (
        <FormattedMessage
          id="tooltip.scheduleThistoolTip.confirmed.LineItem"
          defaultMessage="All Pieces for this Line Item are scheduled. Please see the Order Summary to see all of the scheduled Runs or use the Piece table to see a specific Peice's manufacturing progress."
        />
      );
    }
    if (isAutoRunChecked) {
      return (
        <FormattedMessage
          id="tooltip.autoRun.selected"
          defaultMessage="Auto run must be deslected to schedule line items through schedule this feature"
        />
      );
    }
    return null;
  };

  const disableScheduleThisButton = (
    !hasUnscheduledPieces ||
    lineItem.status !== LINE_ITEM_STATUS.CONFIRMED ||
    isAutoRunChecked ||
    !firstStep
  );

  return (
    !disableScheduleThisButton ? (
      <Button
        disabled={disableScheduleThisButton}
        variant="primary"
        size="sm"
        onClick={navigateLineItemToNewRun}
        className="w-100"
      >
        Schedule This
      </Button>
    ) : (
      <OverlayTrigger
        placement="top"
        overlay={(
          <Tooltip>
            {toolTipMessageDisplay()}
          </Tooltip>
        )}
      >
        <span className="w-100">

          <Button
            disabled={disableScheduleThisButton}
            variant="primary"
            size="sm"
            onClick={navigateLineItemToNewRun}
            className="w-100"
          >
            Schedule This
          </Button>
        </span>
      </OverlayTrigger>
    )
  );
};

SchedulePrintLineItemButton.propTypes = {
  lineItem: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  isAutoRunChecked: PropTypes.bool.isRequired,
};

export default SchedulePrintLineItemButton;

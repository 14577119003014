import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import {
  Button,
  Col,
  FormLabel,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  Card,
  Row,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import {
  FORMATTED_DURATION_TYPES,
  FormattedDateTime,
  FormattedMessageMappingOption,
  FormattedOptionalDuration,
} from 'rapidfab/i18n';
import {
  ORDER_STATUS_MAP,
  PREP_TASK_RECORD_STATUSES_MAP,
} from 'rapidfab/mappings';
import { PREP_TASK_RECORD_STATUS_TRANSFORMATIONS } from 'rapidfab/transformations';
import dayjs from 'dayjs';
import Feature from 'rapidfab/components/Feature';
import {
  COMMENT_RELATED_TABLE_NAMES,
  FEATURES,
} from 'rapidfab/constants';
import PriorityLabel from 'rapidfab/components/records/run/PriorityLabel';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import CommentsContainer from 'rapidfab/containers/CommentsContainer';
import { prepTaskType, prepTaskRecordType, orderType } from 'rapidfab/types';

import { Form, Field } from 'react-final-form';
import { FormControlSelect } from 'rapidfab/components/formTools';
import COMMENT_RESOURCE_TYPES from 'rapidfab/constants/CommentResourceType';

const TwoColumnsRow = ({ left, right, leftWidth, rightWidth, leftTextAlign, rightTextAlign }) => (
  <Row className="clearfix">
    <Col md={leftWidth} style={{ 'text-align': leftTextAlign }}>
      {left}
    </Col>
    <Col
      md={rightWidth}
      style={{ 'text-align': rightTextAlign }}
      className="wrap-text"
    >
      {right}
    </Col>
  </Row>
);

TwoColumnsRow.defaultProps = {
  left: null,
  right: null,
  leftWidth: 4,
  rightWidth: 8,
  leftTextAlign: 'left',
  rightTextAlign: 'left',
};

TwoColumnsRow.propTypes = {
  leftTextAlign: PropTypes.string,
  rightTextAlign: PropTypes.string,
  leftWidth: PropTypes.number,
  rightWidth: PropTypes.number,
  left: PropTypes.node,
  right: PropTypes.node,
};

const PrepTaskRecordModal = ({
  onClose,
  onSubmit,
  isSubmitting,
  prepTaskRecord,
  isLoading,
  prepTask,
  order,
  isUpdateAllowed,
  orderOwner,
  initialFormValues,
}) => {
  if (isLoading) {
    return (
      <Modal show backdrop="static">
        <ModalBody>
          <Loading />
        </ModalBody>
      </Modal>
    );
  }

  if (!prepTaskRecord) {
    // This should not happen, but as an edge-case, prevent throwing errors
    return null;
  }

  const statusOptions =
    PREP_TASK_RECORD_STATUS_TRANSFORMATIONS[prepTaskRecord.status];

  const endTimeMoment = prepTaskRecord.end_time
    ? dayjs(prepTaskRecord.end_time)
    : dayjs();
  const dwellTimeActuals = endTimeMoment.diff(
    dayjs(prepTaskRecord.start_dwell_time),
    'seconds',
  );

  return (
    <Modal size="lg" show onHide={onClose} backdrop="static">
      <Form
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader closeButton>
              <ModalTitle as="h3">{prepTask && prepTask.name}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={6}>
                  {prepTask && (
                    <Card className="m-b" bg="light">
                      <Card.Header className="pd-exp">Task</Card.Header>
                      <Card.Body>
                        <TwoColumnsRow
                          left={(
                            <b>
                              <FormattedMessage
                                id="field.name"
                                defaultMessage="Name"
                              />
                              :
                            </b>
                          )}
                          right={prepTask.name}
                        />
                        <TwoColumnsRow
                          left={(
                            <b>
                              <FormattedMessage
                                id="field.description"
                                defaultMessage="Description"
                              />
                              :
                            </b>
                          )}
                          right={prepTask.description}
                        />
                      </Card.Body>
                    </Card>
                  )}
                  <Card bg="light">
                    <Card.Header className="pd-exp">Time</Card.Header>
                    <Card.Body>
                      <TwoColumnsRow
                        leftWidth={6}
                        rightWidth={6}
                        left={null}
                        right={(
                          <TwoColumnsRow
                            leftWidth={6}
                            rightWidth={6}
                            leftTextAlign="center"
                            rightTextAlign="center"
                            left={(
                              <b>
                                <FormattedMessage
                                  id="estimates"
                                  defaultMessage="Estimates"
                                />
                              </b>
                            )}
                            right={(
                              <b>
                                <FormattedMessage
                                  id="actuals"
                                  defaultMessage="Actuals"
                                />
                              </b>
                            )}
                          />
                        )}
                      />
                      <TwoColumnsRow
                        leftWidth={6}
                        rightWidth={6}
                        left={(
                          <b>
                            <FormattedMessage
                              id="dwellTime.days"
                              defaultMessage="Dwell Time (days)"
                            />
                            :
                          </b>
                        )}
                        right={(
                          <TwoColumnsRow
                            leftWidth={6}
                            rightWidth={6}
                            leftTextAlign="center"
                            rightTextAlign="center"
                            left={(
                              <FormattedOptionalDuration
                                intervalFormat={
                                  FORMATTED_DURATION_TYPES.NO_FORMATTING
                                }
                                value={
                                  prepTask && prepTask.user_estimated_dwell_time
                                }
                              />
                            )}
                            right={(
                              <FormattedOptionalDuration
                                intervalFormat={FORMATTED_DURATION_TYPES.DAYS}
                                value={dwellTimeActuals}
                              />
                            )}
                          />
                        )}
                      />
                      <TwoColumnsRow
                        leftWidth={6}
                        rightWidth={6}
                        left={(
                          <b>
                            <FormattedMessage
                              id="inProgressTime.hours"
                              defaultMessage="In Progress Time (hours)"
                            />
                            :
                          </b>
                        )}
                        right={(
                          <TwoColumnsRow
                            leftWidth={6}
                            rightWidth={6}
                            leftTextAlign="center"
                            rightTextAlign="center"
                            left={(
                              <FormattedOptionalDuration
                                intervalFormat={
                                  FORMATTED_DURATION_TYPES.NO_FORMATTING
                                }
                                value={
                                  prepTask && prepTask.user_estimated_work_time
                                }
                              />
                            )}
                            right={(
                              <FormattedOptionalDuration
                                intervalFormat={FORMATTED_DURATION_TYPES.HOURS}
                                value={prepTaskRecord.actuals.in_progress_time}
                              />
                            )}
                          />
                        )}
                      />
                      <TwoColumnsRow
                        leftWidth={6}
                        rightWidth={6}
                        left={(
                          <b>
                            <FormattedMessage
                              id="onHoldTime.hours"
                              defaultMessage="On Hold Time (hours)"
                            />
                            :
                          </b>
                        )}
                        right={(
                          <TwoColumnsRow
                            leftWidth={6}
                            rightWidth={6}
                            leftTextAlign="center"
                            rightTextAlign="center"
                            left={(
                              <FormattedMessage
                                id="notAvailable"
                                defaultMessage="N/A"
                              />
                            )}
                            right={(
                              <FormattedOptionalDuration
                                intervalFormat={FORMATTED_DURATION_TYPES.HOURS}
                                value={prepTaskRecord.actuals.on_hold_time}
                              />
                            )}
                          />
                        )}
                      />
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={6}>
                  <div className="clearfix mb15">
                    <Button
                      className="pull-right"
                      type="submit"
                      variant="success"
                      disabled={isSubmitting || isLoading || !isUpdateAllowed}
                    >
                      { isSubmitting || isLoading ? <Loading /> : 'Save' }
                    </Button>
                  </div>
                  <FormGroup className="m-b" controlId="prepTaskRecordStatus">
                    <FormLabel>
                      <FormattedMessage id="field.status" defaultMessage="Status" />
                      :
                    </FormLabel>
                    <Field
                      name="status"
                      type="select"
                      render={props => (
                        <FormControlSelect
                          {...props.input}
                          required
                        >
                          {statusOptions &&
                  statusOptions.map(statusOption => (
                    <FormattedMessageMappingOption
                      key={statusOption}
                      mapping={PREP_TASK_RECORD_STATUSES_MAP}
                      value={statusOption}
                    />
                  ))}
                        </FormControlSelect>
                      )}
                    />
                  </FormGroup>
                  {order && (
                    <Card className="m-b" bg="light">
                      <Card.Header className="pd-exp">Order</Card.Header>
                      <Card.Body>
                        <TwoColumnsRow
                          left={(
                            <b>
                              <FormattedMessage
                                id="field.name"
                                defaultMessage="Name"
                              />
                              :
                            </b>
                          )}
                          right={
                            order.name
                          }
                        />
                        <Feature featureName={FEATURES.PRIORITY}>
                          <TwoColumnsRow
                            left={(
                              <b>
                                <FormattedMessage
                                  id="field.priority"
                                  defaultMessage="Priority"
                                />
                                :
                              </b>
                            )}
                            right={(
                              <PriorityLabel
                                value={order.priority}
                                containerClassName="justify-content-start"
                              />
                            )}
                          />
                        </Feature>
                        <TwoColumnsRow
                          left={(
                            <b>
                              <FormattedMessage
                                id="field.status"
                                defaultMessage="Status"
                              />
                              :
                            </b>
                          )}
                          right={
                            <FormattedMessage {...ORDER_STATUS_MAP[order.status]} />
                          }
                        />
                        <TwoColumnsRow
                          left={(
                            <b>
                              <FormattedMessage
                                id="field.owner"
                                defaultMessage="Owner"
                              />
                              :
                            </b>
                          )}
                          right={
                            orderOwner ? (
                              orderOwner.name
                            ) : (
                              <FormattedMessage
                                id="notAvailable"
                                defaultMessage="N/A"
                              />
                            )
                          }
                        />
                        <TwoColumnsRow
                          left={(
                            <b>
                              <FormattedMessage
                                id="field.dueDate"
                                defaultMessage="Due Date"
                              />
                              :
                            </b>
                          )}
                          right={
                            order.due_date ? (
                              <FormattedDateTime value={order.due_date} />
                            ) : (
                              <FormattedMessage
                                id="notAvailable"
                                defaultMessage="N/A"
                              />
                            )
                          }
                        />
                      </Card.Body>
                    </Card>
                  )}
                </Col>
                <Col md={12}>
                  <CommentsContainer
                    bgInverse
                    resourceTableName={COMMENT_RELATED_TABLE_NAMES.PREP_TASK_RECORD}
                    resourceUUID={extractUuid(prepTaskRecord.uri)}
                    resourceType={COMMENT_RESOURCE_TYPES.PREP_TASK}
                  />
                </Col>
              </Row>
            </ModalBody>
          </form>
        )}
      />
    </Modal>
  );
};

PrepTaskRecordModal.defaultProps = {
  isSubmitting: false,
  isLoading: false,
  orderOwner: {
    name: '',
  },
  prepTaskRecord: null,
};

PrepTaskRecordModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  prepTaskRecord: prepTaskRecordType,
  prepTask: prepTaskType.isRequired,
  order: orderType.isRequired,
  orderOwner: PropTypes.shape({
    name: PropTypes.string,
  }),
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  isUpdateAllowed: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  fields: PropTypes.shape({
    status: PropTypes.shape({}).isRequired,
  }).isRequired,
  initialFormValues: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

export default PrepTaskRecordModal;

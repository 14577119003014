import { createSelector } from 'reselect';
import { getPredicate } from 'rapidfab/selectors/helpers/base';
import { getShipments } from 'rapidfab/selectors/shipment';
import _filter from 'lodash/filter';

const getShipmentsForEntity = entityKey => createSelector(
  [getShipments, getPredicate],
  (shipments, entity) => {
    if (!entity) {
      return [];
    }
    return _filter(shipments, { [entityKey]: entity.uri });
  },
);

export const getShipmentsForOrder = getShipmentsForEntity('order');
export const getShipmentsForRun = getShipmentsForEntity('run');

import { faArrowDownShortWide, faEdit, faGear, faInfoCircle, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash/string';
import PropTypes from 'prop-types';
import ModalLightboxModel from 'rapidfab/components/ModelThumbnail';
import TruncatedTitle from 'rapidfab/components/TruncatedTitle';
import {
  MODEL_LIBRARY_TYPES,
  TEXT_COLOR_CONTRAST,
} from 'rapidfab/constants';
import { LINE_ITEM_STATUS_COLOR_CODE_MAPPING } from 'rapidfab/mappings';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import React from 'react';
import { Alert, Button, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'rapidfab/styles/componentStyles/build-plate-line-item-mode.scss';

const BuildPlateLineItemPiecesSummary = ({
  lineItem,
  workflow,
  model,
  ordersByUri,
  piecesCount,
  lineItemSummaryData,
  toggleIncompatiblePiecesModal,
  setSelectedLineItemPiecesCount,
}) => {
  if (!lineItem || !workflow) return null;

  const {
    uri: lineItemUri,
    type: lineItemType,
  } = lineItem;

  const isSpecimen = lineItemType === MODEL_LIBRARY_TYPES.SPECIMEN;
  const lineItemPieceCounts = lineItemSummaryData.find(({ line_item }) => line_item === lineItemUri);

  const {
    total_piece_quantity: allPieceCount,
    original_piece_quantity: originalPiecesCount,
  } = lineItemPieceCounts ?? {};

  const labelBgColor = LINE_ITEM_STATUS_COLOR_CODE_MAPPING[lineItem.status];
  const labelTextColor = TEXT_COLOR_CONTRAST[labelBgColor];

  const onChangePiecesCount = (pieceCount, lineItemUri) => {
    // Prevent leading zeros
    if (pieceCount.length <= 1 && pieceCount.startsWith('0')) return;
    // Only allow numeric input
    if (/^\d*$/.test(pieceCount)) {
      const numericPiecesCount = pieceCount === '' ? '' : Number.parseInt(pieceCount, 10);

      // Check if within bounds
      if (numericPiecesCount === '' || (numericPiecesCount >= 0 && numericPiecesCount <= originalPiecesCount)) {
        setSelectedLineItemPiecesCount({ [lineItemUri]: numericPiecesCount });
      }
    }
  };

  const renderPiecesCountInput = () => (
    <FormControl
      type="number"
      min={1}
      className="hide-default-input-arrows"
      style={{ minWidth: '120px' }}
      max={originalPiecesCount}
      value={piecesCount}
      onChange={(({ target }) => onChangePiecesCount(target.value, lineItemUri))}
      required
    />
  );

  const renderLineItemViewRightContent = () => {
    const countAvailable = originalPiecesCount - Number(piecesCount);
    return (
      <div className="buildPlate-lineItemRightContent">
        <div className="d-flex align-items-baseline">
          <p className="mb0 mr5 font-weight-400 text-white">
            Pieces: *
          </p>
          <div>
            <div className="mb5">{renderPiecesCountInput()}</div>
            <p className="buildPlate-lineItemRightContentPiecesCount">
              {countAvailable} {pluralWord('piece', countAvailable, 's', true)} available
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="buildPlate-lineItem" style={{ backgroundColor: '#25272E' }}>
        <div className="buildPlate-lineItemLeftContent">
          <div className="buildPlate-lineItemSnapshot">
            <ModalLightboxModel
              disableButtonClick
              snapshot={model?.snapshot_content}
              customBtnClass="buildPlate-lineItemSnapshotContent"
              customImgClass="buildPlate-lineItemSnapshotContentImage"
              piecesCount={allPieceCount}
            />
          </div>
          <div className="buildPlate-lineItemDetails">
            <div className="font-weight-500 text-white mb5">
              <TruncatedTitle
                title={lineItem.name}
                maxTextLength={60}
                inline
              />
            </div>
            {!isSpecimen && (
              <div className="d-flex align-items-center mb5 font-weight-500">
                <span
                  className="badge badge-sm"
                  style={{
                    backgroundColor: labelBgColor,
                    color: labelTextColor,
                  }}
                >
                  {capitalize(lineItem.status)}
                </span>
              </div>
            )}
            <div>
              <FontAwesomeIcon icon={faArrowDownShortWide} className="spacer-right" />
              <TruncatedTitle
                title={workflow.name}
                maxTextLength={80}
                inline
              />
            </div>
            {ordersByUri[lineItem.order]?.name && !isSpecimen && (
              <div>
                <FontAwesomeIcon icon={faEdit} className="spacer-right" />
                <TruncatedTitle
                  title={ordersByUri[lineItem.order].name}
                  maxTextLength={80}
                  inline
                />
              </div>
            )}
            {isSpecimen && (
              <div>
                <FontAwesomeIcon icon={faGear} className="spacer-right" />
                These are specimen(s)
                <OverlayTrigger
                  className="ml15"
                  placement="bottom"
                  overlay={(
                    <Tooltip>
                      <div>
                        <div>These Specimen(s) were added to the Run automatically based
                          on the Workflows of some other Pieces in the Run.
                        </div>
                        <div>Please inspect the Workflows of the non-specimen Pieces to
                          determine where they were requested.
                        </div>
                      </div>
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="spacer-left spacer-right" />
                </OverlayTrigger>
              </div>
            )}
          </div>
        </div>
        {renderLineItemViewRightContent()}
      </div>
      {(allPieceCount !== originalPiecesCount) && (
        <Alert variant="info" className="custom-info-alert">
          <FontAwesomeIcon icon={faInfoCircle} size="2x" className="me-2" />
          <div>
            <div className="flex-grow-1">
              Workflow Change for <strong>{allPieceCount - originalPiecesCount} pieces </strong>
              is not available due to the workflows mismatch.
            </div>
            <Button
              variant="link"
              className="buildPlate-lineItemDetailsPiecesAlertBtn"
              onClick={() => toggleIncompatiblePiecesModal(lineItemUri)}
            >
              <FontAwesomeIcon icon={faWrench} className="spacer-right" />
              See All Pieces
            </Button>
          </div>
        </Alert>
      )}
    </>
  );
};

BuildPlateLineItemPiecesSummary.propTypes = {
  lineItem: PropTypes.shape({
    uri: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    workflow: PropTypes.string,
    order: PropTypes.string,
  }).isRequired,
  workflow: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  model: PropTypes.shape({
    snapshot_content: PropTypes.string,
  }).isRequired,
  lineItemSummaryData: PropTypes.arrayOf(PropTypes.shape({
    line_item: PropTypes.string,
  })).isRequired,
  piecesCount: PropTypes.number.isRequired,
  setSelectedLineItemPiecesCount: PropTypes.func.isRequired,
  toggleIncompatiblePiecesModal: PropTypes.func.isRequired,
  ordersByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
};

export default BuildPlateLineItemPiecesSummary;

import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import { DebugModeBadge } from 'rapidfab/components/DebugMode/DebugModeComponents';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import OrderQuoteLineItemSummaryPreview from 'rapidfab/components/records/order/OrderQuoteLineItemSummaryPreview';
import { useOrderQuoteContext } from 'rapidfab/context/OrderQuoteContext';
import { useQuoteModal } from 'rapidfab/context/QuoteProcessStepsModalContext';
import usePrevious from 'rapidfab/hooks';
import * as Selectors from 'rapidfab/selectors';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';
import { calculateTotalQuote } from 'rapidfab/utils/orderQuoteUtils';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getShortUUID, extractUuid } from 'rapidfab/utils/uuidUtils';

import {
  Col, Row,
  ListGroupItem,
  Card,
  Button, FormControl,
  Form, InputGroup,
  OverlayTrigger, Tooltip, Form as BSForm,
} from 'react-bootstrap';
import _get from 'lodash/get';
import Actions from 'rapidfab/actions';
import {
  ORDER_UNLOCKED_STATUSES,
  ORDER_QUOTE_DISCOUNT_TYPES,
  ORDER_QUOTE_STATUS,
  ORDER_STATUS,
  USER_HIDE_INFO_TYPES, API_RESOURCES, ORDER_QUOTE_MODES, TEXT_COLOR_CONTRAST,
} from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';
import OrderQuoteExportButton from 'rapidfab/components/records/order/edit/OrderQuoteExportButton';
import {
  FormControlTextArea,
  FormControlSelect,
  CustomFormControlCost,
} from 'rapidfab/components/formTools';
import { LINE_ITEM_STATUS_COLOR_CODE_MAPPING, ORDER_QUOTE_DISCOUNT_TYPE_MAP } from 'rapidfab/mappings';
import { FormattedMessageMappingOption } from 'rapidfab/i18n';
import {
  getRouteUUIDResource,
  getBureauDefaultCurrency,
  getOrderQuoteDocumentsForOrder,
  getLatestOrderQuoteDocumentForOrder,
  getBureauSettings,
  getCurrentUserRoleMax,
  getModelsByUri, getWorkflowsByUri, getSortedLineItemsForOrder,
} from 'rapidfab/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faChevronDown, faChevronUp,
  faClockRotateLeft, faLongArrowLeft, faPlus,
  faQuestionCircle,
  faReceipt,
} from '@fortawesome/free-solid-svg-icons';
import Alert from 'rapidfab/utils/alert';
import OrderQuoteDownloadModal from './OrderQuoteDownloadModal';

const OrderQuote = () => {
  const order = useSelector(getRouteUUIDResource);
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const orderLineItems = useSelector(state => getSortedLineItemsForOrder(state, order));
  const workflowsByUri = useSelector(state => getWorkflowsByUri(state));
  const currency = useSelector(getBureauDefaultCurrency);
  const bureauCustomPrice = _get(order, 'estimates.total_price', null);
  const modelFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.MODEL].list.fetching
    || state.ui.nautilus[API_RESOURCES.MODEL].get.fetching);
  const orderDetailsFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ORDER_QUOTE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.ORDER_QUOTE].get.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM].list.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM].get.fetching ||
    state.ui.nautilus[API_RESOURCES.ORDER].get.fetching,
  );
  const orderQuoteDocuments = useSelector(state => getOrderQuoteDocumentsForOrder(state, order));
  const orderQuote = useSelector(state => getLatestOrderQuoteDocumentForOrder(state, order));
  const modelsByUri = useSelector(getModelsByUri);
  const [exportQuote, setExportQuote] = useState(false);
  const [confirmNewQuoteGenerate, setConfirmNewQuoteGenerate] = useState(false);
  const download = orderQuote && orderQuote.content;
  const postProcessorTypesByUri = useSelector(Selectors.getPostProcessorTypesByUri);
  const printerTypesByUri = useSelector(Selectors.getPrinterTypesByUri);
  const shippingsByUri = useSelector(Selectors.getShippingsByUri);
  const shippingCost = shippingsByUri[order.shipping.uri]?.cost;
  const processStepTypesByUri = {
    ...postProcessorTypesByUri,
    ...printerTypesByUri,
    ...shippingsByUri,
  };
  const workstepCostEstimates = useSelector(Selectors.getLineItemWorkstepCostEstimates);
  const lineItemQuotes = useSelector(Selectors.getLineItemQuotes);
  const bureauSettings = useSelector(getBureauSettings);
  const role = useSelector(getCurrentUserRoleMax);
  const submitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ORDER_QUOTE].post.fetching ||
    state.ui.nautilus[API_RESOURCES.ORDER].put.fetching,
  );

  const {
    calculateTotalPricePerPiece,
  } = useOrderQuoteContext();

  const {
    showQuoteModal,
  } = useQuoteModal();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleShowOrderModal = lineItem => {
    showQuoteModal({
      processStepTypesByUri,
      workstepCostEstimates,
      modelFetching,
      lineItemUri: lineItem.uri,
      calculateTotalPricePerPiece,
    });
  };

  const hideFinancial = role?.hide_info === USER_HIDE_INFO_TYPES.FINANCIAL;
  const notesPlaceholder = bureauSettings ? bureauSettings.order_quote_notes_placeholder : '';

  const isUnconfirmedOrder = () => {
    /**
     * Returns true if order isn't confirmed yet
     */

    const { status } = order;
    return ORDER_UNLOCKED_STATUSES.includes(status);
  };

  const getCurrentOrderQuoteMode = () => {
    if (!orderLineItems.length) {
      return ORDER_QUOTE_MODES.IDLE;
    }

    if (order.status === ORDER_STATUS.CANCELLED) {
      return ORDER_QUOTE_MODES.IDLE_ORDER_CANCELLED;
    }

    if (!order.quote_required && !isUnconfirmedOrder()) {
      // If order is confirmed and quote is not required
      return ORDER_QUOTE_MODES.IDLE_ORDER_CONFIRMED;
    }

    const { customer_po, discount_value, markup_value, approved } = orderQuote ?? {};

    if (orderQuote && order.quote_required && (customer_po === null && !discount_value && !markup_value && !approved)) {
      // If order quote is required but not generated yet
      return ORDER_QUOTE_MODES.REQUIRED_INITIAL;
    }

    if (
      order.quote_required && !approved
      && ((discount_value || markup_value)
        || (customer_po !== null && customer_po === ''))
    ) {
      // If order quote is required and generated but not accepted yet
      return ORDER_QUOTE_MODES.REQUIRED_GENERATED;
    }

    if (order.quote_required && orderQuote && (customer_po && approved)) {
      // If order quote is required and generated and accepted
      return ORDER_QUOTE_MODES.REQUIRED_ACCEPTED;
    }

    return ORDER_QUOTE_MODES.IDLE;
  };

  const [quoteState, setQuoteState] = useState({
    mode: ORDER_QUOTE_MODES.IDLE,
    customerPo: '',
    discountType: ORDER_QUOTE_DISCOUNT_TYPES.AMOUNT,
    discount: '',
    notes: '',
    markup: null,
    orderQuoteApproved: false,
    showOrderQuoteDownload: false,
    /**
     * loadingReport means that offer object has been created, but
     * not generated yet (in background process)
     */
    loadingReport: false,
    /**
     * isNewOfferCreated means that offer has
     * been created in current component lifecycle
     */
    isNewOfferCreated: false,
  });

  const dispatch = useDispatch();
  const previousOrderQuote = usePrevious(orderQuote);

  const onInitialize = async () => {
    if (order?.quote_required) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER_QUOTE].list({ order: order.uri }));
    }

    // If the order is confirmed and quote is not required, set the mode to idle order confirmed
    if (!order.quote_required && !isUnconfirmedOrder()) {
      setQuoteState({
        ...quoteState,
        mode: ORDER_QUOTE_MODES.IDLE_ORDER_CONFIRMED,
      });
    }
  };

  const {
    total,
    subtotal,
    markupAmount,
    discountAmount,
    totalsByLineItemUri,
  } = useMemo(() => calculateTotalQuote(
    lineItemQuotes,
    shippingCost,
    quoteState.markup ?? 0,
    quoteState.discount,
    quoteState.discountType,
    bureauCustomPrice,
  ), [
    lineItemQuotes,
    shippingCost,
    quoteState.markup,
    quoteState.discount,
    quoteState.discountType,
    bureauCustomPrice,
  ]);

  const {
    loadingReport,
    customerPo,
    orderQuoteApproved,
    discountType,
    discount,
    notes,
    markup,
    showOrderQuoteDownload,
  } = quoteState;

  useEffect(() => {
    onInitialize();
  }, [order?.quote_required]);

  useEffect(() => {
    if (orderQuote) {
      setQuoteState(current => ({
        ...current,
        customerPo: orderQuote.customer_po || '',
        discountType: orderQuote.discount_type || ORDER_QUOTE_DISCOUNT_TYPES.AMOUNT,
        discount: orderQuote.discount_value || '',
        orderQuoteApproved: orderQuote.approved || false,
        notes: orderQuote.notes || '',
        markup: orderQuote.markup_value || null,
      }));
    }
  }, [orderQuote]);

  useEffect(() => {
    setQuoteState(current => ({
      ...current,
      mode: getCurrentOrderQuoteMode(),
    }));
  }, [orderQuote, orderLineItems.length, order.status, order.quote_required]);

  useEffect(() => {
    if (
      exportQuote &&
      orderQuote &&
      previousOrderQuote &&
      orderQuote.status !== previousOrderQuote.status && orderQuote.status === ORDER_QUOTE_STATUS.COMPLETE
    ) {
      setQuoteState(current => ({
        ...current,
        loadingReport: false,
        isNewOfferCreated: true,
      }));

      if (download) {
        window.location = download;
        setExportQuote(false);
      }
    }
  }, [orderQuote, previousOrderQuote, download, exportQuote]);

  const onInputChange = event => {
    const { type, checked, name } = event.target;
    let { value } = event.target;

    value = type === 'checkbox' ? checked : value;

    setQuoteState(current => ({
      ...current,
      [name]: value,
    }));
  };

  const validateQuoteField = (field, fieldName, fieldType, maxAmount, minAmount) => {
    const invalidMaxPercent = field >= maxAmount;
    const invalidMinPercent = field <= minAmount;

    const errors = {
      invalidMax: {
        id: 'toaster.error.quotePercentageMax',
        message: 'The {fieldName} field exceeds or equals the max {fieldType} of {maxAmount}.',
      },
      invalidMin: {
        id: 'toaster.error.quotePercentageMin',
        message: 'The {fieldName} field is under or equals the min {fieldType} of {minAmount}.',
      },
    };

    const showError = error => {
      Alert.error(
        <FormattedMessage
          id={error.id}
          defaultMessage={error.message}
          values={{ fieldName, fieldType, minAmount, maxAmount }}
        />,
      );
    };

    if (invalidMaxPercent) {
      showError(errors.invalidMax);
      return true;
    }

    if (invalidMinPercent) {
      showError(errors.invalidMin);
      return true;
    }

    return false;
  };

  const updateOrder = async (updatedFields, toggleOrderQuote, isNewQuote) => {
    const discountType = updatedFields.discount_type === ORDER_QUOTE_DISCOUNT_TYPES.PERCENTAGE ? 'percentage' : 'amount';
    const discountMaxAmount = discountType === 'amount' ? subtotal.toFixed(2) : 100;

    const discountValidationResult = !_isEmpty(discount) && validateQuoteField(updatedFields.discount_value, 'discount', discountType, discountMaxAmount, 0);
    const markupValidationResult = !_isEmpty(markup) && !discountValidationResult && validateQuoteField(updatedFields.markup_value, 'markup', 'percentage', 100, 0);

    if (discountValidationResult || markupValidationResult) return null;

    /**
   * Updates order object with data from `updatedFields` argument
   */
    try {
      if (toggleOrderQuote) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].put(order.uuid, updatedFields));
        if (!updatedFields.quote_required) {
          setQuoteState({
            ...quoteState,
            mode: ORDER_QUOTE_MODES.IDLE,
          });
        }
      } else {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER_QUOTE].post(updatedFields));
      }

      if (!updatedFields.customer_po && !toggleOrderQuote) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].put(order.uuid, { customer_po: isNewQuote ? null : '' }));
      }

      return await onInitialize();
    } catch (error) {
      return console.error('Error while updating order', error);
    }
  };

  const generateQuotePayload = quoteState => {
    // Destructure and rename state variables to match payload keys directly
    const {
      customerPo: customer_po,
      orderQuoteApproved: approved,
      notes,
      markup,
      discountType: discount_type,
      discount,
    } = quoteState;

    // Construct the payload using nullish coalescing and optional chaining
    const payload = {
      customer_po: customer_po || null,
      discount_type,
      discount_value: discount ? Number.parseFloat(discount) : 0,
      approved,
      notes,
      // Ensures that if markup is falsy (e.g., "", null, undefined), it defaults to 0
      markup_value: Number.parseFloat(markup) || 0,
      order: order.uri,
    };

    if (_isEmpty(payload.customer_po)) {
      delete payload.customer_po;
    }

    return payload;
  };

  const onFormSubmit = async (event, isApproved) => {
    // Prevent default form submission behavior and stop propagation
    event.preventDefault();
    event.stopPropagation();

    const payload = generateQuotePayload(quoteState);

    if (isApproved) {
      payload.approved = true;
    }

    try {
      await updateOrder(payload);

      if (isApproved) {
        setQuoteState(current => ({
          ...current,
          mode: ORDER_QUOTE_MODES.REQUIRED_ACCEPTED,
        }));
      }
    } catch (error) {
      console.error('Error while updating order:', error);
      // Optionally, handle the error in the UI, e.g., by setting an error state or showing a notification
    }
  };

  const getNewOrderQuoteURI = () => {
    /**
     * Returns boolean if order is generated and ready to download
     * Don't mess with `isNewOfferCreated()` method.
     * This method also checks isNewOfferCreated state value which can be true
     * only if object is generated in current component lifecycle
     * (after refreshing of app it should be new component lifecycle)
     */

    const { loadingReport, isNewOfferCreated } = quoteState;

    if (!isNewOfferCreated || loadingReport) {
      return '';
    }

    return download;
  };

  const getMaxDiscount = () => (quoteState.discountType === ORDER_QUOTE_DISCOUNT_TYPES.PERCENTAGE ? 100 : null);

  const checkIsVisibleLatestOrderQuoteButton = () => {
    /**
     * Returns boolean if last generated order is available to download
     */

    const { loadingReport } = quoteState;
    return !loadingReport && !!orderQuoteDocuments.length;
  };

  const createOrderQuote = async shouldExportQuote => {
    /**
     * Generates request for new order quote object
     * and then performs list request to add this object to store
     */

    setQuoteState(current => ({
      ...current,
      loadingReport: true,
    }));

    if (shouldExportQuote) {
      setExportQuote(true);
    }

    const payload = generateQuotePayload(quoteState);

    try {
      const orderQuoteResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER_QUOTE].post(payload));

      const { location } = orderQuoteResponse.headers;
      const uuid = extractUuid(location);
      await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER_QUOTE].get(uuid, true));
    } catch (error) {
      console.error('Error while creating order quote', error);
      setQuoteState(current => ({
        ...current,
        loadingReport: false,
      }));
    }
  };

  const toggleQuoteFeature = async () => {
    /**
     * Used only for swapping `quote_required` field with opposite
     */

    const isConfirmedOrder = !isUnconfirmedOrder();
    if (isConfirmedOrder) {
      return false;
    }

    const updatedFields = {
      quote_required: !order.quote_required,
    };

    try {
      await updateOrder(updatedFields, true);
    } catch (error) {
      console.error('Error while updating order:', error);
      return false;
    }

    return true;
  };

  const generateNewQuote = async () => {
    const payload = generateQuotePayload({
      discountType: ORDER_QUOTE_DISCOUNT_TYPES.AMOUNT,
      discount: '',
      markup: null,
    });

    await updateOrder(payload, false, true);
    setQuoteState(current => ({
      ...current,
      mode: ORDER_QUOTE_MODES.REQUIRED_INITIAL,
    }));
    if (confirmNewQuoteGenerate) {
      setConfirmNewQuoteGenerate(false);
    }
  };

  const approveQuote = async () => {
    setQuoteState(current => ({
      ...current,
      mode: ORDER_QUOTE_MODES.REQUIRED_CONFIRM,
    }));
  };

  const backToGeneratedMode = () => {
    setQuoteState(current => ({
      ...current,
      mode: ORDER_QUOTE_MODES.REQUIRED_GENERATED,
    }));
  };

  const toggleOrderQuoteDownloadModal = () => {
    const { showOrderQuoteDownload } = quoteState;

    setQuoteState(current => ({
      ...current,
      showOrderQuoteDownload: !showOrderQuoteDownload,
    }));
  };

  const orderQuoteURI = getNewOrderQuoteURI();
  const isVisibleLatestOrderQuoteButton = checkIsVisibleLatestOrderQuoteButton();
  const isConfirmedOrder = !isUnconfirmedOrder();

  const allLineItemsHasWorkflow = orderLineItems.every(lineItem => lineItem.workflow);

  const cardHeaderContentClassNames = classNames({
    'd-flex justify-content-between': true,
    'align-items-center': !isCollapsed,
    'custom-darken-order-quote--card-header-line': (order.quote_required && !orderDetailsFetching) && !isCollapsed,
  });

  const renderDiscountInfo = () => {
    if (quoteState.discountType === ORDER_QUOTE_DISCOUNT_TYPES.PERCENTAGE) {
      return (
        <span className={`${bureauCustomPrice ? 'spacer-right' : ''}`}>
          {bureauCustomPrice ? `(${quoteState.discount || 0}%) -` : `(${quoteState.discount || 0}%) N/A`}
        </span>
      );
    }
    return null;
  };

  const renderExportButtons = () => (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        {!hideFinancial && (
          <OrderQuoteExportButton
            asIcon
            download={orderQuoteURI}
            loadingReport={loadingReport}
            onExport={() => createOrderQuote(true)}
            disabled={isConfirmedOrder}
            pullToLeft={false}
          />
        )}

        {isVisibleLatestOrderQuoteButton && (
          <>
            <div className="custom-darken-last-version-btn-wrapper">
              <Button
                variant="outline-info"
                onClick={toggleOrderQuoteDownloadModal}
                className="custom-darken-last-version-btn spacer-left"
              >
                <FontAwesomeIcon icon={faClockRotateLeft} />
              </Button>
              {orderQuoteDocuments?.length > 0 && (
                <span className="custom-darken-last-version-badge">{orderQuoteDocuments.length}</span>
              )}
            </div>
            <OrderQuoteDownloadModal
              close={toggleOrderQuoteDownloadModal}
              orderQuoteDocuments={orderQuoteDocuments}
              show={showOrderQuoteDownload}
            />
          </>
        )}

        {quoteState.mode === ORDER_QUOTE_MODES.REQUIRED_ACCEPTED && (
          <span className="custom-darken-switch-green ml15 font-weight-500">
            <FontAwesomeIcon icon={faCheck} className="spacer-right" />
            Approved
          </span>
        )}
      </div>
    </div>

  );

  const renderLineItemsSummaryPreview = () => orderLineItems.map(lineItem => {
    const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
    const model = modelsByUri[lineItem[workflowTypeKey]?.model];
    const labelBgColor = LINE_ITEM_STATUS_COLOR_CODE_MAPPING[lineItem?.status];
    const labelTextColor = TEXT_COLOR_CONTRAST[labelBgColor];

    return (
      <OrderQuoteLineItemSummaryPreview
        key={lineItem.uri}
        lineItem={lineItem}
        modelFetching={modelFetching}
        model={model}
        workflowsByUri={workflowsByUri}
        labelBgColor={labelBgColor}
        labelTextColor={labelTextColor}
        handleShowOrderModal={handleShowOrderModal}
        renderTotalProcessData={totalsByLineItemUri}
      />
    );
  });

  const renderSwitchToggle = () => {
    if (isConfirmedOrder || isCollapsed) {
      return null;
    }

    if (submitting) {
      return <Loading className="spacer-right" />;
    }

    return (
      <BSForm.Check
        type="switch"
        id="custom-switch"
        checked={order.quote_required}
        onChange={toggleQuoteFeature}
        disabled={isConfirmedOrder || !allLineItemsHasWorkflow}
        className="ml15 mr0 custom-darken-switch"
      />
    );
  };

  const renderCardHeaderContent = (
    isCollapsed,
    isConfirmedOrder,
    order,
    toggleQuoteFeature,
    allLineItemsHasWorkflow,
  ) => (
    <div className={cardHeaderContentClassNames}>
      <div className="d-flex align-items-center custom-darken-order-quote--card-header-content">
        <FontAwesomeIcon icon={faReceipt} className="spacer-right" />
        <p className="mb0">Order Quote</p>
      </div>
      <div className="d-flex align-items-center">
        {renderSwitchToggle()}
        {!isCollapsed && !isConfirmedOrder && (
          <p
            className={`mb0 font-weight-400 font-size-14 ${order.quote_required ? 'custom-darken-switch-green' : 'text-white'}`}
          >
            Requires Approved Quote
          </p>
        )}

        {order.quote_required && (
          <FontAwesomeIcon
            icon={!isCollapsed ? faChevronUp : faChevronDown}
            className="ml15 font-weight-500 font-size-22 cursor-pointer text-white"
            onClick={() => setIsCollapsed(previous => !previous)}
          />
        )}
        {!allLineItemsHasWorkflow && (
          <Col xs={12} sm={1}>
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip>
                  <FormattedMessage
                    id="orderQuoteToolTip"
                    defaultMessage="Please assign workflows for the following Line Items so that you can create an Order Quote: {name} - ({id})"
                    values={{ name: order.name, id: getShortUUID(order.uuid) }}
                  />
                </Tooltip>
              )}
            >
              <FontAwesomeIcon className="spacer-left spacer-right" icon={faQuestionCircle} />
            </OverlayTrigger>
          </Col>
        )}
      </div>
    </div>
  );

  const renderOrderQuoteHeader = () => (
    <Card.Header className="pd-exp custom-darken-order-quote--card-header">
      {renderCardHeaderContent(isCollapsed, isConfirmedOrder, order, toggleQuoteFeature, allLineItemsHasWorkflow)}
    </Card.Header>
  );

  const renderLineItemsPreviewContents = () => (
    <div>
      <div className="mt20">
        {renderLineItemsSummaryPreview()}
      </div>

      <div className="mt20 mb30">
        <p className="font-weight-200 font-size-20 text-white">Summary</p>
        {(!!discountAmount ||
          (!bureauCustomPrice
            && quoteState.discountType === ORDER_QUOTE_DISCOUNT_TYPES.PERCENTAGE
            && quoteState.discount))
          && (
            <div className="custom-darken-order-quote-summary">
              <div>Discount</div>
              <div className="d-flex align-items-center">
                {renderDiscountInfo()}
                {bureauCustomPrice && (
                  <div>
                    <FormattedLocalizedCost value={discountAmount || 0} />
                  </div>
                )}
              </div>
            </div>
          )}
        <div className="custom-darken-order-quote-summary">
          <div>Shipping</div>
          <div>
            <FormattedLocalizedCost value={shippingCost || 0} />
          </div>
        </div>
        <div className="custom-darken-order-quote-summary-divider" />
        <div
          className={`d-flex justify-content-between ${!discountAmount ? 'align-items-center' : 'align-items-start'}`}
        >
          <p className="font-weight-500 mb0 font-size-20 text-white">Total</p>
          <div>
            <p className={`font-weight-500 mb0 font-size-20 text-white ${discountAmount || markupAmount ? 'crossed-line' : ''}`}>
              <FormattedLocalizedCost value={subtotal || 0} />
            </p>
            {(!!discountAmount || !!markupAmount) && (
              <p className="font-weight-500 text-white mb0 font-size-20">
                <FormattedLocalizedCost value={total || 0} />
              </p>
            )}
          </div>
        </div>
        {quoteState.markup && (
          <div className="custom-darken-order-quote-summary">
            <p className="mb0"><span className="font-weight-400">Internal Note: </span>
              This includes <span className="font-weight-500 text-white">{quoteState.markup}%</span> markup according to
              <span className="ml5 font-weight-500 text-white">
                <FormattedLocalizedCost value={markupAmount || 0} />
              </span>
            </p>
          </div>
        )}

        {isDebugModeEnabled &&
          (quoteState.discountType === ORDER_QUOTE_DISCOUNT_TYPES.PERCENTAGE && !!discountAmount)
          && (
            <div className="custom-darken-order-quote-summary-discount">
              <div className="d-flex align-items-center justify-content-between mb5">
                <p className="mb0 font-size-16 text-white font-weight-400">Discount Explanation </p>
                <DebugModeBadge />
              </div>

              <p className="mb0">
                <span className="font-weight-500 text-white">Discount Estimates Value: </span>
                order.estimates.total_price = {order.estimates.total_price}
              </p>
              <p className="mb0">
                <span className="font-weight-500 text-white">Discount Amount: </span>
                {quoteState.discount}%
              </p>
              <p className="mb0">
                <span className="font-weight-500 text-white">Formula: </span>
                (Discount Estimates Value * Discount Amount) / 100;
              </p>

              <p className="mb0">
                <span className="font-weight-500 text-white">Result: </span>
                <FormattedLocalizedCost value={discountAmount || 0} />
              </p>
            </div>
          )}
      </div>
    </div>
  );

  const renderOrderQuoteBody = () => {
    const { mode } = quoteState;

    if (isCollapsed) {
      return null;
    }

    if (mode === ORDER_QUOTE_MODES.IDLE) {
      return (
        <Card.Body className="custom-darken-order-quote--initial-body">
          {submitting || orderDetailsFetching ?
            <Loading /> : (
              <p>
                Please Enable Order Quote to generate the quote for this order and view the price breakdown.
              </p>
            )}
        </Card.Body>
      );
    }

    if (mode === ORDER_QUOTE_MODES.IDLE_ORDER_CANCELLED) {
      return (
        <Card.Body className="custom-darken-order-quote--initial-body">
          {submitting ?
            <Loading /> : (
              <p>
                <FormattedMessage
                  id="orderQuote.cancelledOrder"
                  defaultMessage="An order quote cannot be set because the order has been cancelled."
                />
              </p>
            )}
        </Card.Body>
      );
    }

    if (mode === ORDER_QUOTE_MODES.IDLE_ORDER_CONFIRMED) {
      return (
        <Card.Body className="custom-darken-order-quote--initial-body">
          {submitting ?
            <Loading /> : (
              <p>
                <FormattedMessage
                  id="orderQuote.confirmedOrder"
                  defaultMessage="An order quote cannot be set because the order has been confirmed."
                />
              </p>
            )}
        </Card.Body>
      );
    }

    if (mode === ORDER_QUOTE_MODES.REQUIRED_INITIAL) {
      return (
        <Card.Body className="custom-darken-order-quote--required-body">
          <Card
            className="custom-darken-modal--card custom-darken-order-quote-card"
          >
            <Card.Header className="pd-exp custom-darken-modal--card-header">Create Quote</Card.Header>
            <Card.Body className="custom-darken-modal--card-body custom-darken-order-quote--body-content">
              {!hideFinancial && (
                <>
                  <ListGroupItem>
                    <Row>
                      <Col xs={4}>
                        <FormattedMessage
                          id="field.quote_discount_value"
                          defaultMessage="Discount"
                        />:
                      </Col>
                      <Col xs={4}>
                        <FormControlSelect
                          id="discountType"
                          name="discountType"
                          value={discountType}
                          onChange={onInputChange}
                          disabled={isConfirmedOrder}
                        >
                          {Object.keys(ORDER_QUOTE_DISCOUNT_TYPE_MAP).map(type => (
                            <FormattedMessageMappingOption
                              key={type}
                              mapping={ORDER_QUOTE_DISCOUNT_TYPE_MAP}
                              value={type}
                            />
                          ))}
                        </FormControlSelect>
                      </Col>
                      <Col xs={4}>
                        {discountType === ORDER_QUOTE_DISCOUNT_TYPES.PERCENTAGE && (
                          <FormControl
                            type="number"
                            min="0"
                            className="hide-default-input-arrows"
                            max={getMaxDiscount()}
                            name="discount"
                            value={discount}
                            onChange={onInputChange}
                            disabled={isConfirmedOrder}
                          />
                        )}
                        {discountType === ORDER_QUOTE_DISCOUNT_TYPES.AMOUNT && (
                          <CustomFormControlCost
                            type="number"
                            min="0"
                            hideInputArrows
                            max={getMaxDiscount()}
                            name="discount"
                            value={discount}
                            onChange={onInputChange}
                            disabled={isConfirmedOrder}
                            currency={currency}
                          />
                        )}
                      </Col>
                    </Row>
                  </ListGroupItem>

                  <ListGroupItem>
                    <Row>
                      <Col xs={4}>
                        <FormattedMessage
                          id="field.markup"
                          defaultMessage="Mark-up"
                        />:
                      </Col>
                      <Col xs={8}>
                        <InputGroup>
                          <Form.Control
                            name="markup"
                            type="number"
                            className="hide-default-input-arrows"
                            as="input"
                            max="100"
                            onChange={onInputChange}
                            disabled={isConfirmedOrder}
                            value={markup}
                          />
                          <InputGroup.Text id="markup-addon">%</InputGroup.Text>
                        </InputGroup>
                      </Col>
                    </Row>
                  </ListGroupItem>

                  <div className="d-flex align-items-center justify-content-end mb15">
                    <Button
                      size="sm"
                      className="mt15 pull-right"
                      variant="outline-info"
                      onClick={onFormSubmit}
                    >
                      {submitting ?
                        <Loading className="spacer-right" inline /> :
                        (<FontAwesomeIcon icon={faCheck} className="spacer-right" />)}
                      <FormattedMessage id="button.confirm" defaultMessage="Confirm" />
                    </Button>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
          {renderLineItemsPreviewContents()}
        </Card.Body>
      );
    }

    if (mode === ORDER_QUOTE_MODES.REQUIRED_GENERATED || mode === ORDER_QUOTE_MODES.REQUIRED_ACCEPTED) {
      return (
        <div className="custom-darken-modal--quote-generated-body mb30">
          {renderExportButtons()}
          {renderLineItemsPreviewContents()}
          <div className="d-flex align-items-center justify-content-end">
            <Button onClick={() => setConfirmNewQuoteGenerate(true)} size="sm" variant="outline-light" className="spacer-right">
              <FontAwesomeIcon icon={faPlus} className="spacer-right" />
              <span className="font-weight-400">Generate New Quote</span>
            </Button>
            {mode !== ORDER_QUOTE_MODES.REQUIRED_ACCEPTED && (
              <Button
                variant="outline-success"
                className="btn-success-dark"
                size="sm"
                onClick={approveQuote}
              >
                <FontAwesomeIcon icon={faCheck} className="spacer-right" />
                <span className="font-weight-400">Approve Quote</span>
              </Button>
            )}
          </div>
        </div>
      );
    }

    if (mode === ORDER_QUOTE_MODES.REQUIRED_CONFIRM) {
      return (
        <Card.Body className="custom-darken-order-quote--required-body">
          <Card
            className="custom-darken-modal--card custom-darken-order-quote-card mb30"
          >
            <Card.Header className="pd-exp custom-darken-modal--card-header">Confirm Order Quote</Card.Header>
            <Card.Body className="custom-darken-modal--card-body custom-darken-order-quote--body-content">
              <Form onSubmit={event => onFormSubmit(event, true)}>
                <ListGroupItem>
                  <Row>
                    <Col xs={4}>
                      <FormattedMessage
                        id="field.customer_po"
                        defaultMessage="Customer PO"
                      />:*
                    </Col>
                    <Col xs={8}>
                      <FormControl
                        required
                        name="customerPo"
                        value={customerPo}
                        onChange={onInputChange}
                        disabled={isConfirmedOrder}
                      />
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row>
                    <Col xs={4}>
                      <FormattedMessage
                        id="field.notes"
                        defaultMessage="Notes"
                      />:
                    </Col>
                    <Col xs={8}>
                      <FormControlTextArea
                        required={orderQuoteApproved}
                        name="notes"
                        value={notes}
                        placeholder={notesPlaceholder}
                        onChange={onInputChange}
                        disabled={isConfirmedOrder}
                      />
                    </Col>
                  </Row>
                </ListGroupItem>

                {(!isConfirmedOrder && !hideFinancial) && (
                  <div className="d-flex align-items-center justify-content-end mt15">
                    <Button onClick={backToGeneratedMode} size="sm" variant="outline-light" className="spacer-right">
                      <FontAwesomeIcon icon={faLongArrowLeft} className="spacer-right" />
                      <FormattedMessage id="back" defaultMessage="Back" />
                    </Button>
                    <Button
                      variant="outline-success"
                      className="btn-success-dark"
                      size="sm"
                      disabled={submitting}
                      type="submit"
                    >
                      {submitting
                        ? <Loading inline className="spacer-right" />
                        : <FontAwesomeIcon icon={faCheck} className="spacer-right" />}
                      <FormattedMessage id="button.confirm" defaultMessage="Confirm" />
                    </Button>
                  </div>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Card.Body>
      );
    }

    return null;
  };

  return (
    <Card className="mb15 custom-darken-order-quote--card order-quote-outline">
      {renderOrderQuoteHeader()}
      {renderOrderQuoteBody()}

      {confirmNewQuoteGenerate && (
        <ConfirmationModal
          handleCancel={() => setConfirmNewQuoteGenerate(false)}
          handleConfirm={generateNewQuote}
          message="The current quote will be lost. Are you sure you want to generate a new quote?"
          isDarken
          confirmButtonContent={submitting ? <Loading inline /> : <FormattedMessage id="button.confirm" defaultMessage="Confirm" />}
        />
      )}
    </Card>
  );
};

export default OrderQuote;

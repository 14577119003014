import _isEmpty from 'lodash/isEmpty';
import Loading from 'rapidfab/components/Loading';
import { FormattedMessage } from 'rapidfab/i18n';
import { MLINE_SLOT_TYPES_MAP, PERMANENT_CONTAINER_TYPES_MAP } from 'rapidfab/mappings';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import React from 'react';
import { getShortUUID, extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { PERMANENT_CONTAINER_ACTION_TYPES, ROUTES } from 'rapidfab/constants';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import { Link } from 'react-router-dom';

const PermanentContainerMetadataColumn = ({
  rowData,
  locations,
  subLocations,
  materialLotDetailsMap,
  materialLotsFetching,
  containerActionContainers,
  containerActionPrinters,
  containerActionBatches,
  postProcessors,
}) => {
  const { action_type: actionType } = rowData;

  switch (actionType) {
    case (PERMANENT_CONTAINER_ACTION_TYPES.RELOCATE): {
      const location = _find(locations, { uri: rowData?.metadata.destination_location });
      const subLocation = _find(subLocations, { uri: rowData?.metadata.destination_sub_location });

      return (
        <>
          <p>
            Location:{' '}
            <i>
              <Link
                to={getRouteURI(ROUTES.LOCATIONS, null, { uuid: extractUuid(location?.uri) }, true)}
              >
                {location?.name} ({getShortUUID(location?.uri)})
              </Link>
            </i>
          </p>
          {' '}
          <p>
            Sub Location:{' '}
            <i>{subLocation?.name} ({getShortUUID(subLocation?.uri)})</i>
          </p>
        </>
      );
    }
    case (PERMANENT_CONTAINER_ACTION_TYPES.UPDATE_MODULE_TARE): {
      const notes = rowData?.notes;

      if (notes) {
        return (
          <div>
            <p className="mb5">
              Notes:
            </p>
            <i>{notes}</i>
          </div>
        );
      }
      return null;
    }
    case PERMANENT_CONTAINER_ACTION_TYPES.TOP_OFF:
    case PERMANENT_CONTAINER_ACTION_TYPES.LOAD_MATERIAL:
      if (!rowData?.materials?.material_names || !rowData?.materials?.material_lots) {
        return null;
      }
      if (materialLotsFetching) {
        return <Loading inline />;
      }

      return (
        <>
          <div>
            <p className="mb5">{pluralWord('Material', rowData.materials.material_names.length)}:</p>
            <i>
              {rowData.materials.material_names.map((materialName, index) => {
                const lotUri = rowData.materials.material_lots[index];
                const lotDetails = materialLotDetailsMap.get(lotUri);
                const units = lotDetails?.units || '';
                return (
                  <p key={materialName}>
                    {materialName} ({units})
                  </p>
                );
              })}
              {!_isEmpty(rowData?.source_batch) && (
                <p>Batch:{' '}
                  <a href={getRouteURI(ROUTES.MATERIAL_BATCH,
                    { uuid: extractUuid(rowData.source_batch) })}
                  >
                    {getShortUUID(rowData.source_batch)}
                  </a>
                </p>
              )}
            </i>
          </div>
        </>
      );
    case (PERMANENT_CONTAINER_ACTION_TYPES.UNLOAD_MATERIAL): {
      const notes = rowData?.notes;

      if (notes) {
        return (
          <div>
            <p className="mb5">
              Notes:
            </p>
            <i>{notes}</i>
          </div>
        );
      }

      if (rowData.metadata?.split_off_batch) {
        return (
          <span>Transferred material to split off batch{' '}
            <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: rowData.metadata.split_off_batch })}>
              {getShortUUID(rowData.metadata.split_off_batch)}
            </a>
          </span>
        );
      }
      return null;
    }
    case (PERMANENT_CONTAINER_ACTION_TYPES.SIEVE): {
      const notes = rowData?.notes;
      const targetPrinterUri = rowData.metadata?.target_printer;
      const targetContainerUri = rowData.metadata?.target_container;
      const printer = _find(containerActionPrinters, { uri: targetPrinterUri });
      const container = _find(containerActionContainers, { uri: targetContainerUri });
      const isContainerDisposable = container?.disposable;

      const remainingQuantity = rowData.metadata?.remaining_quantity;
      const displayedBatch = rowData?.source_batch;
      const batch = displayedBatch
        && containerActionBatches.find(actionBatch => actionBatch.uri === displayedBatch);
      const units = batch?.units || '';

      if (notes) {
        return (
          <div>
            <p className="mb5">
              Notes:
            </p>
            <p><i>{notes}</i></p>

            {!_isEmpty(printer) && (
              <p>Destination Printer:{' '}
                <a href={getRouteURI(ROUTES.PRINTER_EDIT,
                  { uuid: extractUuid(printer.uri) })}
                >
                  {printer.name}
                </a>
              </p>
            )}

            {!_isEmpty(container) && (
              <p>Destination Container:{' '}
                {!isContainerDisposable ? (
                  <a href={getRouteURI(ROUTES.PERMANENT_CONTAINER,
                    { uuid: extractUuid(container.uri) })}
                  >
                    {container.name}
                  </a>
                ) : (
                  <span>{getShortUUID(container.uri)}</span>
                )}
              </p>
            )}

            {remainingQuantity && units && (
              <p>Remaining Quantity:{' '}
                <i>{remainingQuantity} {units}</i>
              </p>
            )}

          </div>
        );
      }

      return null;
    }
    case (PERMANENT_CONTAINER_ACTION_TYPES.UNDOCK_MODULE):
    case (PERMANENT_CONTAINER_ACTION_TYPES.DOCK_MODULE): {
      const notes = rowData?.notes;
      const printerUri = rowData.metadata?.printer;
      const postProcessorUri = rowData.metadata?.post_processor;
      const lpsUri = rowData.metadata?.laser_processing_system;
      const mhsUri = rowData.metadata?.material_handling_system;
      const slotPosition = rowData.metadata?.slot_position;
      const printer = _find(containerActionPrinters, { uri: printerUri });
      const postProcessor = _find(postProcessors, { uri: postProcessorUri });

      const allModuleTypes = {
        ...PERMANENT_CONTAINER_TYPES_MAP,
        ...MLINE_SLOT_TYPES_MAP,
      };
      return (
        <div>
          {notes && (
            <>
              <p className="mb5">
                Notes:
              </p>
              <p><i>{notes}</i></p>
            </>
          )}

          {!_isEmpty(printer) && (
            <p>Printer:{' '}
              <a href={getRouteURI(ROUTES.PRINTER_EDIT,
                { uuid: extractUuid(printer.uri) })}
              >
                {printer.name}
              </a>
            </p>
          )}

          {!_isEmpty(postProcessor) && (
            <p>Post Processor:{' '}
              <a href={getRouteURI(ROUTES.POST_PROCESSOR_EDIT,
                { uuid: extractUuid(postProcessor.uri) })}
              >
                {postProcessor.name}
              </a>
            </p>
          )}

          {lpsUri && (
            <p>LPS System:{' '}
              <p><i>{getShortUUID(lpsUri)}</i></p>
            </p>
          )}

          {mhsUri && (
            <p>MHS System:{' '}
              <p><i>{getShortUUID(mhsUri)}</i></p>
            </p>
          )}

          {slotPosition && (
            <p>Slot Position:{' '}
              <p>
                <i>
                  <FormattedMessage {...allModuleTypes[slotPosition]} />
                </i>
              </p>
            </p>
          )}

        </div>
      );
    }
    case (PERMANENT_CONTAINER_ACTION_TYPES.TRANSFER_MATERIAL): {
      const container = _find(containerActionContainers, { uri: rowData.metadata?.destination_container });
      const isContainerDisposable = container?.disposable;

      return (
        <div>
          {rowData.metadata?.destination_container && (
            <p>Destination Container:{' '}
              {!_isEmpty(container) && !isContainerDisposable && (
                <a href={getRouteURI(ROUTES.PERMANENT_CONTAINER,
                  { uuid: extractUuid(container.uri) })}
                >
                  {container.name}
                </a>
              )}
              {!_isEmpty(container) && isContainerDisposable && (
                <span>{getShortUUID(container.uri)}</span>
              )}
            </p>
          )}
        </div>
      );
    }
    case (PERMANENT_CONTAINER_ACTION_TYPES.SPLIT_CONTAINER):
    case (PERMANENT_CONTAINER_ACTION_TYPES.MACHINE_LOAD): {
      const notes = rowData?.notes;
      const printer = _find(containerActionPrinters, { uri: rowData.metadata?.destination_printer });

      if (notes) {
        return (
          <div>
            <p className="mb5">
              Notes:
            </p>
            <p>
              <i>{notes}</i>
            </p>

            {rowData.metadata?.destination_printer && !_isEmpty(printer) && (
              <p>Destination Printer:{' '}
                <a href={getRouteURI(ROUTES.PRINTER_EDIT,
                  { uuid: extractUuid(printer.uri) })}
                >
                  {printer.name}
                </a>
              </p>
            )}
          </div>
        );
      }

      return null;
    }
    case (PERMANENT_CONTAINER_ACTION_TYPES.SCRAP): {
      const notes = rowData?.notes;

      if (notes) {
        return (
          <div>
            <p className="mb5">
              Notes:
            </p>
            <p>
              <i>{notes}</i>
            </p>

            {rowData.metadata?.scrapped_batch && (
              <p>Scrapped Batch:{' '}
                <a href={getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(rowData.metadata.scrapped_batch) })}>
                  {getShortUUID(rowData.metadata.scrapped_batch)}
                </a>
              </p>
            )}
          </div>
        );
      }

      return null;
    }
    default:
      return '';
  }
};

export default PermanentContainerMetadataColumn;

PermanentContainerMetadataColumn.propTypes = {
  rowData: PropTypes.shape({
    notes: PropTypes.string,
    action_type: PropTypes.string,
    materials: PropTypes.shape({
      material_names: PropTypes.arrayOf(PropTypes.string),
      material_lots: PropTypes.arrayOf(PropTypes.string),
    }),
    quantity: PropTypes.number,
    source_batch: PropTypes.string,
    metadata: PropTypes.shape({
      resulting_batch: PropTypes.shape({}),
      split_off_batch: PropTypes.shape({}),
      destination_location: PropTypes.string,
      destination_sub_location: PropTypes.string,
      scrapped_batch: PropTypes.string,
      target_container: PropTypes.string,
      destination_container: PropTypes.string,
      destination_printer: PropTypes.string,
      target_printer: PropTypes.string,
      remaining_quantity: PropTypes.number,
      printer: PropTypes.string,
      laser_processing_system: PropTypes.string,
      material_handling_system: PropTypes.string,
      slot_position: PropTypes.string,
      post_processor: PropTypes.string,
    }),
  }).isRequired,
  materialLotDetailsMap: PropTypes.instanceOf(Map).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialLotsFetching: PropTypes.bool.isRequired,
  containerActionContainers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  containerActionPrinters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  containerActionBatches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  postProcessors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

import BureauBarcodeOutput from 'rapidfab/components/BureauBarcodeOutput';
import { getQRAppUri } from 'rapidfab/utils/uriUtils';
import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import QRSticker from 'rapidfab/components/qr/_wrapper';

const Printer = ({ printer, printerType, location, materials, setPrinterTypeModalVisible }) => {
  const printerCustomUri = printerType.is_modular ? `${getQRAppUri(printer.uri)}&isModular=true` : null;
  return (
    <QRSticker>
      <div>Printer Type: {printerType.name || 'N/A'}</div>
      <div>Printer Name: {printer.name}</div>

      <BureauBarcodeOutput url={printer.uri} customOutputUrl={printerCustomUri} />

      <div>Location: {location.name || 'N/A'}</div>
      <div>
        Material Types:{' '}
        {materials.length ? (
          materials.length > 4 ? (
            <>
              <ul className="non-printable">
                {materials.slice(0, 4).map(material => (
                  <li key={material.uri}>{material.name}</li>
                ))}
                {materials.length > 5 && (
                  <li>
                    Plus {materials.length - 4} more.{' '}
                    <Button variant="link" className="p-a-0" onClick={() => setPrinterTypeModalVisible(true)}>
                      View all here
                    </Button>
                  </li>
                )}
              </ul>
              <div className="only-printable">
                {materials.length}
                <br />
                For a full list of material types please navigate to the printer
                type page in Flows
              </div>
            </>
          ) : (
            <div>
              {materials.map(material => (
                <p key={material.uri}>{material.name}</p>
              ))}
            </div>
          )
        ) : (
          'N/A'
        )}
      </div>
    </QRSticker>
  );
};

Printer.propTypes = {
  printer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  printerType: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
    is_modular: PropTypes.bool,
  }).isRequired,
  materials: PropTypes.arrayOf({
    name: PropTypes.string,
  }).isRequired,
  setPrinterTypeModalVisible: PropTypes.func.isRequired,
};

export default Printer;

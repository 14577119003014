import { FormControlSelect } from 'rapidfab/components/formTools';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _sumBy from 'lodash/sumBy';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import {
  MATERIAL_BATCH_ACTION_TYPES,
  CONTAINERIZE_QUANTITY_FIELD_STEP,
  API_RESOURCES,
  MATERIAL_BATCH_STATUSES,
} from 'rapidfab/constants';
import { Button, Col, FormLabel, Form, FormControl, Modal, Row } from 'react-bootstrap';
import Loading from 'rapidfab/components/Loading';
import { FormattedMessage } from 'react-intl';
import Alert from 'rapidfab/utils/alert';
import { materialBatchResourceType } from 'rapidfab/types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

// Generate unique UUID for Disposable Containers to prevent react key render issues.
const generateDisposableUri = () => `disposable_${uuidv4()}`;

const ContainerizeBlock = ({
  batch,
  fetchPermanentContainers,
  fetchedPermanentContainers,
  permanentContainersLoading,
}) => {
  const emptyContainer = { quantity: null, uri: generateDisposableUri() };

  const [permanentContainersFetched, setPermanentContainersFetched] = useState(false);
  const [isModalShown, setShowModal] = useState(false);
  const [containers, setContainers] = useState([{ ...emptyContainer }]);
  const [containersToSelect, setContainersToSelect] = useState([]);

  const isSaving = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH_ACTION].post.fetching);

  const dispatch = useDispatch();

  const containerizeBatch = async (batchUri, containers) => {
    const filteredContainers = containers.filter(container => container.quantity > 0);

    const modifiedContainers = filteredContainers.map(container => {
      if (container.uri.includes('disposable')) {
        const { uri, ...restOfContainer } = container;
        return restOfContainer;
      }
      return container;
    });
    const payload = {
      action_type: MATERIAL_BATCH_ACTION_TYPES.CONTAINERIZE_BATCH,
      source_batch: batchUri,
      metadata: {
        containers: modifiedContainers,
      },
    };

    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH_ACTION].post(payload));

    if (batch?.uri) {
      // Refetch the current batch to update the material_in_containers field
      await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH]
        .get(extractUuid(batch.uri), true));
      // Re-fetch containers by the current Batch URI.
      await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER]
        .list({ current_batch: batch.uri }, { limit: 50 }, {}, {}, true));
    }
  };

  useEffect(() => {
    if (isModalShown && !permanentContainersFetched) {
      fetchPermanentContainers();
      setPermanentContainersFetched(true);
    }
  }, [isModalShown, permanentContainersFetched]);

  useEffect(() => {
    if (permanentContainersFetched) {
      setContainersToSelect(fetchedPermanentContainers);
    }
  }, [fetchedPermanentContainers, permanentContainersFetched]);

  const filteredPermanentContainersByMaterial = useMemo(() => {
    if (!containersToSelect.length) {
      return [];
    }
    // Convert batch materials URIs to a Set for faster lookup
    const batchMaterialURIs = new Set(batch.materials.map(material => material.uri));

    return containersToSelect.filter(permanentContainer => {
      // Check if the container is not archived
      if (permanentContainer.archived) return false;

      // If there are no material restrictions, the container is suitable
      if (!permanentContainer.material_restrictions.length) return true;

      // Check if at least one material in the batch is not supported by "material_restrictions"
      if (permanentContainer.material_restrictions.some(materialUri => !batchMaterialURIs.has(materialUri))) {
        return false;
      }

      // Otherwise - the container is suitable
      return true;
    });
  }, [containersToSelect, batch.materials]);

  if (!batch) {
    return null;
  }

  if (batch.status === MATERIAL_BATCH_STATUSES.DONE) {
    // Skip showing the "Containerize Button" if the Batch is Done.
    return null;
  }

  // No need to show `Containerise` options when there is no quantity left
  if (!batch.quantity) {
    return null;
  }

  // Hide `Containerize` options when batch is loaded into a machine
  if (batch.at_machine) {
    return null;
  }

  if (batch.material_in_containers) {
    return null;
  }

  // Sum the quantities for both containers and permanentContainers
  const specifiedQuantity = _sumBy(containers, 'quantity');

  // Calculate the left quantity based on the total quantity and the specified quantities in both container types
  // If the number is nearly negative due to conversion, set 0
  const leftQuantity = Math.max(0, (batch.quantity - specifiedQuantity)).toFixed(3);

  const isSubmitAllowed = Number(leftQuantity) === 0;

  const closeModal = () => {
    setShowModal(false);
  };

  const showModal = () => {
    setShowModal(true);
  };

  const addNewPermanentContainer = () => {
    setContainers(previousContainers => [
      ...previousContainers,
      { uri: generateDisposableUri(), quantity: null },
    ]);
  };

  const removeContainer = index => {
    const newContainers = containers.filter((container, index_) => index_ !== index);
    setContainers(newContainers);
  };

  const onQuantityChange = (index, event) => {
    const { value } = event.target;
    const newValue = value !== '' ? Number(value) : null;

    setContainers(previousContainers =>
      previousContainers.map((container, index_) =>
        (index_ === index ? { ...container, quantity: newValue } : container),
      ),
    );
  };

  const onSelectPermanentContainer = (index, event) => {
    const selectedContainerUri = event.target.value;
    const containerData = fetchedPermanentContainers.find(container => container.uri === selectedContainerUri);

    const changedContainers = containers.map((container, index_) => {
      if (index_ === index) {
        return { ...container, uri: containerData ? selectedContainerUri : generateDisposableUri() };
      }
      return container;
    });

    setContainers(changedContainers);
  };

  const onSubmit = event => {
    event.preventDefault();

    // Sum the quantities for both containers and permanentContainers for validation
    const totalSpecifiedQuantity = Number(_sumBy(containers, 'quantity').toFixed(3));

    // Check if the total specified quantity exceeds the batch quantity
    if (totalSpecifiedQuantity > batch.quantity) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.containerizeBlock.overQuantity"
          defaultMessage="The total specified quantity exceeds the available batch quantity."
        />,
      );
      return;
    }

    if (!isSubmitAllowed) {
      if (Number(leftQuantity) > 0) {
        Alert.error(
          <FormattedMessage
            id="toaster.error.containerizeBlock.remainingQuantity"
            defaultMessage="You still have {leftQuantity} {batchUnits} remaining to be containerised."
            values={{ leftQuantity, batchUnits: batch.units }}
          />,
        );
      } else {
        Alert.error(
          <FormattedMessage
            id="toaster.error.containerizeBlock.allRemainingQuantity"
            defaultMessage="All remaining quantity needs to be containerised."
          />);
      }

      return;
    }
    containerizeBatch(
      batch.uri,
      containers,
    );
    closeModal();
  };

  return (
    <>
      <p>
        <b>Note:&nbsp;</b>
        Your material is not currently in a known container. Would you like to place your material back into containers?
      </p>
      <Button variant="primary" onClick={showModal} className="pull-right" disabled={isSaving}>
        {isSaving && <Loading inline />} Containerize Material
      </Button>
      <Modal show={isModalShown} onHide={closeModal} backdrop="static">
        <Form onSubmit={onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              Please specify the amount of material in each container
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <b>Note:</b> Specifying a batch is stored in a disposable container
              {' '}will create a new QR code for that container but will NOT
              {' '}create a new Batch ID (it will keep the original Batch ID).
              {' '}<br />Storing a batch in a permanent container will retain the container&apos;s QR code and the original batch ID.
              {' '}To create a new batch, please use the Split action in the QR app.
            </p>
            <p>
              <b>Remaining amount of Material to be containerized:</b>
              &nbsp;
              <span className={Number(leftQuantity) === 0 ? 'text-success' : 'text-danger'}>{leftQuantity} {batch.units}</span>
            </p>
            {containers.map((container, index) => (
              <Row key={container.uri} className="form-group">
                <Col xs={4}>
                  <FormLabel htmlFor={`permanentContainerSelect${index}`}>
                    {index + 1}. Container:
                  </FormLabel>
                </Col>
                <Col xs={4}>
                  <FormControlSelect
                    id={`permanentContainerSelect${index}`}
                    name="permanentContainerSelect"
                    value={container.uri || ''}
                    required
                    onChange={event => onSelectPermanentContainer(index, event)}
                  >
                    <option value={generateDisposableUri()}>Disposable (default)</option>

                    {filteredPermanentContainersByMaterial
                      .filter(pc => !containers.some((c, index_) => index_ !== index && c.uri === pc.uri))
                      .map(filteredContainer => (
                        <option value={filteredContainer.uri} key={filteredContainer.uri}>
                          {filteredContainer.name || `Permanent Container ${index + 1}`}
                        </option>
                      ))}

                  </FormControlSelect>
                </Col>
                <Col xs={2}>
                  <FormControl
                    type="number"
                    step={CONTAINERIZE_QUANTITY_FIELD_STEP}
                    min="0"
                    placeholder="0"
                    max={batch.quantity}
                    onChange={event => onQuantityChange(index, event)}
                    value={container.quantity || null}
                  />
                </Col>
                <Col xs={2}>
                  {containers.length > 1 && (
                    <Button variant="danger" onClick={() => removeContainer(index)} className="pull-right">
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  )}
                </Col>
              </Row>
            ))}
            <Button
              variant="success"
              disabled={permanentContainersLoading}
              onClick={addNewPermanentContainer}
            >
              {permanentContainersLoading ? <Loading inline className="spacer-right" /> : '+'} Add Container
            </Button>
            <div className="clearfix" />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeModal}>
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="success" type="submit" disabled={isSaving || !containers.length}>
              {isSaving && <Loading inline />}
              <FormattedMessage id="button.save" defaultMessage="Save" />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

ContainerizeBlock.defaultProps = {
  batch: null,
};

ContainerizeBlock.propTypes = {
  batch: materialBatchResourceType,
  fetchPermanentContainers: PropTypes.func.isRequired,
  fetchedPermanentContainers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  permanentContainersLoading: PropTypes.bool.isRequired,
};

export default ContainerizeBlock;

import BureauBarcodeOutput from 'rapidfab/components/BureauBarcodeOutput';
import { getQRAppUri } from 'rapidfab/utils/uriUtils';
import React from 'react';
import PropTypes from 'prop-types';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import { FormattedDateTime } from 'rapidfab/i18n';
import QRSticker from 'rapidfab/components/qr/_wrapper';

const getContainerUri = (container, isBatchRedirect, isBarcode) => {
  if (isBatchRedirect && !isBarcode) {
    return `${container.uri}&initialBatchAction=true`;
  }

  return container.uri;
};

const Container = ({ lotUri, materialLot, materialName, container, isBatchRedirect, isBarcode }) => {
  const containerShortUUID = getShortUUID(container.uri);
  const lotShortUUID = getShortUUID(lotUri);
  /* If the Batch has the Permanent Container QR,
     it should still refer to the Batch page in QR
   */
  const containerUri = getContainerUri(container, isBatchRedirect, isBarcode);
  const isPermanentContainer = !container.disposable;

  return (
    <QRSticker>
      {isPermanentContainer && (
        <div>Material Name: {container.name}</div>
      )}
      <div>Material Container: {containerShortUUID}</div>
      {!isPermanentContainer && (
        <div>Material Type: {materialName || 'N/A'}</div>
      )}

      <BureauBarcodeOutput
        url={containerUri}
        customOutputUrl={getQRAppUri(getContainerUri(container, isBatchRedirect, false))}
      />

      {!isPermanentContainer && (
        <>
          <div>Material Lot: {lotShortUUID || 'N/A'}</div>
          {materialLot && (
            <div>Delivery ID: {materialLot.delivery_id || 'N/A'}</div>
          )}
        </>
      )}

      <div>Created: <FormattedDateTime value={container.created} /></div>
    </QRSticker>
  );
};

Container.propTypes = {
  lotUri: PropTypes.string.isRequired,
  materialLot: PropTypes.shape({
    delivery_id: PropTypes.string,
  }).isRequired,
  materialName: PropTypes.string.isRequired,
  container: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string,
    uri: PropTypes.string,
    created: PropTypes.string,
    disposable: PropTypes.bool,
  }).isRequired,
  isBatchRedirect: PropTypes.bool,
  isBarcode: PropTypes.bool,
};

Container.defaultProps = {
  isBatchRedirect: false,
  isBarcode: false,
};
export default Container;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FormLabel, FormGroup, FormControl } from 'react-bootstrap';
import Alert from 'rapidfab/utils/alert';
import { ALLOWED_DOCUMENT_EXTENSIONS, ALLOWED_MODEL_EXTENSIONS } from 'rapidfab/constants';

const FileInput = ({
  customFileFormControl,
  handleFileChange,
  name,
  required,
  fileType,
  buttonStyle,
  buttonClassName,
  onlyFileInput,
  acceptedExtensions,
  chooseFileLabel,
  disabled,
  style,
  controlLabelStyle,
  customContainerStyles,
}) => {
  // Changing File Input key will trigger re-render of file input
  // (which is the same as `Clear File Input`)
  const [fileInputKey, setFileInputKey] = useState(() => Date.now());
  const getAcceptedExtensions = () => {
    if (acceptedExtensions) {
      // List of accepted extensions is already defined by props
      return acceptedExtensions;
    }

    if (fileType === FileInput.fileTypes.model) {
      return ALLOWED_MODEL_EXTENSIONS;
    }

    if (fileType === FileInput.fileTypes.document) {
      return ALLOWED_DOCUMENT_EXTENSIONS;
    }

    return [];
  };

  const validExtensions = getAcceptedExtensions();
  const acceptExtensions = `.${validExtensions.join(', .')}`;

  function checkFileType(event) {
    try {
      const file = event.target.files[0];
      const fileExtension = file.name.toLowerCase().split('.').pop();

      if (!validExtensions.includes(fileExtension)) {
        Alert.error(
          <FormattedMessage
            id="toaster.error.fileType.extensionMustBeOfType"
            defaultMessage="{fileType} must be one of extension: {acceptExtensions}"
            values={{ fileType, acceptExtensions }}
          />);
        return;
      }
    } catch {
      // if something goes wrong here, just accept it
    }

    handleFileChange(event);
    // Without this, uploading same file twice won't be possible
    // (since file input will still store the same file)
    // See `AddLineItemPresentation -> Document upload` to see the problem
    setFileInputKey(Date.now());
  }

  const fileFormControl = (
    <FormControl
      disabled={disabled}
      key={fileInputKey}
      id={`${fileType}Input`}
      name={fileType}
      type="file"
      accept={acceptExtensions}
      required={required}
      /* eslint-disable-next-line react/jsx-no-bind */
      onChange={checkFileType}
      style={onlyFileInput ?
        {}
        // Hide default file input when onlyFileInput is false
        : {
          width: '100%',
          height: '100%',
          position: 'absolute',
          opacity: 0,
        }}
    />
  );

  if (onlyFileInput) {
    return fileFormControl;
  }

  return (
    <FormGroup style={{ ...style }} className={`p-relative ${customContainerStyles ?? 'm-l-0 mt15'}`}>
      {fileFormControl}

      {customFileFormControl ? (
        <div htmlFor="fileInput">
          {customFileFormControl}
        </div>
      ) : (
        <FormLabel
          style={{ ...controlLabelStyle, ...(disabled && { pointerEvents: 'none', opacity: 0.65 }) }}
          htmlFor="fileInput"
          className={`btn ${buttonStyle} ${buttonClassName} file-input`}
        >
          {chooseFileLabel || (
            <FormattedMessage
              id={`choose${fileType}File`}
              defaultMessage={`Choose ${fileType} File`}
            />
          )}
        </FormLabel>
      )}

      {name && (
        <div className="d-inline ml15 link">
          {name}
        </div>
      )}
    </FormGroup>
  );
};

FileInput.fileTypes = {
  document: 'Document',
  model: 'Model',
};

FileInput.defaultProps = {
  name: '',
  required: false,
  acceptedExtensions: null,
  fileType: FileInput.fileTypes.model,
  buttonStyle: 'btn-default',
  buttonClassName: null,
  onlyFileInput: false,
  chooseFileLabel: null,
  disabled: false,
  style: null,
  controlLabelStyle: null,
  customContainerStyles: null,
  customFileFormControl: null,
};

FileInput.propTypes = {
  customFileFormControl: PropTypes.node,
  handleFileChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
  fileType: PropTypes.oneOf(Object.values(FileInput.fileTypes)),
  buttonStyle: PropTypes.oneOf(['btn-primary', 'btn-default']),
  buttonClassName: PropTypes.string,
  onlyFileInput: PropTypes.bool,
  acceptedExtensions: PropTypes.arrayOf(PropTypes.string),
  chooseFileLabel: PropTypes.node,
  disabled: PropTypes.bool,
  style: PropTypes.shape({}),
  controlLabelStyle: PropTypes.shape({}),
  customContainerStyles: PropTypes.string,
};

export default FileInput;

const useEnvironmentHosts =
  process.env.EVENTSTREAM_HOST ||
  process.env.SCYLLA_HOST ||
  process.env.NAUTILUS_HOST ||
  process.env.QR_HOST ||
  process.env.SENTRY_DSN;

/**
  * Takes a url, and returns a string of the domain without the subdomain
  * e.g flows.dev-auth2.com -> dev-auth2.com
  * e.g dev.threads.dev-auth2.com -> threads.dev-auth2.com
  *
  * @param {URL} url
  * @returns {string}
*/
export function getDomain(url) {
  try {
    const parts = url.hostname.split('.')
    if (parts.length > 2) {
      parts.shift();
    }
    return parts.join('.');
  } catch {
    return null;
  }
}

let config = {
  SENTRY_DSN: process.env.SENTRY_DSN || null,
  YBUG_ID: process.env.YBUG_ID || null,
  HOST: {
    EVENT: process.env.EVENTSTREAM_HOST || 'https://events.dev-auth2.com/',
    SCYLLA: process.env.SCYLLA_HOST || 'https://app.dev-auth2.com',
    NAUTILUS: process.env.NAUTILUS_HOST || 'https://data.dev-auth2.com',
    QR: process.env.QR_HOST || 'https://qr.dev-auth2.com',
    THREADS_API: process.env.THREADS_API_HOST || 'https://api.threads.dev-auth2.com'
  },
  TOS_URL: 'https://authentise.com/tech/etos.html',
  AUTH_REDIRECT_SUBDOMAIN: 'rapidfab',
  THREADS_URL: process.env.THREADS_URL || 'https://dev.threads.dev-auth2.com',
  ENVIRONMENT: getDomain(window.location),
};

// useEnvironmentHosts=true for local development only for now
// (since `env` are NOT set for dev/stage/prod)
if (!useEnvironmentHosts) {
  // Use window.configs (config.js from root dir) by default
  // if host variables isn't found in environment
  config = window.Config || config;
}


export default config;

import React from 'react';
import PropTypes from 'prop-types';
import { finalFormInputTypes } from 'rapidfab/types';
import Loading from 'rapidfab/components/Loading';
import {
  Button,
  FormLabel,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { Form, Field } from 'react-final-form';
import { FormControlSelect } from 'rapidfab/components/formTools';
import HISTORY_COLLECTION_FREQUENCY_TYPES from 'rapidfab/constants/historyCollectionFrequencyTypes';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';
import SaveButton from 'rapidfab/components/SaveButton';

const ModelerModal = ({
  onClose,
  isSubmitting,
  onFormSubmitNew,
  onFormSubmitEdit,
  isLoading,
  modeler,
  modelerTypes,
  initialFormValues,
}) => {
  if (isLoading) {
    return (
      <Modal show backdrop="static">
        <ModalBody>
          <Loading />
        </ModalBody>
      </Modal>
    );
  }
  // Handles form submission for both new and existing modelers
  // For existing modelers, only submits fields that were changed
  // For new modelers, submits all form values
  const handleSubmit = (values, form) => {
    if (modeler) {
      // Get modified fields from form state to track which fields user interacted with
      const touchedFields = form.getState().modified;

      // Create an object containing only fields that were both:
      // 1. Touched by the user
      // 2. Changed from their initial values
      const changedValues = {};
      Object.keys(touchedFields).forEach(key => {
        if (touchedFields[key] && values[key] !== initialFormValues[key]) {
          changedValues[key] = values[key];
        }
      });

      // Submit only the changed values for existing modeler
      return onFormSubmitEdit(changedValues);
    }
    // Submit all values for new modeler
    return onFormSubmitNew(values);
  };
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      subscription={{
        pristine: true,
        touched: true,
      }}
    >
      {({ handleSubmit, pristine }) => (
        <Modal size="lg" show onHide={onClose} backdrop="static">
          <ModalHeader closeButton>
            <ModalTitle>
              {modeler ? `Edit ${modeler.name}` : 'Add Printer Link'}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <form id="modeler" onSubmit={handleSubmit}>
              <FormGroupField
                name="name"
                controlId="modelerName"
                required
                label={(
                  <FormattedMessage
                    id="field.name"
                    defaultMessage="Name"
                  />
                )}
              />
              <FormGroupField
                name="ip_address"
                controlId="modelerIpAddress"
                label={(
                  <FormattedMessage
                    id="field.ipAddress"
                    defaultMessage="IP Address"
                  />
                )}
              />
              <FormGroupField
                name="serial_number"
                controlId="modelerSerialNumber"
                label={(
                  <FormattedMessage
                    id="field.serialNumber"
                    defaultMessage="Serial Number"
                  />
                )}
              />
              <FormGroupField
                name="username"
                controlId="modelerUsername"
                label={(
                  <FormattedMessage id="field.username" defaultMessage="Username" />
                )}
              />
              <FormGroupField
                name="password"
                type="password"
                controlId="modelerPassword"
                label={(
                  <FormattedMessage id="field.password" defaultMessage="Password" />
                )}
                placeholder={modeler && modeler.password ? '*****' : ''}
              />

              <FormGroup controlId="modelerType" className="form-group">
                <FormLabel>
                  <FormattedMessage
                    id="field.printerLinkType"
                    defaultMessage="Printer Link Type"
                  />
                  :
                </FormLabel>
                <Field
                  name="type"
                  render={props => (
                    <FormControlSelect
                      {...props.input}
                    >
                      {modelerTypes.map(modelerType => (
                        <option key={modelerType.uri} value={modelerType.uri}>
                          {modelerType.name}
                        </option>
                      ))}
                    </FormControlSelect>
                  )}
                />
              </FormGroup>

              <FormGroup controlId="modelerFrequency" className="modeler-frequency-dropdown">
                <FormLabel>
                  <FormattedMessage
                    id="field.historyCollectionFrequency"
                    defaultMessage="History Collection Frequency"
                  />
                  :
                </FormLabel>
                <Field
                  name="history_collection_frequency"
                  render={props => (
                    <FormControlSelect
                      {...props.input}
                    >
                      {
                        Object.keys(HISTORY_COLLECTION_FREQUENCY_TYPES).map(type => (
                          <option key={type} value={HISTORY_COLLECTION_FREQUENCY_TYPES[type]}>
                            {HISTORY_COLLECTION_FREQUENCY_TYPES[type]}
                          </option>
                        ))
                      }
                    </FormControlSelect>
                  )}
                />
              </FormGroup>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button className="pull-right" onClick={onClose}>
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Button>
            <SaveButton
              showSaveIcon={false}
              disabled={pristine}
              isSaving={isSubmitting || isLoading}
              label={<FormattedMessage id="button.save" defaultMessage="Save" />}
              bsStyle="success"
              className="pull-right ml5"
              form="modeler"
            />
          </ModalFooter>
        </Modal>
      )}
    </Form>
  );
};

ModelerModal.defaultProps = {
  isSubmitting: false,
  isLoading: false,
  modeler: null,
};

ModelerModal.propTypes = {
  onFormSubmitNew: PropTypes.func.isRequired,
  onFormSubmitEdit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  input: finalFormInputTypes.isRequired,
  initialFormValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    ip_address: PropTypes.string.isRequired,
    serial_number: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  modeler: PropTypes.shape({
    name: PropTypes.string,
    password: PropTypes.string,
  }),
  modelerTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ModelerModal;

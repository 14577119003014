import React from 'react';
import { FormLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';

import { COLORS } from 'rapidfab/constants';
import { FormattedMessage } from 'rapidfab/i18n';

const FormFieldMessage = (id, text, variant) => {
  const styles = {
    error: {
      class: 'text-danger',
      icon: faWarning,
      iconColor: COLORS.ORANGE,
    },
  };

  return (
    <FormLabel className={`d-flex align-items-center mt-1 mb-0 font-size-12 ${styles[variant].class}`}>
      <FontAwesomeIcon className="spacer-left spacer-right" icon={styles[variant].icon} style={{ color: styles[variant].iconColor }} />
      <p className="m-0">
        <FormattedMessage
          id={id}
          defaultMessage={text}
        />
      </p>
    </FormLabel>
  );
};

export default FormFieldMessage;

import PropTypes from 'prop-types';
import React from 'react';
import FusionLogo from 'rapidfab/icons/fusion-logo';

const FusionButton = ({ onClick, content, disabled, hidden }) => {
  if (hidden) return null;
  return (
    <button type="button" className="fusion-connect-button" onClick={onClick} disabled={disabled || !onClick}>
      <FusionLogo />
      {content && (
        <div className="fusion-connect-button-text">{content}</div>
      )}
    </button>
  );
};

FusionButton.defaultProps = {
  onClick: () => {},
  content: 'View in Fusion',
  disabled: false,
  hidden: false,
};

FusionButton.propTypes = {
  onClick: PropTypes.func,
  content: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
};

export default FusionButton;

import PostProcessorTypeModalContainer from 'rapidfab/containers/modals/PostProcessorTypeModalContainer';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  getUUIDResource,
  getRouteUUID,
  getMaterialsForPostProcessorType,
} from 'rapidfab/selectors';
import Actions from 'rapidfab/actions';

import Loading from 'rapidfab/components/Loading';
import QrPostProcessor from 'rapidfab/components/qr/PostProcessor';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import { Col, Container, Row } from 'react-bootstrap';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';

const PostProcessorContainer = () => {
  const dispatch = useDispatch();
  const uuid = useSelector(state => getRouteUUID(state));
  const postProcessor = useSelector(state => getUUIDResource(state, uuid));
  const isLoading = useSelector(state => !postProcessor
    || state.ui.nautilus[API_RESOURCES.POST_PROCESSOR].get.fetching
    || state.ui.nautilus[API_RESOURCES.LOCATION].get.fetching
    || state.ui.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching,
  );

  const [postProcessorTypeModalVisible, setPostProcessorTypeModalVisible] = useState(false);

  const location = useSelector(state => postProcessor && getUUIDResource(state, extractUuid(postProcessor.location)));

  const postProcessorType = useSelector(state => postProcessor
    && getUUIDResource(state, extractUuid(postProcessor.post_processor_type)));

  const materials = useSelector(state => getMaterialsForPostProcessorType(state, postProcessorType));

  const onInitialize = async postProcessorUuid => {
    const result = await dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].get(postProcessorUuid));
    const { location: locationUri, post_processor_type: postProcessorTypeUri } = result.json;
    const [postProcessorTypeResult] = await Promise.all([
      dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].get(extractUuid(postProcessorTypeUri))),
      dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].get(extractUuid(locationUri))),
    ]);
    const { materials: currentMaterials } = postProcessorTypeResult.json;
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ uri: currentMaterials }));
  };

  useEffect(() => {
    if (uuid) {
      onInitialize(uuid).then(() => window.print());
    }
  }, [uuid]);

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          {isLoading
            ? (<Loading />)
            : (
              <>
                <div className="hide-on-print">
                  <BreadcrumbNav
                    breadcrumbs={[
                      'postProcessors',
                      {
                        message: `${postProcessor.name} (${getShortUUID(postProcessor.uuid)})`,
                        href: getRouteURI(ROUTES.POST_PROCESSOR_EDIT, { uuid: postProcessor.uuid }),
                      },
                      `Print ${postProcessor.name} QR Code`,
                    ]}
                  />
                </div>

                <QrPostProcessor
                  postProcessor={postProcessor}
                  location={location || {}}
                  postProcessorType={postProcessorType || {}}
                  materials={materials || []}
                  setPostProcessorTypeModalVisible={setPostProcessorTypeModalVisible}
                />

                {postProcessorType?.uuid && postProcessorTypeModalVisible && (
                  <PostProcessorTypeModalContainer
                    isVisible={postProcessorTypeModalVisible}
                    hideModal={() => setPostProcessorTypeModalVisible(false)}
                    externalUUID={postProcessorType.uuid}
                  />
                )}
              </>
            )}
        </Col>
      </Row>
    </Container>
  );
};

PostProcessorContainer.propTypes = {
  postProcessor: PropTypes.shape({
    name: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  postProcessorType: PropTypes.shape({}).isRequired,
};

export default PostProcessorContainer;

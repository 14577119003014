import { useOrderQuoteContext } from 'rapidfab/context/OrderQuoteContext';
import { useQuoteModal } from 'rapidfab/context/QuoteProcessStepsModalContext';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, ListGroup, ListGroupItem, Card, Row, Tooltip, OverlayTrigger, Form } from 'react-bootstrap';
import {
  FORMATTED_DURATION_TYPES,
  FormattedMessage, FormattedOptionalDuration,
} from 'rapidfab/i18n';
import PropTypes from 'prop-types';
import _sumBy from 'lodash/sumBy';
import classNames from 'classnames';
import nl2br from 'rapidfab/utils/nl2br';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'rapidfab/components/Loading';
import { convertHoursToSeconds } from 'rapidfab/utils/timeUtils';
import { faInfoCircle, faMoneyBills, faPencil, faSave } from '@fortawesome/free-solid-svg-icons';
import { workStepPricePerLineItem } from 'rapidfab/utils/orderQuoteUtils';

const LineItemQuotePreview = ({
  processSteps,
  processStepTypesByUri,
  lineItemQuantity,
  publicNotes,
  lineItemUri,
  workstepCostEstimates,
  isRobozeDDWFeatureEnabled,
  lineItem,
  setLineItemViewUri,
  lineItemQuoteDataFetching,
  modelFetching,
  lineItemQuote,
  handleUpdateQuoteManualPrice,
}) => {
  const {
    calculateTotalPricePerPiece,
  } = useOrderQuoteContext();

  const {
    showQuoteModal,
  } = useQuoteModal();

  const [manualPricePerChecked, setManualPricePerChecked] = useState(false);
  const [manualPricePerEditMode, setManualPricePerEditMode] = useState(false);
  const [manualPricePer, setManualPricePer] = useState('');

  const handleShowOrderModal = currentStep => {
    setLineItemViewUri(lineItem.uri);

    showQuoteModal({
      currentStep,
      processStepTypesByUri,
      workstepCostEstimates,
      modelFetching,
      lineItemUri,
      calculateTotalPricePerPiece,
    });
  };

  const stepPricePerPiece = step => Math.round(workStepPricePerLineItem(step, lineItemQuantity) / lineItemQuantity, 2);

  const totalPrice = useMemo(() => {
    if (!lineItemQuote?.manual_price_per) {
      return _sumBy(
        processSteps, current => workStepPricePerLineItem(current.work_steps_quote_details, lineItemQuantity),
      );
    }
    return lineItemQuote?.manual_price_per;
  }, [processSteps, lineItemQuote]);

  useEffect(() => {
    setManualPricePer(totalPrice);
    setManualPricePerChecked(!!lineItemQuote?.manual_price_per);
  }, [lineItemQuote?.manual_price_per, totalPrice]);

  const listHeaderClassNames = classNames({
    'resource-list-item-card-header': true,
    'disabled-interaction disabled half-opacity not-allowed-cursor': manualPricePerChecked,
  });
  const listItemClassNames = classNames({
    'resource-list-item-card-item': true,
    'disabled-interaction disabled half-opacity not-allowed-cursor': manualPricePerChecked,
  });

  return (
    <Card bg="dark" className="custom-darken-modal--card-lite mb15">
      <Card.Header className="custom-darken-modal--card-header-quotePreview pd-exp">
        <FontAwesomeIcon icon={faMoneyBills} className="spacer-right" />
        <FormattedMessage
          id="line_item_quote.preview"
          defaultMessage="Quote Preview"
        />
      </Card.Header>
      <Card.Body className="custom-darken-modal--card-body">
        <ListGroup fill>
          {!!publicNotes && (
            <ListGroupItem className="wrap-text">
              <div>
                <b>
                  <FormattedMessage
                    id="field.notes"
                    defaultMessage="Notes"
                  />:
                </b>
              </div>
              {nl2br(publicNotes)}
            </ListGroupItem>
          )}
          {processSteps.length > 0 && (
            <>
              <ListGroupItem className={listHeaderClassNames}>
                <Row>
                  <Col xs={5}>
                    <b>
                      <FormattedMessage
                        id="line_item.workflow_step"
                        defaultMessage="Workflow Step"
                      />
                    </b>
                  </Col>
                  <Col xs={2}>
                    <b>
                      <FormattedMessage
                        id="line_item_quote.unit_price"
                        defaultMessage="Unit Price"
                      />
                    </b>
                  </Col>
                  <Col xs={2}>
                    <b>
                      <FormattedMessage
                        id="process_step.flowTime"
                        defaultMessage="Flow Time"
                      />
                    </b>
                  </Col>
                  <Col xs={2}>
                    <b>
                      <FormattedMessage
                        id="line_item_quote.total"
                        defaultMessage="Total"
                      />
                    </b>
                  </Col>
                  <Col xs={1}>
                    <> </>
                  </Col>
                </Row>
              </ListGroupItem>
              {!isRobozeDDWFeatureEnabled && processSteps.map(processStep => (
                <ListGroupItem key={processStep.uri} className={listItemClassNames}>
                  <Row>
                    <Col xs={5} className="wrap-text">
                      {processStep.name}
                    </Col>
                    <Col xs={2} className="wrap-text">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={!processStep.work_steps_quote_details.in_price
                          ? (
                            <Tooltip>
                              <div>
                                <FormattedMessage
                                  id="unitPriceBreakdown"
                                  defaultMessage="Unit Price Breakdown"
                                />
                              </div>
                              <div>
                                (<FormattedMessage
                                  id="excludedFromPrice"
                                  defaultMessage="Excluded from Price"
                                />)
                              </div>
                              <div>
                                <FormattedMessage
                                  id="field.duration"
                                  defaultMessage="Duration"
                                />: --- hours
                              </div>
                              <div>
                                <FormattedMessage
                                  id="rate"
                                  defaultMessage="Rate"
                                />: --- per hour
                              </div>
                            </Tooltip>
                          )
                          : <></>}
                      >
                        <div>
                          <span className={!processStep.work_steps_quote_details.in_price && 'crossed-line'}>
                            {processStep ? (
                              <FormattedLocalizedCost
                                value={stepPricePerPiece(processStep.work_steps_quote_details)}
                              />
                            ) : (
                              <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                            )}
                          </span>
                          {!processStep.work_steps_quote_details.in_price && (
                            <span className="ml5">
                              $0
                            </span>
                          )}
                        </div>
                      </OverlayTrigger>
                    </Col>
                    <Col xs={2} className="wrap-text">
                      {processStep ? (
                        lineItemQuoteDataFetching ?
                          <Loading inline />
                          : (
                            <span className={!processStep.work_steps_quote_details.in_price && 'crossed-line'}>
                              <FormattedOptionalDuration
                                intervalFormat={FORMATTED_DURATION_TYPES.DAYS}
                                value={processStep?.flow_time && convertHoursToSeconds(processStep.flow_time)}
                              /> <span>days</span>
                            </span>
                          )

                      ) : (
                        <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                      )}
                      {!processStep.work_steps_quote_details.in_price && (
                        <span className="ml5">
                          $0
                        </span>
                      )}
                    </Col>
                    <Col xs={2} className="wrap-text">
                      {processStep.work_steps_quote_details.separate && (
                        <OverlayTrigger
                          placement="top"
                          overlay={(
                            <Tooltip>
                              <FormattedMessage
                                id="lineSeparateInQuote"
                                defaultMessage="Line separate in quote"
                              />
                            </Tooltip>
                          )}
                        >
                          <FontAwesomeIcon className="pr-1" icon={faInfoCircle} />
                        </OverlayTrigger>
                      )}
                      {processStep ? (
                        lineItemQuoteDataFetching ?
                          <Loading inline />
                          : (
                            <span className={!processStep.work_steps_quote_details.in_price && 'crossed-line'}>
                              <FormattedLocalizedCost
                                value={workStepPricePerLineItem(processStep.work_steps_quote_details, lineItemQuantity)}
                              />
                            </span>
                          )

                      ) : (
                        <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                      )}
                      {!processStep.work_steps_quote_details.in_price && (
                        <span className="ml5">
                          $0
                        </span>
                      )}
                    </Col>
                    <Col xs={1}>
                      <button
                        type="button"
                        onClick={() => handleShowOrderModal(processStep)}
                        style={{ background: 'none', border: 'none' }}
                      >
                        <FontAwesomeIcon icon={faPencil} color="white" />
                      </button>
                    </Col>
                  </Row>

                </ListGroupItem>
              ))}
              <ListGroupItem className="resource-list-item-card-item">
                <Row>
                  <Col xs={9}>
                    <div className="d-flex align-items-center">
                      <Form.Check
                        className="spacer-right"
                        name="autorun"
                        type="checkbox"
                        onChange={() => {
                          if (manualPricePerChecked) {
                            setManualPricePerEditMode(false);
                            setManualPricePer(totalPrice);
                            handleUpdateQuoteManualPrice(null);
                          }
                          setManualPricePerChecked(prevState => !prevState);
                        }}
                        checked={manualPricePerChecked}
                      />
                      <FormattedMessage
                        id="line_item_quote.manualPricePer"
                        defaultMessage="Manual Price"
                      />

                    </div>
                  </Col>
                  <Col xs={2} className="wrap-text">
                    {
                      lineItemQuoteDataFetching ? <Loading inline /> : (
                        <>
                          {manualPricePerChecked && !manualPricePerEditMode && (
                            <FormattedLocalizedCost
                              value={manualPricePer}
                            />
                          )}
                          {manualPricePerChecked && manualPricePerEditMode && (
                            <input
                              onChange={event => {
                                setManualPricePer(event.target.value ? Number(event.target.value) : '');
                              }}
                              className="line-item-quote-modal-input"
                              type="number"
                              disabled={!manualPricePerChecked}
                              value={manualPricePer}
                            />
                          )}
                        </>
                      )
                    }

                  </Col>
                  <Col xs={1}>
                    {manualPricePerChecked && (
                      <>
                        {manualPricePerEditMode ? (
                          <FontAwesomeIcon
                            icon={faSave}
                            className="cursor-pointer"
                            onClick={() => {
                              setManualPricePerEditMode(false);
                              handleUpdateQuoteManualPrice(manualPricePer);
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faPencil}
                            className="cursor-pointer"
                            onClick={() => {
                              setManualPricePerEditMode(true);
                            }}
                          />
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem className="resource-list-item-card-item-last">
                <Row>
                  <Col xs={3}>
                    <FormattedMessage
                      id="line_item_quote.total_for_lineitem"
                      defaultMessage="Total for Line Item"
                    />
                  </Col>
                  <Col xs={3} className="wrap-text">
                    {processSteps.length ? (
                      lineItemQuoteDataFetching ? <Loading inline /> : (
                        <FormattedLocalizedCost
                          value={totalPrice}
                        />
                      )
                    ) : (
                      <FormattedMessage id="notAvailable" defaultMessage="N/A" />
                    )}
                  </Col>
                  <Col xs={1} />
                </Row>
              </ListGroupItem>
            </>
          )}
          {processSteps.length === 0 && (
            <FormattedMessage
              id="line_item.no_workflow_steps"
              defaultMessage="No Workflow Steps"
            />
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

LineItemQuotePreview.propTypes = {
  processSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  publicNotes: PropTypes.string.isRequired,
  lineItemQuantity: PropTypes.number.isRequired,
  lineItemUri: PropTypes.string.isRequired,
  workstepCostEstimates: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  processStepTypesByUri: PropTypes.shape({}).isRequired,
  isRobozeDDWFeatureEnabled: PropTypes.bool.isRequired,
  lineItem: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
  modelFetching: PropTypes.bool.isRequired,
  setLineItemViewUri: PropTypes.func.isRequired,
  lineItemQuoteDataFetching: PropTypes.bool.isRequired,
  lineItemQuote: PropTypes.shape({
    manual_price_per: PropTypes.number,
  }).isRequired,
  handleUpdateQuoteManualPrice: PropTypes.func.isRequired,
};

export default LineItemQuotePreview;

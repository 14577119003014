import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import PermanentContainerRecord from 'rapidfab/components/inventory/PermanentContainerRecord';
import { isURI } from 'rapidfab/utils/uriUtils';
import React, { useEffect, useMemo, useState } from 'react';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import {
  API_RESOURCES,
  PERMANENT_CONTAINER_ACTION_TYPES,
} from 'rapidfab/constants';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'rapidfab/utils/alert';

import { PERMANENT_CONTAINER } from 'rapidfab/constants/forms';
import { FormattedMessage } from 'react-intl';
import {
  getIncompleteBatchTransactions,
  getLocationsByRole,
  getMaterialContainerActionsForPermanentContainer, getMaterialLots, getPostProcessors, getPrinters,
  getSortedMaterialsByName, getSubLocations, getSubLocationsForLocation,
  getUUIDResource,
} from 'rapidfab/selectors';

const PermanentContainerRecordContainer = props => {
  const [isEditMode, setIsEditMode] = useState(false);
  const uuid = useSelector(Selectors.getRouteUUID);
  const permanentContainer = useSelector(state => Selectors.getInitialValuesBureau(state, props));
  const materials = useSelector(getSortedMaterialsByName);
  const incompleteBatchTransactions = useSelector(getIncompleteBatchTransactions);
  const printers = useSelector(getPrinters);
  const postProcessors = useSelector(getPostProcessors);
  const materialLots = useSelector(getMaterialLots);
  const locations = useSelector(getLocationsByRole);
  const bureau = useSelector(Selectors.getBureau);
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const usersByUri = useSelector(Selectors.getUsersByUri);
  const printerTypesByUri = useSelector(Selectors.getPrinterTypesByUri);
  const [location, setLocation] = useState();

  const subLocations = useSelector(getSubLocations);

  const subLocationsForLocation = useSelector(state =>
    (location ? getSubLocationsForLocation(state, location) : []));

  const subLocation = useSelector(
    state => permanentContainer?.sub_location && getUUIDResource(state, extractUuid(permanentContainer.sub_location)),
  );
  const permanentContainerActions = useSelector(state =>
    (permanentContainer ? getMaterialContainerActionsForPermanentContainer(state, permanentContainer) : null));

  const materialLotDetailsMap = new Map(materialLots.map(lot => [lot.uri, lot]));

  const isSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].post.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].put.fetching);
  const subLocationsFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.SUB_LOCATION].list.fetching);
  const materialResourcesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].list.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].get.fetching);
  const materialsFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching);
  const fetchingData = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].get.fetching ||
    state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching);
  const materialLotsFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_LOT].list.fetching);
  const actionsSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER_ACTION].post.fetching);

  const batchTransactionsFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH_ACTION_TRANSACTION].list.fetching);

  const [batch, setBatch] = useState(null);
  const [containerActionBatches, setContainerActionBatches] = useState([]);
  const [containerActionContainers, setContainerActionContainers] = useState([]);
  const [permanentContainerSelectedMaterials, setPermanentContainerSelectedMaterials] = useState([]);
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);

  const initialFormValues = {};
  Object
    .keys(permanentContainer)
    .filter(key => PERMANENT_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = permanentContainer[key];
    });

  const [isAllMaterialsButtonSelected, setIsAllMaterialsButtonSelected] =
    useState(!!initialFormValues?.material_restrictions?.length
      && (initialFormValues?.material_restrictions?.length === materials?.length));

  const onSelectAllMaterials = (args, state) => {
    setPermanentContainerSelectedMaterials(materials);
    return state?.fields.material_restrictions.change(materials);
  };

  const onDeselectAllMaterials = (args, state) => {
    setPermanentContainerSelectedMaterials([]);
    return state?.fields.material_restrictions.change([]);
  };

  const onLocationChange = newLocation => setLocation(newLocation);

  const containerPrinterTypes = useMemo(() => {
    const printerTypeRelationships = permanentContainer.printer_type_relationships || [];
    return printerTypeRelationships.map(uri => printerTypesByUri[uri]).filter(Boolean);
  }, [permanentContainer, printerTypesByUri]);

  const selected = {
    uuid,
    permanentContainer,
    initialFormValues,
    isSubmitting,
    materialData: {
      permanentContainerSelectedMaterials,
      materials,
      materialResourcesFetching,
      materialsFetching,
      isAllMaterialsButtonSelected,
      setIsAllMaterialsButtonSelected,
    },
    editMode: {
      isEditMode,
      setIsEditMode,
    },
    locationData: {
      location,
      subLocation,
      locations,
      subLocations,
      subLocationsFetching,
      subLocationsForLocation,
    },
    batch,
    isDebugModeEnabled,
    usersByUri,
    permanentContainerActions,
    fetchingData,
    showArchiveConfirmation,
    materialLotDetailsMap,
    materialLotsFetching,
    containerActionBatches,
    containerActionContainers,
    containerActionPrinters: printers,
    onLocationChange,
    actionsSubmitting,
    postProcessors,
    printerTypes: containerPrinterTypes,
    incompleteBatchTransactions,
    batchTransactionsFetching,
  };

  const dispatch = useDispatch();

  const onInitialize = (currentUuid, bureauUri) => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: bureauUri }));
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list());
    if (currentUuid) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].get(currentUuid, true))
        .then(locationResponse => {
          const currentBatch = locationResponse.json?.current_batch;
          setLocation(locationResponse.json?.location);

          dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH_ACTION_TRANSACTION].clear('list'));
          if (currentBatch) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH]
              .list({ uri: currentBatch }, {}, {}, {}, true))
              .then(batchResponse => {
                const batch = batchResponse.json.resources[0] ?? null;

                if (batch) {
                  setBatch(batch);
                }
              });

            dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH_ACTION_TRANSACTION]
              .list({ material_batch: currentBatch }, {}, {}, {}, true));
          }

          const printerTypes = locationResponse.json?.printer_type_relationships || [];
          if (printerTypes.length) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list({ uri: printerTypes }));
          }
        });
    }
  };

  const loadContainerTraceabilityReport = permanentContainerUri => {
    if (!permanentContainerUri) return null;

    return dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER_ACTION]
      .list({ source_material_container: permanentContainerUri }))
      .then(actionsResponse => {
        const resources = actionsResponse?.json?.resources || [];
        const userUris = _uniq(_map(resources, 'user'));
        const relocateActions = _filter(resources, { action_type: PERMANENT_CONTAINER_ACTION_TYPES.RELOCATE });
        const postProcessorUris = _uniq(_map(resources, 'metadata.post_processor')).filter(Boolean);

        const materialLotUris = [...new Set(resources.flatMap(resource =>
          resource.materials?.material_lots ?? [],
        ))];

        const combinedBatchesUris = [
          ...new Set([
            ...resources.flatMap(resource => resource?.source_batch ?? []),
            ...resources.flatMap(resource => resource?.metadata?.scrapped_batch ?? []),
          ]),
        ];

        const combinedContainerUris = [
          ...new Set([
            ...resources.flatMap(resource => resource?.metadata?.destination_container ?? []),
            ...resources.flatMap(resource => resource?.metadata?.target_container ?? []),
          ]),
        ].filter(container => isURI(container));

        const combinedPrinterUris = [
          ...new Set([
            ...resources.flatMap(resource => resource?.metadata?.destination_printer ?? []),
            ...resources.flatMap(resource => resource?.metadata?.target_printer ?? []),
            ...resources.flatMap(resource => resource?.metadata?.printer ?? []),
          ]),
        ];

        if (materialLotUris.length) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].list({ uri: materialLotUris }));
        }

        if (combinedPrinterUris.length) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].list({ uri: combinedPrinterUris }));
        }

        if (combinedContainerUris.length) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER]
            .list({ uri: combinedContainerUris }, {}, {}, {}, true))
            .then(containerResponse => {
              const containers = containerResponse.json?.resources;
              if (!_isEmpty(containers)) {
                setContainerActionContainers(containers);
              }
            });
        }

        if (combinedBatchesUris.length) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH]
            .list({ uri: combinedBatchesUris }, {}, {}, {}, true))
            .then(batchResponse => {
              const batches = batchResponse.json?.resources;
              if (!_isEmpty(batches)) {
                setContainerActionBatches(batches);
              }
            });
        }

        if (relocateActions.length) {
          const relocateActionsMetadata = _map(relocateActions, 'metadata');
          const destinationLocationUris = _map(relocateActionsMetadata, 'destination_location');
          const destinationSubLocationUris = _map(relocateActionsMetadata, 'destination_sub_location');

          // Fetch location/sub-locations for relocation batch actions
          dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list({ uri: destinationLocationUris }));
          dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list({ uri: destinationSubLocationUris }));
        }

        if (userUris.length) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ uri: userUris }));
        }

        if (postProcessorUris.length) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].list({ uri: postProcessorUris }));
        }
      });
  };

  const onRelocate = async (locationUri, subLocationUri) => {
    const currentLocation = permanentContainer.location;
    const currentSubLocation = permanentContainer.sub_location;

    // If no change to the location or subLocation => Skip the relocate action
    if (currentLocation === locationUri
      && currentSubLocation === subLocationUri) {
      return;
    }

    const payload = {
      metadata: {
        destination_location: locationUri,
        destination_sub_location: subLocationUri,
      },
      ...(permanentContainer.current_batch
      && { source_batch: permanentContainer.current_batch }),
      action_type: PERMANENT_CONTAINER_ACTION_TYPES.RELOCATE,
      source_material_container: permanentContainer.uri,
    };

    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER_ACTION].post(payload));
    // Re-fetch to update location/sub-location
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].get(permanentContainer.uri));
  };

  const onTareWeightUpdate = async tareWeight => {
    if (!tareWeight) return;
    const currentTareWeight = permanentContainer.tare_weight;

    if (Number(currentTareWeight) === Number(tareWeight)) return;

    const payload = {
      metadata: {
        tare_weight: Number.parseFloat(tareWeight),
      },
      action_type: PERMANENT_CONTAINER_ACTION_TYPES.UPDATE_MODULE_TARE,
      source_material_container: permanentContainer.uri,
    };

    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER_ACTION].post(payload));
    // Re-fetch to update the tare weight
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].get(permanentContainer.uri));
  };

  const onSave = async permanentContainerPayload => {
    const permanentContainerOmittedKeys = ['location', 'sub_location', 'type', 'tare_weight'];

    const payload = {
      ..._omit(permanentContainerPayload, permanentContainerOmittedKeys),
      material_restrictions: permanentContainerPayload?.material_restrictions || [],
    };

    // Relocate action is separate from the permanent container update
    await onRelocate(permanentContainerPayload.location, permanentContainerPayload.sub_location);
    await onTareWeightUpdate(permanentContainerPayload.tare_weight);

    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER]
        .put(extractUuid(permanentContainerPayload.uri), payload))
        .then(() => {
          Alert.success(<FormattedMessage
            id="toaster.permanentContainer.updated"
            defaultMessage="Permanent container successfully updated"
          />);
          setIsEditMode(false);
        });
    } catch {
      Alert.error(<FormattedMessage
        id="toaster.permanentContainer.error_update"
        defaultMessage="Something went wrong while updating permanent container"
      />);
    }
  };

  const handleSetEditMode = () => setIsEditMode(true);

  const onArchivePermanentContainer = async permanentContainer => {
    if (permanentContainer) {
      if (!showArchiveConfirmation && !permanentContainer.archived && !permanentContainer.current_batch) {
        setShowArchiveConfirmation(true);
        return;
      }

      if (permanentContainer.current_batch) {
        Alert.warning(`The container you are trying to Archive currently contains Batch ${getShortUUID(permanentContainer.current_batch)}.
        To complete the Archive, first Unload, Transfer, or Scrap this material batch before retrying. These actions can be accessed by scanning the Container’s QR Code.`);
        return;
      }

      const payload = {
        archived: !permanentContainer.archived,
      };

      await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER]
        .put(extractUuid(permanentContainer.uri), payload))
        .then(() => {
          if (permanentContainer.archived) {
            Alert.success(<FormattedMessage
              id="toaster.permanentContainer.unarchived"
              defaultMessage="Permanent container successfully unarchived"
            />);
          } else {
            Alert.success(<FormattedMessage
              id="toaster.permanentContainer.archived"
              defaultMessage="Permanent container successfully archived"
            />);
          }
          setShowArchiveConfirmation(false);
        });
    }
  };

  const handleAbortArchivePermanentContainer = () => setShowArchiveConfirmation(false);

  useEffect(() => {
    onInitialize(uuid, bureau.uri);
  }, [bureau]);

  useEffect(() => {
    if (permanentContainer?.material_restrictions) {
      setPermanentContainerSelectedMaterials(
        _filter(materials, ({ uri }) => permanentContainer.material_restrictions.includes(uri)));
    }
  }, [permanentContainer?.material_restrictions, materials]);

  useEffect(() => {
    loadContainerTraceabilityReport(permanentContainer?.uri);
  }, [permanentContainer?.uri]);

  const dispatched = {
    onSubmit: onSave,
    onArchivePermanentContainer,
    handleSetEditMode,
    onSelectAllMaterials,
    onDeselectAllMaterials,
    handleAbortArchivePermanentContainer,
  };

  return (
    <PermanentContainerRecord
      {...props}
      {...selected}
      {...dispatched}
    />
  );
};

export default PermanentContainerRecordContainer;

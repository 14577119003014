import { API_RESOURCES } from 'rapidfab/constants';

export default function isIgnoredError(error) {
  if (error.code === 'unauthorized-no-session') {
    // Skip toaster for this error.
    // This error state is handled by router (user is redirected to login page)
    return true;
  }

  if (error.resource === 'user_sso') {
    // Expected to have USER_SSO errors on login
    // if integration is not enabled.
    // It's handled by login form component
    return true;
  }

  if (error.resource === API_RESOURCES.OAUTH_TOKEN_AUTODESK_FUSION_USER && error?.code === 'resource-not-found') {
    // If the user is not logged it to Fusion360, we will have 404 error
    // We do not want to show the Alert in this particular case
    return true;
  }

  return false;
}

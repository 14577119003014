import dayjs from 'dayjs';

export const startAndEndDateValidators = {
  /** Checks start date is same or before of end date. */
  isStartValid: (startDate, endDate) => dayjs(startDate)
    .isSameOrBefore(dayjs(endDate)),
  /** Checks start date is same or before of end date. */
  isEndValid: (endDate, startDate) => dayjs(endDate)
    .isSameOrAfter(dayjs(startDate)),
};

export const startAndEndDatetimeValidators = {
  /** Checks start date-time is same or before end date-time. */
  isTimeValid: (startTime, endTime, startDate, endDate, template) => {
    const startDateTime = dayjs(`${startDate} ${startTime}`);
    const endDateTime = dayjs(`${endDate} ${endTime}`);
    return template === 'start' ? startDateTime.isSameOrBefore(endDateTime) : endDateTime.isSameOrAfter(startDateTime);
  },
};

export const patternValidators = {
  required: /^\s*$/,
};

import BureauBarcodeOutput from 'rapidfab/components/BureauBarcodeOutput';
import { getQRAppUri } from 'rapidfab/utils/uriUtils';
import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import QRSticker from 'rapidfab/components/qr/_wrapper';

const PostProcessor = ({ postProcessor, postProcessorType, location, materials, setPostProcessorTypeModalVisible }) => {
  const postProcessorCustomUri = postProcessorType.is_modular ? `${getQRAppUri(postProcessor.uri)}&isModular=true` : null;
  return (
    <QRSticker>
      <div>Post Processor Type: {postProcessorType.name || 'N/A'}</div>
      <div>Post Processor Name: {postProcessor.name}</div>

      <BureauBarcodeOutput url={postProcessor.uri} customOutputUrl={postProcessorCustomUri} />

      <div>Location: {location.name || 'N/A'}</div>
      <div>
        Material Types:{' '}
        {materials.length ? (
          materials.length > 4 ? (
            <>
              <ul className="non-printable">
                {materials.slice(0, 4).map(material => (
                  <li key={material.uri}>{material.name}</li>
                ))}
                {materials.length > 5 && (
                  <li>
                    Plus {materials.length - 4} more.{' '}
                    <Button variant="link" className="p-a-0" onClick={() => setPostProcessorTypeModalVisible(true)}>
                      View all here
                    </Button>
                  </li>
                )}
              </ul>
              <div className="only-printable">
                {materials.length}
                <br />
                For a full list of material types please navigate to the post processor
                type page in Flows
              </div>
            </>
          ) : (
            <div>
              {materials.map(material => (
                <p key={material.uri}>{material.name}</p>
              ))}
            </div>
          )
        ) : (
          'N/A'
        )}
      </div>
    </QRSticker>
  );
};

PostProcessor.propTypes = {
  postProcessor: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  postProcessorType: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
    is_modular: PropTypes.bool,
  }).isRequired,
  materials: PropTypes.arrayOf({
    name: PropTypes.string,
  }).isRequired,
  setPostProcessorTypeModalVisible: PropTypes.func.isRequired,
};

export default PostProcessor;

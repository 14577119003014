import React, { useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Actions from 'rapidfab/actions';
import Loading from 'rapidfab/components/Loading';
import { getShipmentsForRun, getUUIDResource } from 'rapidfab/selectors';
import { API_RESOURCES } from 'rapidfab/constants';
import RunShipment from 'rapidfab/components/records/run/RunShipment';

const RunShipmentContainer = props => {
  const { runUri } = props;
  const runUuid = extractUuid(runUri);
  const run = useSelector(state => getUUIDResource(state, runUuid));
  const shipment = useSelector(state => getShipmentsForRun(state, run));

  const { fetching } = useSelector(state => state.ui.nautilus[API_RESOURCES.SHIPMENT].list);

  const dispatch = useDispatch();
  /* eslint-disable no-shadow */
  const onInitialize = runUri =>
    dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPMENT]
      .list({ run: runUri }));

  useEffect(() => {
    onInitialize(runUri);
  }, [runUri]);

  if (fetching) {
    return <Loading />;
  }

  return <RunShipment shipment={shipment[0]} run={run} />;
};

RunShipmentContainer.propTypes = {
  runUri: PropTypes.string.isRequired,
};

export default memo(RunShipmentContainer);

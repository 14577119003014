import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  FEATURES,
  COMMENT_CHARACTER_LIMIT,
} from 'rapidfab/constants';
import { FormattedMessage, FormattedMessageMappingOption } from 'rapidfab/i18n';
import {
  FormControlTextArea,
  FormControlTextCareful,
} from 'rapidfab/components/formTools';

import {
  IP_SENSITIVITY_MAP,
} from 'rapidfab/mappings';

import VisibleFor from 'rapidfab/components/VisibleFor';
import Feature from 'rapidfab/components/Feature';
import FormRow from 'rapidfab/components/FormRow';
import OrderPrioritySelector from 'rapidfab/components/records/order/OrderPrioritySelector';
import WholeOrderShipmentCheckbox from 'rapidfab/components/records/order/WholeOrderShipmentCheckbox';
import UserOption from 'rapidfab/components/UserOption';
import _find from 'lodash/find';
import CustomFieldList from 'rapidfab/components/forms/CustomFieldList';
import FormFieldMessage from 'rapidfab/components/forms/FormFieldMessage';
import getOrderFormOptions, { ORDER_FORM_TYPES } from 'rapidfab/utils/orderFormOptions';
import getorderCustomFieldReferencesOverrides from 'rapidfab/utils/orderCustomFieldReferencesOverrides';
import getVisibleCustomFieldReferencesWithOptions from 'rapidfab/utils/getVisibleCustomFieldReferencesWithOptions';
import { patternValidators } from 'rapidfab/utils/formValidators';

import { FormSpy, Field } from 'react-final-form';
import SelectSingleLazy from 'rapidfab/components/forms/SelectSingleLazy';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { DateInput } from 'rapidfab/components/forms/DateInput';

class NewOrderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: this.props.initialFormValues,
    };

    this.handleBlurShippingField = this.handleBlurShippingField.bind(this);
  }

  componentDidMount() {
    if (this.props.customOrderFieldReferences.length) {
      this.props.initCustomFieldValues('custom_field_values');
    }
  }

  componentDidUpdate(prevProps) {
    const { customOrderFieldReferences } = this.props;
    if (
      prevProps.customOrderFieldReferences.length !== customOrderFieldReferences.length
    ) {
      this.props.initCustomFieldValues('custom_field_values');
    }
  }

  handleBlurShippingField() {
    const {
      shipping,
      customer_name: customerName,
      customer_email: customerEmail,
    } = this.state.form.values;
    try {
      const { submittedOrders } = this.props;
      const shippingName = shipping.name.value.trim();
      const matchedOrder = _find(submittedOrders, order =>
        order.shipping.name.toLowerCase() === shippingName.toLowerCase());

      if (matchedOrder) {
        shipping.address.onChange(matchedOrder.shipping.address);
        customerName.onChange(matchedOrder.customer_name);
        customerEmail.onChange(matchedOrder.customer_email);
      }
    } catch {
      // empty
    }
  }

  render() {
    const {
      shippings,
      users,
      locationsAvailable,
      isUserRestricted,
      isOrderBusinessSegmentFeatureEnabled,
      customOrderFieldReferences,
      isBoeingOrderFieldsFeatureEnabled,
      isBeehiveOrderFieldsFeatureEnabled,
      isXerox3DPFieldsFeatureEnabled,
      is3MOrderFieldsFeatureEnabled,
      initialFormValues,
      isDigitalDesignWarehouseFeatureEnabled,
      isEatonOrderFieldsEnabled,
    } = this.props;

    const isEatonUser = isEatonOrderFieldsEnabled;
    const isNormalUser = !isUserRestricted;
    const isDanfossUser = isOrderBusinessSegmentFeatureEnabled;
    const isBoeingUser = isBoeingOrderFieldsFeatureEnabled;
    const is3MUser = is3MOrderFieldsFeatureEnabled;
    const isBeehiveUser = isBeehiveOrderFieldsFeatureEnabled;
    const isXerox3DPUser = isXerox3DPFieldsFeatureEnabled;

    const options = getOrderFormOptions({
      isNormalUser,
      isDanfossUser,
      isBoeingUser,
      is3MUser,
      isBeehiveUser,
      isXerox3DPUser,
      isEatonUser,
      isDigitalDesignWarehouseFeatureEnabled,
    }, ORDER_FORM_TYPES.CREATE);

    const fieldOverrides = getorderCustomFieldReferencesOverrides({
      isNormalUser,
      isDanfossUser,
      isBoeingUser,
      is3MUser,
    });

    const visibleOrderCustomFieldReferencesWithOptions = getVisibleCustomFieldReferencesWithOptions({
      fieldReferences: customOrderFieldReferences,
      fieldValues: this.state.form?.values?.custom_field_values,
      fieldOverrides,
    });

    const exceededInitialCommentLimit =
      this.state.form?.values?.initial_comment?.length > COMMENT_CHARACTER_LIMIT;

    const customerFields = (
      <span>
        {options.customerEmail.show && (
          <FormRow
            id="field.customer_email"
            defaultMessage="Customer Email"
            isRequired={options.customerEmail.required}
            data-cy="order-form-customer-email-text-field"
          >
            <Field
              name="customer_email"
              initialValue={initialFormValues?.customer_email}
              render={props => {
                const field = { ...props.input, ...props.meta };
                return (
                  <FormControlTextCareful
                    {...field}
                    inputRef={props.ref}
                    required={options.customerEmail.required}
                  />
                );
              }}
            />
          </FormRow>
        )}

        {options.customerName.show && (
          <FormRow
            id={options.customerName.id}
            defaultMessage={options.customerName.defaultMessage}
            isRequired={options.customerName.required}
            data-cy="order-form-customer-name-text-field"
          >
            <Field
              name="customer_name"
              initialValue={initialFormValues?.customer_name}
              render={props => {
                const field = { ...props.input, ...props.meta };
                return (
                  <FormControlTextCareful
                    {...field}
                    inputRef={props.ref}
                    required={options.customerName.required}
                  />
                );
              }}
            />
          </FormRow>
        )}
      </span>
    );

    return (
      <div>
        <FormRow
          id="orderName"
          defaultMessage="Order Name"
          isRequired
          data-cy="order-form-order-name-text-field"
        >
          <Field
            name="name"
            initialValue={initialFormValues?.name}
            validate={value => patternValidators.required.test(value)}
            render={props => (
              <>
                <FormControl
                  value={props.input.value}
                  onChange={props.input.onChange}
                  type="text"
                  required
                />
                {
                  (props.meta.error && props.meta.touched)
                  && FormFieldMessage(
                    'order.error.requiredField',
                    'A required field must not be empty or contain only empty spaces.',
                    'error',
                  )
                }
              </>
            )}
          />
        </FormRow>

        {options.priority.show && (
          <Feature featureNames={[FEATURES.PRIORITY, FEATURES.THREE_M_MAIN_BUREAU_ORDER_FIELDS]}>
            <div data-cy="order-form-priority-dropdown-field">
              <Field
                name="priority"
                initialValue={initialFormValues?.priority}
                render={props => (
                  <OrderPrioritySelector
                    value={props.input.value}
                    onChange={props.input.onChange}
                    options={options.priority}
                  />
                )}
              />
            </div>
          </Feature>
        )}

        {options.orderOwner.show && (
          <Feature featureName={FEATURES.ORDER_ORDER_OWNER}>
            <FormRow
              id="field.owner"
              defaultMessage="Owner"
              isRequired={options.orderOwner.required}
              data-cy="order-form-owner-name-dropdown-field"
            >
              <Field
                name="order_owner"
                initialValue={initialFormValues?.order_owner}
                render={props => (
                  <SelectSingleLazy
                    name={props.input.name}
                    dataTestId="order_owner_new"
                    data={users}
                    labelKey="username"
                    value={props.input.value}
                    handleOnChange={props.input.onChange}
                    imitateOnChangeEvent
                    required={options.orderOwner.required}
                    disabled={isUserRestricted}
                    renderLabelCallback={UserOption.generateLabel}
                    onFetchMore={this.props.shouldFetchMoreUsers ? this.props.fetchAllUsers : null}
                  />
                )}
              />
            </FormRow>
          </Feature>
        )}
        {options.shippingName.show && (
          <FormRow
            id={options.shippingName.id}
            defaultMessage={options.shippingName.defaultMessage}
            isRequired={options.shippingName.required}
            data-cy="order-form-shipping-name-text-field"
          >
            <Field
              name="shipping.name"
              validate={value => options.shippingName.required && patternValidators.required.test(value)}
              initialValue={initialFormValues?.shipping?.name}
              render={props => {
                const field = { ...props.input, ...props.meta };
                return (
                  <>
                    <FormControlTextCareful
                      {...field}
                      inputRef={props.ref}
                      required={options.shippingName.required}
                      onBlur={this.handleBlurShippingField}
                    />
                    {
                      (props.meta.error && props.meta.touched)
                      && FormFieldMessage(
                        'order.error.requiredField',
                        'A required field must not be empty or contain only empty spaces.',
                        'error',
                      )
                    }
                  </>
                );
              }}
            />
          </FormRow>
        )}

        {options.shippingAddress.show && (
          <FormRow
            id={options.shippingAddress.id}
            defaultMessage={options.shippingAddress.defaultMessage}
            isRequired={options.shippingAddress.required}
            data-cy="order-form-shipping-name-text-field"
          >
            <Field
              name="shipping.address"
              initialValue={initialFormValues?.shipping?.address}
              validate={value => options.shippingAddress.required && patternValidators.required.test(value)}
              render={props => (
                <>
                  <FormControl
                    {...props.input}
                    as="textarea"
                    required={options.shippingAddress.required}
                    className="resize-vertical"
                    onBlur={this.handleBlurShippingField}
                  />
                  {
                    (props.meta.error && props.meta.touched)
                    && FormFieldMessage(
                      'order.error.requiredField',
                      'A required field must not be empty or contain only empty spaces.',
                      'error',
                    )
                  }
                </>
              )}
            />
          </FormRow>
        )}

        {options.trackingNumber.show && (
          <FormRow
            id="field.trackingNumber"
            data-cy="order-form-tracking-number-text-field"
            defaultMessage="Shipping/Tracking #"
            isRequired={options.trackingNumber.required}
          >
            <Field
              name="shipping.tracking"
              initialValue={initialFormValues?.shipping?.tracking}
              render={props => {
                const field = { ...props.input, ...props.meta };
                return (
                  <FormControlTextCareful
                    {...field}
                    inputRef={props.ref}
                    required={options.trackingNumber.required}
                  />
                );
              }}
            />
          </FormRow>
        )}

        {options.shippingType.show && (
          <FormRow
            id="field.shippingType"
            defaultMessage="Shipping Type"
            data-cy="order-form-shipping-type-dropdown-field"
            isRequired={options.shippingType.required}
          >
            <Field
              name="shipping.uri"
              initialValue={initialFormValues?.shipping?.uri}
              render={props => (
                <FormControl
                  value={props.input.value}
                  onChange={props.input.onChange}
                  as="select"
                  required={options.shippingType.required}
                >
                  {shippings.map(shipping => (
                    <option key={shipping.uri} value={shipping.uri}>
                      {shipping.name}
                    </option>
                  ))}
                </FormControl>
              )}
            />
          </FormRow>
        )}

        <Field
          name="shipping_grouping"
          initialValue={initialFormValues?.shipping_grouping}
          render={props => (
            <VisibleFor unrestricted>
              {initialFormValues?.shipping?.uri && (
                <Feature featureName={FEATURES.SHIPMENT_FOR_ORDER}>
                  <WholeOrderShipmentCheckbox field={props.input} />
                </Feature>
              )}
            </VisibleFor>
          )}
        />

        {customerFields}

        {options.location.show && (
          <FormRow
            id="field.location"
            defaultMessage="Location"
            data-cy="order-form-order-location-dropdown-field"
          >
            <Field
              name="location"
              initialValue={initialFormValues?.location}
              render={props => (
                <FormControl
                  as="select"
                  {...props.input}
                >
                  <FormattedMessage id="field.any" defaultMessage="Any">{text =>
                    <option value="">{text}</option>}
                  </FormattedMessage>
                  {locationsAvailable.map(location => location && (
                    <option key={location.uri} value={location.uri}>
                      {location.name}
                    </option>
                  ))}
                </FormControl>
              )}
            />
          </FormRow>
        )}

        <Feature featureName={FEATURES.IP_SENSITIVITY}>
          <FormRow
            id="field.ipSensitivity"
            defaultMessage="IP Sensitivity"
            isRequired={options.ipSensitivity.required}
            data-cy="order-form-ip-sensitivity-dropdown-field"
          >
            <Field
              name="ip_sensitivity"
              initialValue={initialFormValues?.ip_sensitivity}
              render={props => (
                <FormControl
                  value={props.input.value}
                  onChange={props.input.onChange}
                  required={options.ipSensitivity.required}
                  as="select"
                >
                  {options.ipSensitivity.required ? (
                    <FormattedMessage id="field.choose" defaultMessage="Choose…">{text =>
                      <option value="" disabled>{text}</option>}
                    </FormattedMessage>
                  ) : (
                    <FormattedMessage id="field.none" defaultMessage="None">{text =>
                      <option value="none">{text}</option>}
                    </FormattedMessage>
                  )}
                  {Object.keys(IP_SENSITIVITY_MAP).map(ip => (
                    <FormattedMessageMappingOption
                      mapping={IP_SENSITIVITY_MAP}
                      value={ip}
                    />
                  ))}
                </FormControl>
              )}
            />
          </FormRow>
        </Feature>

        <Feature featureName={FEATURES.ORDER_DUE_DATE}>
          {options.dueDate.show && (
            <FormRow
              id="field.dueDate"
              defaultMessage="Due Date"
              isRequired={options.dueDate.required}
              data-cy="order-form-due-date-calendar-field"
            >
              <Field
                name="due_date"
                initialValue={initialFormValues?.due_date}
                render={props => (
                  <DateInput
                    value={props.input.value}
                    onChange={props.input.onChange}
                    required={options.dueDate.required}
                    min={options.dueDate.min}
                  />
                )}
              />
            </FormRow>
          )}
        </Feature>

        {isDanfossUser && (
          <FormRow
            isRequired
            id="field.initialComment"
            defaultMessage="Initial Comment"
            data-cy="order-form-danfoss-initial-comment-text-field"
          >
            <Field
              name="initial_comment"
              initialValue={initialFormValues?.initial_comment}
              validate={value => patternValidators.required.test(value)}
              render={props => (
                <>
                  <div className="d-flex align-items-center">
                    <FormControlTextArea
                      {...props.input}
                      required
                      placeholder="Please describe here the usage of the 3D printed parts and the
                    relevant requirements such as the desired mechanical, thermal,
                    chemical and surface properties as well as required tolerances.
                    This will help the ADM team to select a suitable printing
                    processes and materials for your request."
                    />
                    <OverlayTrigger
                      placement="left"
                      overlay={(
                        <Tooltip>
                          The input here will be viewable in the order comment section.
                        </Tooltip>
                      )}
                    >
                      <FontAwesomeIcon icon={faQuestionCircle} className="d-flex ml30" />
                    </OverlayTrigger>
                  </div>
                  {
                    (props.meta.error && props.meta.touched)
                    && FormFieldMessage(
                      'order.error.requiredField',
                      'A required field must not be empty or contain only empty spaces.',
                      'error',
                    )
                  }
                  <div className={`initial-comment-limit ${exceededInitialCommentLimit ? 'char-count-err' : ''}`}>
                    {this.state.form?.values?.initial_comment?.length || 0} / {COMMENT_CHARACTER_LIMIT}
                  </div>
                </>
              )}
            />
          </FormRow>
        )}

        <CustomFieldList
          customFieldReferences={visibleOrderCustomFieldReferencesWithOptions}
          customFieldValues={this.props.formValues?.custom_field_values || []}
          onChange={this.props.onCustomFieldChange}
        />
        <FormSpy subscription={{ values: true }} onChange={values => this.setState({ form: values })} />
      </div>
    );
  }
}

NewOrderForm.defaultProps = {
  shouldFetchMoreUsers: false,
};

NewOrderForm.propTypes = {
  initialFormValues: PropTypes.shape({
    custom_field_values: PropTypes.arrayOf(PropTypes.shape({})),
    customer_name: PropTypes.string.isRequired,
    customer_email: PropTypes.string.isRequired,
    shipping: PropTypes.shape({
      name: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      tracking: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
    initial_comment: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    priority: PropTypes.string.isRequired,
    order_owner: PropTypes.string.isRequired,
    customer_po: PropTypes.string.isRequired,
    quote_number: PropTypes.string.isRequired,
    ip_sensitivity: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    order_type: PropTypes.string.isRequired,
    due_date: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    shipping_grouping: PropTypes.string.isRequired,
  }).isRequired,
  locationsAvailable: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isUserRestricted: PropTypes.bool.isRequired,
  isEatonOrderFieldsEnabled: PropTypes.bool.isRequired,
  isOrderBusinessSegmentFeatureEnabled: PropTypes.bool.isRequired,
  isBoeingOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  isBeehiveOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  isXerox3DPFieldsFeatureEnabled: PropTypes.bool.isRequired,
  is3MOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  shippings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customOrderFieldReferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  submittedOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCustomFieldChange: PropTypes.func.isRequired,
  initCustomFieldValues: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    custom_field_values: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  fetchedUsers: PropTypes.bool.isRequired,
  fetchAllUsers: PropTypes.func.isRequired,
  isDigitalDesignWarehouseFeatureEnabled: PropTypes.bool.isRequired,
  shouldFetchMoreUsers: PropTypes.bool,
};

export default NewOrderForm;

import _omit from 'lodash/omit';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import PermanentContainerRecordForm from 'rapidfab/components/inventory/PermanentContainerRecordForm';
import Alert from 'rapidfab/utils/alert';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { useEffect, useState } from 'react';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES, PERMANENT_CONTAINER_ACTION_TYPES, ROUTES } from 'rapidfab/constants';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import {
  getBureauUri,
  getLocationsByRole,
  getMaterials,
  getSubLocations, getSubLocationsForLocation,
} from 'rapidfab/selectors';
import { useNavigate } from 'react-router-dom';

const PermanentContainerNewContainer = () => {
  const materials = useSelector(getMaterials);
  const locations = useSelector(getLocationsByRole);
  const subLocations = useSelector(getSubLocations);

  const [location, setLocation] = useState();

  const subLocationsForLocation = useSelector(state =>
    (location ? getSubLocationsForLocation(state, location) : []));

  const bureau = useSelector(getBureauUri);
  const isSubmitting = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].post.fetching);
  const subLocationsFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.SUB_LOCATION].list.fetching);
  const materialResourcesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].list.fetching);
  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);
  const usersByUri = useSelector(Selectors.getUsersByUri);

  const navigate = useNavigate();

  const initialFormValues = {};

  const [permanentContainerSelectedMaterials, setPermanentContainerSelectedMaterials] = useState([]);

  const [isAllMaterialsButtonSelected, setIsAllMaterialsButtonSelected] = useState(
    !!initialFormValues?.material_restrictions?.length
      && (initialFormValues?.material_restrictions?.length === materials?.length));

  const onSelectAllMaterials = (args, state) => {
    setPermanentContainerSelectedMaterials(materials);
    return state?.fields.material_restrictions.change(materials);
  };

  const onDeselectAllMaterials = (args, state) => {
    setPermanentContainerSelectedMaterials([]);
    return state?.fields.material_restrictions.change([]);
  };

  const onLocationChange = newLocation => setLocation(newLocation);

  const selected = {
    initialFormValues,
    isSubmitting,
    materialData: {
      isAllMaterialsButtonSelected,
      setIsAllMaterialsButtonSelected,
      permanentContainerSelectedMaterials,
      materialResourcesFetching,
      materials,
      setPermanentContainerSelectedMaterials,
    },
    locationData: {
      locations,
      subLocations,
      subLocationsForLocation,
      subLocationsFetching,
    },
    isDebugModeEnabled,
    usersByUri,
  };

  const dispatch = useDispatch();

  const onInitialize = currentBureau => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: currentBureau }, {}, {}, {}, true));
    dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list());
  };

  const onTareWeightCreate = async (tareWeight, containerUri) => {
    if (!tareWeight) return;

    const payload = {
      metadata: {
        tare_weight: Number.parseFloat(tareWeight),
      },
      action_type: PERMANENT_CONTAINER_ACTION_TYPES.UPDATE_MODULE_TARE,
      source_material_container: containerUri,
    };

    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER_ACTION].post(payload));
    // Re-fetch to update the tare weight
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].get(containerUri));
  };

  const onSave = async newPermanentContainerPayload => {
    const permanentContainerOmittedKeys = ['tare_weight'];

    const payload = {
      ..._omit(newPermanentContainerPayload, permanentContainerOmittedKeys),
      material_restrictions: newPermanentContainerPayload?.material_restrictions || [],
    };

    const permanentContainerResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER]
      .post(payload));

    const permanentContainerUri = permanentContainerResponse?.headers?.location;
    await onTareWeightCreate(newPermanentContainerPayload.tare_weight, permanentContainerUri);

    if (permanentContainerUri) {
      Alert.success(<FormattedMessage
        id="toaster.permanentContainer.created"
        defaultMessage="Permanent container successfully created"
      />);

      return navigate(getRouteURI(ROUTES.PERMANENT_CONTAINER,
        { uuid: extractUuid(permanentContainerUri) },
        {}, true));
    }

    return Alert.error(<FormattedMessage
      id="toaster.permanentContainer.error_create"
      defaultMessage="Something went wrong while creating permanent container. Please try again."
    />);
  };

  useEffect(() => {
    onInitialize(bureau);
  }, [bureau]);

  const dispatched = {
    onSubmit: onSave,
    onSelectAllMaterials,
    onDeselectAllMaterials,
    onLocationChange,
  };

  return (
    <Container fluid>
      <BreadcrumbNav
        breadcrumbs={['inventory', 'permanentContainers', 'New']}
      />

      <div className="page-header d-flex align-items-center">
        <h3 className="m-a-0">{initialFormValues?.name ?
          `Permanent Container: ${initialFormValues.name}` : (
            <FormattedMessage
              id="field.permanentContainer.new"
              defaultMessage="New Permanent Container"
            />
          )}
        </h3>
      </div>

      <Row>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <PermanentContainerRecordForm
            {...selected}
            {...dispatched}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PermanentContainerNewContainer;

import { MANUFACTURER_FIELDS } from 'rapidfab/constants';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Container,
  ButtonToolbar,
  SplitButton,
  Dropdown,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Form, Field } from 'react-final-form';
import FormGroupField from 'rapidfab/components/forms/FormGroupField';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import Loading from 'rapidfab/components/Loading';
import _pick from 'lodash/pick';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { patternValidators } from 'rapidfab/utils/formValidators';

const Manufacturer = ({ onSubmit, onDelete, manufacturer, isSubmitting }) => {
  const {
    name,
    contact_name,
    contact_phone,
    support_name,
    support_phone,
    address,
    notes,
  } = MANUFACTURER_FIELDS;

  const formInitialValues = manufacturer && _pick(manufacturer, Object.values(MANUFACTURER_FIELDS));
  return (
    <Form
      initialValues={formInitialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Container fluid>
            <BreadcrumbNav
              breadcrumbs={['organize', 'manufacturers', manufacturer ? `${manufacturer.name} (${getShortUUID(manufacturer.uri)})` : 'New']}
            />

            <div className="clearfix">
              <ButtonToolbar className="pull-right">
                <SplitButton
                  id="uxSaveDropdown"
                  type="submit"
                  variant="success"
                  size="sm"
                  disabled={isSubmitting}
                  title={(
                    <>
                      {isSubmitting && <Loading inline className="spacer-right" />}
                      <SaveButtonTitle />
                    </>
                  )}
                  pullRight
                >
                  <Dropdown.Item
                    eventKey={1}
                    onClick={() => onDelete(manufacturer.uuid)}
                    disabled={!manufacturer}
                  >
                    <FontAwesomeIcon icon={faBan} />{' '}
                    <FormattedMessage id="button.delete" defaultMessage="Delete" />
                  </Dropdown.Item>
                </SplitButton>
              </ButtonToolbar>
            </div>

            <hr />

            <Row>
              <Col xs={12}>
                <Field
                  name={name}
                  type="text"
                  validate={value => patternValidators.required.test(value)}
                  render={props => (
                    <FormGroupField
                      {...props.input}
                      required
                      name={name}
                      shouldShowValidationMessage={props.meta.touched && props.meta.error}
                      validationMessage="A required field must not be empty or contain only empty spaces."
                    />
                  )}
                />
                <FormGroupField name={contact_name} label="Commercial Contact" />
                <FormGroupField name={contact_phone} label="Commercial Phone" type="tel" pattern="[0-9\(\)\-]+" />
                <FormGroupField name={support_name} label="Support Contact" />
                <FormGroupField name={support_phone} label="Support Phone" type="tel" pattern="[0-9\(\)\-]+" />
                <FormGroupField name={address} as="textarea" />
                <FormGroupField name={notes} as="textarea" />
              </Col>
            </Row>
          </Container>
        </form>
      )}
    </Form>
  );
};

Manufacturer.propTypes = {
  manufacturer: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  input: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.bool,
    touched: PropTypes.bool,
  }).isRequired,
};

Manufacturer.defaultProps = {
  manufacturer: null,
};

export default Manufacturer;

import { faCheckCircle, faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import FusionButtonLink from 'rapidfab/components/autodesk-fusion/FusionButtonLink';
import Loading from 'rapidfab/components/Loading';
import { API_RESOURCES, AUTODESK_FUSION_MODEL_STATUSES } from 'rapidfab/constants';
import { getFusionModels } from 'rapidfab/selectors';
import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

const AutodeskFusionPanel = ({ model }) => {
  const fusionModel = useSelector(state => getFusionModels(state)[0]);
  const fusionModelFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.FUSION_MODEL].list.fetching);

  const dispatch = useDispatch();

  const onInitialize = () => {
    const modelUri = model?.uri;
    if (!modelUri) return;

    dispatch(Actions.Api.nautilus[API_RESOURCES.FUSION_MODEL].clear('list'));
    dispatch(Actions.Api.nautilus[API_RESOURCES.FUSION_MODEL].list({
      model: modelUri,
    }));
  };

  useEffect(() => onInitialize(), []);

  const processingContent = {
    content: <span>Processing</span>,
    disabled: true,
    hidden: true,
  };

  const statusContent = {
    [AUTODESK_FUSION_MODEL_STATUSES.ready]: {
      content: (
        <p>
          <FontAwesomeIcon icon={faCheckCircle} color="#1ac98e" className="spacer-right" />
          Ready
        </p>
      ),
    },
    [AUTODESK_FUSION_MODEL_STATUSES.processing]: {
      content: (
        <p className="mb0">
          <Loading className="spacer-right" inline />
          Model is processing
        </p>
      ),
    },
    [AUTODESK_FUSION_MODEL_STATUSES.error]: {
      content: (
        <p>
          <FontAwesomeIcon icon={faExclamationCircle} color="#dc3545" className="spacer-right" />
          An issue occurred during model processing.
        </p>
      ),
    },
    modelNotUploaded: (
      <p>
        <FontAwesomeIcon icon={faInfoCircle} className="spacer-right" />
        Model is not uploaded to Autodesk Fusion.
      </p>
    ),
  };

  const fusionLinkStateByStatus = {
    [AUTODESK_FUSION_MODEL_STATUSES.processing]: processingContent,
    [AUTODESK_FUSION_MODEL_STATUSES.created]: processingContent,
    [AUTODESK_FUSION_MODEL_STATUSES.ready]: {
      content: 'View in Fusion',
      href: fusionModel?.fusion_uri,
      newTab: true,
    },
    [AUTODESK_FUSION_MODEL_STATUSES.error]: {
      content: 'View in Fusion',
    },
  };

  const linkState = fusionLinkStateByStatus[fusionModel?.status] || {};

  const renderStatusContent = () => {
    if (!fusionModel) return statusContent.modelNotUploaded;
    return statusContent[fusionModel?.status]?.content || null;
  };

  return (
    <Card className={`m-b ${fusionModelFetching ? '' : 'fusion-card'}`} bg="light">
      <Card.Header className="pd-exp">
        <div className="d-flex justify-content-between align-items-center">
          Autodesk Fusion
        </div>
      </Card.Header>
      <Card.Body>
        {fusionModelFetching ? <Loading /> : (
          <>
            {renderStatusContent()}
            <FusionButtonLink {...linkState} />
          </>
        )}
      </Card.Body>
    </Card>
  );
};

AutodeskFusionPanel.propTypes = {
  model: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
};

export default AutodeskFusionPanel;

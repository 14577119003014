import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import {
  createRunProgressClear, createRunProgressError,
  createRunRestartStartProgress, createRunStartProgress, createRunSuccess, createRunUpdateProcessing,
} from 'rapidfab/actions/createRunProgress';
import CreateNewRunProgressModal from 'rapidfab/components/modals/CreateNewRunProgressModal';
import {
  API_RESOURCES,
  CREATE_RUN_PROGRESS_STATUSES,
  ROUTES, RUN_CREATION_BACKGROUND_REFRESH_TIMEOUT, RUN_CREATION_REFRESH_COMPLETE_TEXT_TIMEOUT,
  RUN_CREATION_REFRESH_SUGGESTION_TIMEOUT,
} from 'rapidfab/constants';
import { getCreateRunProgress } from 'rapidfab/selectors';
import Alert from 'rapidfab/utils/alert';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CreateNewRunProgressModalContainer = ({
  cleanUpContextResources,
  selectedPrinter,
  runCreationPayload,
  setCreateRunUri,
  handleResetBuildPlate,
}) => {
  const createRunProgress = useSelector(getCreateRunProgress);
  // As we have only single-run progress tracking for Stage 1 - show only the first item in the queue
  const currentRunProgress = createRunProgress.queue?.[0];
  // When the Run is created, we get the complete details with the mainRun URI
  const completeDetails = currentRunProgress?.completeDetails || {};
  // Get the mainRun URI to redirect to the Edit Run Page
  const primaryRunUri = completeDetails?.mainRun;

  // Show the Loader when we create "Try Again" (re-create the run again)
  const createNewRunFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.CREATE_RUNS].post.fetching);

  // Show the spinner when the user clicked on "Refresh" btn
  const refreshingRunProgress = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.CREATE_RUNS].get.fetching);

  // The countdown to redirect to the Edit Run Page automatically
  const [redirectCountdown, setRedirectCountdown] = useState(25);
  const [confirmCloseModal, setConfirmCloseModal] = useState(false);

  const [showRefreshDataInfo, setShowRefreshDataInfo] = useState(false);
  // Flag to check if we should check the run progress in the background
  const [shouldCheckRunProgressInBackground, setShouldCheckRunProgressInBackground] = useState(false);
  const [refreshDataComplete, setRefreshDataComplete] = useState(false);
  const [tryAgainAttempt, setTryAgainAttempt] = useState(0);

  const [isRetryingRun, setIsRetryingRun] = useState(false);
  const [isContinuingRun, setIsContinuingRun] = useState(false);

  const refreshDataTimerRef = useRef(null);
  const backgroundRunProgressCheckTimerRef = useRef(null);
  const intervalRef = useRef(null);

  const isRelevantStatusForRefreshingData = [
    CREATE_RUN_PROGRESS_STATUSES.PENDING,
    CREATE_RUN_PROGRESS_STATUSES.PROCESSING].includes(currentRunProgress?.status);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Close the Modal Window and remove the current run from the progress queue
  const clearModalWindow = () => {
    setTryAgainAttempt(0);
    return dispatch(createRunProgressClear());
  };

  // Handle the cases for if the user is trying again or continue the run (re-create the run)
  const handleRunRecreate = useCallback(async ({ shouldRetry, shouldContinue }) => {
    // If we don't have the runCreationPayload - show the warning message
    if (!runCreationPayload) {
      return Alert.warning("We're unable to attempt another run creation. Please click on \"Edit Runs\" and then \"Save Run\" to try again.");
    }

    let payload = runCreationPayload;

    if (shouldContinue) {
      setIsContinuingRun(true);
      payload = {
        ...runCreationPayload,
        do_build_volume_check: false,
      };
    }

    if (shouldRetry) { setIsRetryingRun(true); }

    const createRunResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.CREATE_RUNS]
      .post(payload));
    // Get the updated createRunUri
    const createRunUri = createRunResponse.headers.location;
    // Get the old run progress ID to update the details
    const oldId = currentRunProgress?.id;
    setCreateRunUri(createRunUri);

    if (shouldRetry) {
      setTryAgainAttempt(attempt => attempt + 1);
    }

    // Restart the current run progress, update the current run in the queue with the new details
    return dispatch(createRunRestartStartProgress(oldId, extractUuid(createRunUri), createRunUri));
  }, [dispatch, runCreationPayload, currentRunProgress, setCreateRunUri]);

  const handleRefreshCurrentRunProgressQueue = useCallback(createRunData => {
    const { status, finished, primary_run, resulting_runs, notes, unfitted_pieces, uri } = createRunData ?? {};

    const id = extractUuid(uri);
    const runDetails = { finished, primary_run, resulting_runs };
    const errorDetails = { finished, notes, unfittedPiecesLength: unfitted_pieces?.length };

    // Update the current run progress in the queue if the status got changed and not updated on the background
    const statusActions = {
      [CREATE_RUN_PROGRESS_STATUSES.PENDING]: () => dispatch(createRunStartProgress(id, uri)),
      [CREATE_RUN_PROGRESS_STATUSES.PROCESSING]: () => dispatch(createRunUpdateProcessing(id)),
      [CREATE_RUN_PROGRESS_STATUSES.COMPLETE]: () => dispatch(createRunSuccess(id, runDetails)),
      [CREATE_RUN_PROGRESS_STATUSES.ERROR]: () => dispatch(createRunProgressError(id, errorDetails)),
    };

    // Execute the function based on the current status if applicable
    statusActions[status]?.();
  }, []);

  // Function to handle setting the refresh timer
  const handleSetRefreshTimer = () => {
    clearTimeout(refreshDataTimerRef.current);
    refreshDataTimerRef.current = setTimeout(() => {
      setShowRefreshDataInfo(true);
    }, RUN_CREATION_REFRESH_SUGGESTION_TIMEOUT);
  };

  // Timer to handle the background check for the run progress
  const handleSetBackgroundRefreshTimer = () => {
    clearTimeout(backgroundRunProgressCheckTimerRef.current);
    backgroundRunProgressCheckTimerRef.current = setTimeout(() => {
      setShouldCheckRunProgressInBackground(true);
    }, RUN_CREATION_BACKGROUND_REFRESH_TIMEOUT);
  };

  // If the user clicked on "Refresh" btn - update the current run progress queue
  // Or if the background check was triggered - refresh the data
  const handleRefreshDataForRunCreation = useCallback(async (backgroundRefreshCheck = false) => {
    // If we don't have the runCreationPayload - show the warning message
    if (!currentRunProgress?.createRunUri) {
      return Alert.warning('Sorry, there was an issue updating the data. Please try refreshing again.');
    }

    try {
      const { json } = await dispatch(Actions.Api.nautilus[API_RESOURCES.CREATE_RUNS]
        .get(extractUuid(currentRunProgress.createRunUri)));

      if (_isEmpty(json)) return null;

      const { status } = json;

      // No changes to the current status, no need to update the queue
      if (status === currentRunProgress.status) {
        setShowRefreshDataInfo(false); // Hide the refresh data info
        setRefreshDataComplete(true); // Show the "Everything is up to date" message
        const refreshDataTimer = setTimeout(() => {
          setRefreshDataComplete(false); // Automatically hide message after 3 seconds
        }, RUN_CREATION_REFRESH_COMPLETE_TEXT_TIMEOUT);

        // Cleanup function to clear the timeout
        return () => clearTimeout(refreshDataTimer);
      }

      // Refresh the current run progress queue state if the status got changed
      return handleRefreshCurrentRunProgressQueue(json);
    } catch (error) {
      console.error('Error refreshing data for run creation:', error);
      return Alert.error(error.message);
    } finally {
      if (!backgroundRefreshCheck) {
        // Reset the information display timer directly here
        setShowRefreshDataInfo(false);
        // If the status was not changed, set the timer to get the Refresh Data info again
        handleSetRefreshTimer();
      } else {
        // Reset the background check after the data was refreshed
        setShouldCheckRunProgressInBackground(false);
        // Restart the timer if we still need to check the progress in the background
        handleSetBackgroundRefreshTimer();
      }
    }
  }, [currentRunProgress, dispatch, handleRefreshCurrentRunProgressQueue]);

  // useEffect to inform the user about the unsaved changes if the run creation is in progress
  useEffect(() => {
    const handleBeforeUnload = event => {
      if (!createRunProgress.queue.every(({ status }) => status === CREATE_RUN_PROGRESS_STATUSES.COMPLETE)) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [createRunProgress.queue]);

  const startRedirectCountdown = useCallback(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      setRedirectCountdown(currentCountdown => {
        if (currentCountdown <= 1) {
          clearInterval(intervalRef.current);
          navigate(getRouteURI(ROUTES.RUN_EDIT, { uuid: extractUuid(primaryRunUri) }, {}, true));
          cleanUpContextResources();
          clearModalWindow();
          return 0;
        }
        return currentCountdown - 1;
      });
    }, 1000);
  }, [primaryRunUri]);

  // useEffect to start the countdown to redirect to the Edit Run Page
  useEffect(() => {
    // Start countdown only when the current run progress status is complete
    if (currentRunProgress?.status === CREATE_RUN_PROGRESS_STATUSES.COMPLETE
      && redirectCountdown > 0
      && primaryRunUri) {
      startRedirectCountdown();
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [currentRunProgress?.status, redirectCountdown, primaryRunUri, startRedirectCountdown]);

  useEffect(() => {
    // This effect ensures that the info message is reset on status change
    setShowRefreshDataInfo(false);
    // Reset timer to potentially show the info message after 2 minutes
    handleSetRefreshTimer();

    return () => {
      // Cleanup to clear any pending timer
      clearTimeout(refreshDataTimerRef.current);
    };
    // Depend on status changes to reset the info message
  }, [currentRunProgress?.status]);

  useEffect(() => {
    if (!isRelevantStatusForRefreshingData) {
      // Immediately clear timeout if status is not relevant for refreshing
      clearTimeout(refreshDataTimerRef.current);
      // Hide the info message if status is not relevant
      setShowRefreshDataInfo(false);
    }
    // Depend on relevance of status for refreshing
  }, [isRelevantStatusForRefreshingData]);

  useEffect(() => {
    // Always clear the existing timer and reset the background check whenever
    // the run's status or its "relevance" changes.
    clearTimeout(backgroundRunProgressCheckTimerRef.current);
    setShouldCheckRunProgressInBackground(false);

    // Only re-set the timer if the status is relevant for refreshing.
    if (isRelevantStatusForRefreshingData) {
      handleSetBackgroundRefreshTimer();
    }

    // Cleanup to clear any pending timer if this effect re-runs or unmounts
    return () => {
      clearTimeout(backgroundRunProgressCheckTimerRef.current);
    };
  }, [currentRunProgress?.status, isRelevantStatusForRefreshingData]);

  useEffect(() => {
    if (shouldCheckRunProgressInBackground) {
      handleRefreshDataForRunCreation(true);
    }
  }, [shouldCheckRunProgressInBackground]);

  // Navigate to the specific route and clear the modal window
  const handleNavigate = route => {
    clearInterval(intervalRef.current);
    navigate(route);
    clearModalWindow();
    cleanUpContextResources();
  };

  // Skip showing the modal window if we don't have the current run progress
  if (!currentRunProgress) return null;

  return (
    <CreateNewRunProgressModal
      currentRunProgress={currentRunProgress}
      handleNavigate={handleNavigate}
      clearModalWindow={clearModalWindow}
      redirectCountdown={redirectCountdown}
      selectedPrinter={selectedPrinter}
      handleRunRecreate={handleRunRecreate}
      tryAgainAttempt={tryAgainAttempt}
      handleRefreshDataForRunCreation={handleRefreshDataForRunCreation}
      handleResetBuildPlate={handleResetBuildPlate}
      createNewRunFetching={createNewRunFetching}
      refreshingRunProgress={refreshingRunProgress}
      showRefreshDataInfo={showRefreshDataInfo}
      refreshDataComplete={refreshDataComplete}
      modalCloseConfirmationProps={{
        confirmCloseModal,
        setConfirmCloseModal,
      }}
      isContinuingRun={isContinuingRun}
      isRetryingRun={isRetryingRun}
    />
  );
};

CreateNewRunProgressModalContainer.defaultProps = {
  runCreationPayload: null,
  selectedPrinter: null,
};

CreateNewRunProgressModalContainer.propTypes = {
  cleanUpContextResources: PropTypes.func.isRequired,
  selectedPrinter: PropTypes.shape({}),
  runCreationPayload: PropTypes.shape({}),
  setCreateRunUri: PropTypes.func.isRequired,
  handleResetBuildPlate: PropTypes.func.isRequired,
};

export default CreateNewRunProgressModalContainer;

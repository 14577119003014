import {
  faLocationArrow,
  faPen,
  faPlay,
  faLightbulb,
  faClose, faInfoCircle, faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import ConfirmationModal from 'rapidfab/components/ConfirmationModal';
import Loading from 'rapidfab/components/Loading';
import CreateRunProgressStepper from 'rapidfab/components/stepper/CreateRunProgressStepper';
import { BUILD_PACKER_TYPES, CREATE_RUN_PROGRESS_STATUSES, DATETIME_DEFAULT_FORMAT, ROUTES } from 'rapidfab/constants';
import { resetRcTooltipInnerStyle } from 'rapidfab/constants/styles';
import { BUILD_PACKER_TYPES_MAP } from 'rapidfab/mappings';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import RCTooltip from 'rc-tooltip';
import React, { memo } from 'react';
import 'rapidfab/styles/componentStyles/create-new-run-progress-modal.scss';
import { Button } from 'react-bootstrap';

const CreateNewRunProgressModal = ({
  currentRunProgress,
  redirectCountdown,
  handleNavigate,
  clearModalWindow,
  selectedPrinter,
  handleRunRecreate,
  createNewRunFetching,
  handleResetBuildPlate,
  modalCloseConfirmationProps,
  showRefreshDataInfo,
  handleRefreshDataForRunCreation,
  refreshingRunProgress,
  refreshDataComplete,
  tryAgainAttempt,
  isContinuingRun,
  isRetryingRun,
}) => {
  // Get the current run creation status update
  const status = currentRunProgress?.status;
  // If run creation error, we will get the error details
  const errorDetails = currentRunProgress?.error || {};
  // Get the complete details if the run creation is successful
  const completeDetails = currentRunProgress?.completeDetails || {};
  // Check if the run creation is in progress
  const createRunInProgress = [
    CREATE_RUN_PROGRESS_STATUSES.PENDING,
    CREATE_RUN_PROGRESS_STATUSES.PROCESSING,
    CREATE_RUN_PROGRESS_STATUSES.FINISHING].includes(status);
  // Get the mainRun URI to redirect to the Edit Run Page
  const primaryRunUri = completeDetails?.mainRun;
  // Get the selected Printer to identify the Build Packer Type
  const selectedWorkstationPackingMethod = selectedPrinter?.printer_type?.build_packer_type;

  const { confirmCloseModal, setConfirmCloseModal } = modalCloseConfirmationProps;

  // Render the specific "Close Text" based on the status when the user clicked on "X" button
  const handleGetCloseModalConfirmationText = () => {
    switch (status) {
      case CREATE_RUN_PROGRESS_STATUSES.COMPLETE:
        return 'The runs have been created successfully. Would you like to go to the Orders page?';
      case CREATE_RUN_PROGRESS_STATUSES.ERROR:
        return 'Unfortunately, an error occurred during the creation of the runs. Would you like to visit the Orders page?';
      default:
        return (
          <>
            <p className="mb0">We will continue scheduling your runs and if they are scheduled without issues, you will find them on the
              Runs list shortly
            </p>
            <p className="mb0">If scheduling fails, the selected pieces will be added back to the scheduling backlog.</p>
          </>
        );
    }
  };

  // If "Create Another Run" button was clicked
  const handleCreateAnotherRun = () => {
    handleResetBuildPlate();
    clearModalWindow();
  };

  const navigateToOrders = () => handleNavigate(ROUTES.ORDERS);
  const navigateToPrimaryRun = () =>
    handleNavigate(
      getRouteURI(ROUTES.RUN_EDIT, { uuid: extractUuid(primaryRunUri) }, {}, true));

  // If the user confirmed the "X" button (close) - we will navigate to the Orders page and clear the modal window
  const handleLeaveFromRunCreation = () => {
    clearModalWindow();
    setConfirmCloseModal(false);
    navigateToOrders();
  };

  const handleConfirmModalClose = () => setConfirmCloseModal(true);

  // Specify the title text based on the status
  const renderTitleText = () => {
    if (status === CREATE_RUN_PROGRESS_STATUSES.COMPLETE) {
      return 'Run creation complete';
    }

    if (status === CREATE_RUN_PROGRESS_STATUSES.ERROR) {
      return 'An error occurred';
    }

    return 'Please wait';
  };

  // Specify the subtitle text based on the status
  const renderSubTitleText = () => {
    if (status === CREATE_RUN_PROGRESS_STATUSES.COMPLETE) {
      return (
        <>
          <strong>{completeDetails?.runsCreated?.length || 'All'}</strong> runs have been successfully created
          {
            !_isEmpty(completeDetails) && (
              <RCTooltip
                placement="bottom"
                id="createCompleteetailsTooltip"
                destroyTooltipOnHide
                overlayInnerStyle={resetRcTooltipInnerStyle}
                mouseLeaveDelay={0.4}
                overlay={(
                  <p className="mb0 darkTooltip">
                    {completeDetails?.dateFinished && (
                      <p className="mb0"><strong>Date Finished:</strong> {dayjs(completeDetails.dateFinished).format(DATETIME_DEFAULT_FORMAT)}</p>
                    )}
                  </p>
                )}
              >
                <FontAwesomeIcon className="spacer-left" icon={faInfoCircle} />
              </RCTooltip>
            )
          }
        </>
      );
    }

    if (status === CREATE_RUN_PROGRESS_STATUSES.ERROR) {
      return (
        <>
          <span>Unfortunately, the runs were not created</span>
          {
            !_isEmpty(errorDetails) && (
              <RCTooltip
                placement="bottom"
                id="createRunErrorDetailsTooltip"
                destroyTooltipOnHide
                overlayInnerStyle={resetRcTooltipInnerStyle}
                mouseLeaveDelay={0.4}
                overlay={(
                  <p className="mb0 darkTooltip">
                    {errorDetails?.dateFinished && (
                      <p className="mb0"><strong>Date Finished:</strong> {dayjs(errorDetails.dateFinished).format(DATETIME_DEFAULT_FORMAT)}</p>
                    )}
                    {errorDetails?.unfittedPiecesLength && (
                      <p className="mb0"><strong>Unfitted Pieces:</strong> {errorDetails.unfittedPiecesLength}</p>
                    )}
                    {errorDetails?.id && (
                      <p className="mb0"><strong>Create Run UUID:</strong> {errorDetails.id}</p>
                    )}
                  </p>
                )}
              >
                <FontAwesomeIcon className="spacer-left" icon={faInfoCircle} />
              </RCTooltip>
            )
          }
        </>

      );
    }

    return 'We are working on the creation of runs';
  };

  // Render the bottom text based on the status
  const renderBottomText = () => {
    if (status === CREATE_RUN_PROGRESS_STATUSES.COMPLETE) {
      return (
        <div className="text-center mt15">
          <p className="mb15">You will be redirected to the Primary Run page in <strong>{redirectCountdown} seconds</strong></p>
          <div className="d-flex align-items-center justify-content-center">
            <Button onClick={handleCreateAnotherRun} variant="primary" className="mr15">
              <FontAwesomeIcon icon={faPlus} className="spacer-right" />
              Create Another Run
            </Button>
            <Button onClick={navigateToPrimaryRun} variant="light" className="mr15">
              <FontAwesomeIcon icon={faLocationArrow} className="spacer-right" />
              Go To Primary Run
            </Button>
          </div>
        </div>
      );
    }

    if (status === CREATE_RUN_PROGRESS_STATUSES.ERROR) {
      return (
        <div className="text-center mt15">
          <p className="mb15">
            We were unable to fit all your pieces in this build plate. Would you like
            to {tryAgainAttempt < 1 && (<span>retry packing these pieces,</span>)} edit the pieces selected or continue
            creating the runs using the selected pieces (this will override our
            printer size checks)?
          </p>
          <div className="d-flex align-items-center justify-content-center">
            {tryAgainAttempt < 1 && (
              <Button onClick={() => handleRunRecreate({ shouldRetry: true })} variant="success" className="mr15">
                {
                  createNewRunFetching && isRetryingRun
                    ? <Loading inline className="spacer-right" />
                    : <FontAwesomeIcon icon={faPlay} className="spacer-right" />
                }
                Try Again
              </Button>
            )}
            <Button onClick={clearModalWindow} variant="primary" className="mr15">
              <FontAwesomeIcon icon={faPen} className="spacer-right" />
              Edit Runs
            </Button>
            <Button onClick={() => handleRunRecreate({ shouldContinue: true })} variant="success">
              {
                createNewRunFetching && isContinuingRun
                  ? <Loading inline className="spacer-right" />
                  : <FontAwesomeIcon icon={faPlay} className="spacer-right" />
              }
              Continue Creating Runs
            </Button>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderPackerTypeTip = () => {
    // No need to show the Tip under the loader if the status is either Error or Complete
    // As we will show the additional complete / error UI
    if (status === CREATE_RUN_PROGRESS_STATUSES.ERROR
      || status === CREATE_RUN_PROGRESS_STATUSES.COMPLETE) {
      return null;
    }

    // Show the tip only if the Build Packer Type is not "User Managed"
    if (selectedWorkstationPackingMethod !== BUILD_PACKER_TYPES.USER_MANAGED) {
      return (
        <div className="text-center">
          <p className="mb0">
            <FontAwesomeIcon icon={faLightbulb} className="spacer-right" />
            <strong>Did you know?</strong>
          </p>
          <p className="create-new-run-modal-tip">
            Some users prefer to use their own build packing software.
            This can be achieved by switching the Printer&apos;s <strong>Build Packer Type</strong>
            &nbsp;to <strong>&quot;User Managed&quot;</strong>, allowing them to bypass the default process.
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="create-new-run-modal-backdrop" />
      <div
        className="create-new-run-modal"
      >
        <div className="d-flex align-items-center justify-content-end">
          <FontAwesomeIcon
            icon={faClose}
            onClick={handleConfirmModalClose}
            tabIndex={0}
            role="button"
            className="custom-darken-modal-button"
          />
        </div>
        <div
          className="create-new-run-modal-header"
          style={
            status === CREATE_RUN_PROGRESS_STATUSES.COMPLETE ||
            status === CREATE_RUN_PROGRESS_STATUSES.ERROR ? { marginBottom: '30px' } : {}
          }
        >
          <p className="mb5">{renderTitleText()}</p>
          <p>{renderSubTitleText()}</p>
        </div>
        {createRunInProgress && (
          <div className="d-flex align-items-center justify-content-center mb15">
            <div className="create-new-run-modal-loader" />
          </div>
        )}
        {renderPackerTypeTip()}
        <CreateRunProgressStepper
          status={status}
          errorDetails={errorDetails}
          packingMethod={BUILD_PACKER_TYPES_MAP[selectedWorkstationPackingMethod]?.defaultMessage}
          showRefreshDataInfo={showRefreshDataInfo}
          handleRefreshDataForRunCreation={handleRefreshDataForRunCreation}
          refreshDataComplete={refreshDataComplete}
          refreshingRunProgress={refreshingRunProgress}
          selectedPrinter={selectedPrinter}
          clearModalWindow={clearModalWindow}
        />
        {renderBottomText()}
      </div>
      {confirmCloseModal && (
        <ConfirmationModal
          handleCancel={() => setConfirmCloseModal()}
          handleConfirm={handleLeaveFromRunCreation}
          confirmButtonContent="Go To Orders"
          cancelButtonContent="Stay"
          customConfirmVariant="success"
          message={handleGetCloseModalConfirmationText()}
        />
      )}
    </>
  );
};

CreateNewRunProgressModal.defaultProps = {
  selectedPrinter: null,
};

CreateNewRunProgressModal.propTypes = {
  currentRunProgress: PropTypes.arrayOf(
    PropTypes.shape({})).isRequired,
  redirectCountdown: PropTypes.number.isRequired,
  handleNavigate: PropTypes.func.isRequired,
  clearModalWindow: PropTypes.func.isRequired,
  selectedPrinter: PropTypes.shape({
    printer_type: PropTypes.shape({
      build_packer_type: PropTypes.string,
    }),
  }),
  handleRunRecreate: PropTypes.func.isRequired,
  createNewRunFetching: PropTypes.bool.isRequired,
  handleResetBuildPlate: PropTypes.func.isRequired,
  modalCloseConfirmationProps: PropTypes.shape({
    confirmCloseModal: PropTypes.bool.isRequired,
    setConfirmCloseModal: PropTypes.func.isRequired,
  }).isRequired,
  showRefreshDataInfo: PropTypes.bool.isRequired,
  handleRefreshDataForRunCreation: PropTypes.func.isRequired,
  refreshingRunProgress: PropTypes.bool.isRequired,
  refreshDataComplete: PropTypes.bool.isRequired,
  tryAgainAttempt: PropTypes.number.isRequired,
  isContinuingRun: PropTypes.bool.isRequired,
  isRetryingRun: PropTypes.bool.isRequired,
};

export default memo(CreateNewRunProgressModal);
